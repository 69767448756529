import React from 'react'
import useStyles from './SelectButtonStyles'
import DownArrow from '../../Icons/chevron-down.svg'

const SelectButton = ({placeholder, showModal,value}) => {
    const classes = useStyles();
    

  return (
    <div className={classes.container} onClick={() => showModal()}>
        {!value && <span className={classes.placeholder}>{placeholder}</span>}
        {value && <span className={classes.value}>{value}</span>}
        <img src={DownArrow} alt="Select a Category" />
    </div>
  )
}

export default SelectButton