import React from 'react';
import Product from '../product/Product';
import useStyles from './BusinessProductsStyles';


const BusinessProducts = ({data}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
    
      {
        data ?
        data.map(element => 
          {
            return (
              <Product
                  key={element.id}
                  content={element}
              />
            )
          }
        )
        : 
        <p>cargando...</p>
      }
    </div>
  )
}

export default BusinessProducts