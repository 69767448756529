import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#fff',
      paddingBottom: '6vh',
      width: "95%",
      borderRadius: theme.spacing(2),
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      marginLeft: '2.5%',
      position: "sticky",
      top: "118px",
      zIndex: "1300",
    },
    icon: {
      fontSize: 60,
      color: '#404040',
      marginTop: '4vh',
    },
    title: {
      fontSize: 16,
      color: '#404040',
      fontWeight: 'bold',
      alignItems: 'center',
      margin: '6px',
    },
    subTitle: {
      fontSize: 12,
      alignItems: 'center',
      color: '#ABABAB',
    },
    centrar: {
        display: 'flex',
        alignItems: 'center',
    },
  }));

export default useStyles