/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button } from "@material-ui/core";
import React, { useState } from "react";
import ImageInput from "../../imageInput/ImageInput";
import useStyles from "./ProductFormStyles";
import { Input } from "../../inputs/Input";
import SelectCategories from "../../selectcategories/SelectCategories";
import { TextArea } from "../../inputs/TextArea";
import SelectDays from "../../selectdays/SelectDays";
import PriorNoticeHours from "../../priornoticehours/PriorNoticeHours";
import CustomTimePicker from "../../timepicker/TimePicker";
import SelectDeliveryType from "../../selectdeliverytype/SelectDeliveryType";
import Overlay from "../../overlay/Overlay";
import CancelNewProductOverlay from "../cancelNewProductOverlay/CancelNewProductOverlay";
import NewProductFinishedOverlay from "../newProductFinishedOverlay/NewProductFinishedOverlay";
import ProductPreview from "../ProductPreview";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import NavBack from "../../navbar/Navback";
import useNewProduct from "../../../hooks/useNewProduct";
import { useEffect } from "react";
import app, { db } from "../../../firebase";
import {
  handleInputWithTwoDecimals,
  handleInputLength,
} from "../../../utils/handleInputs";
import Spinner from "../../spinner/Spinner"; // Replace with the correct path to your Spinner component
import { useDispatch } from "react-redux";
const currency = "Bs.";

const ProductForm = () => {
  const [userInfo, setUserInfo] = useState({
    uid: "",
    email: "",
    contactName: "",
    contactNumber: "",
    zone: "",
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const submitNewProduct = async (productValues, docId) => {
    let newGuideEntry = {
      id: docId,
      name: productValues.name,
      lastUpdate: productValues.lastUpdate,
      price: productValues.price,
      photoRating: productValues.photoRating,
    };
    dispatch({
      type: "guides/addProductToGuide",
      product: newGuideEntry,
    });
    newProductFormik.values.Categories.forEach((category) => {
      dispatch({
        type: "guides/addCategorieGuide",
        payload: null,
        category: category,
      });
    });
    dispatch({
      type: "fetcher/guideRefreshed",
    });
    //console.log("finished dispatches");
    setProductPublished(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [newProductFormik, formikHasDefaultValues] =
    useNewProduct(submitNewProduct);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showingModal, setShowingModal] = useState("");
  const [previewMode, setPreviewMode] = useState(false);
  const [formikIsEmpty, setFormikIsEmpty] = useState(true);
  const [productPublished, setProductPublished] = useState(false);

  const handlePreview = () => {
    setPreviewMode(true);
  };

  const handleClosePreview = () => {
    setPreviewMode(false);
  };

  const handleSetAllTouched = () => {
    const touchedFields = Object.keys(newProductFormik.values).reduce(
      (touched, fieldName) => {
        touched[fieldName] = true;
        return touched;
      },
      {}
    );
    newProductFormik.setTouched(touchedFields);
  };
  const handleSubmitForm = async () => {
    let validations = await newProductFormik.validateForm(
      newProductFormik.values
    );
    if (!isLoading && Object.keys(validations).length === 0) {
      newProductFormik.submitForm();
      setIsLoading(true);
    } else {
      handleSetAllTouched();
    }
  };
  const askToShowModal = (modalId) => {
    switch (showingModal) {
      case "":
        setShowingModal(modalId);
        return true;
      default:
        if (showingModal !== modalId) return false;
        setShowingModal("");
        return true;
    }
  };

  const handleDeliveryTypeChange = (selectedOption) => {
    newProductFormik.setFieldValue("DeliveryType", selectedOption);
  };

  const setFormikValue = async (field, value) => {
    await newProductFormik.setFieldValue(field, value, false); // Update the closingTime field value
    newProductFormik.setErrors(await newProductFormik.validateForm());
  };

  const handleBack = () => {
    history.push("/profile");
  };

  useEffect(() => {
    const unsubscribe = app.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            setUserInfo(doc.data());
          });
      }
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (productPublished) {
      setIsLoading(false);
    }
  }, [productPublished]);

  useEffect(() => {
    if (!newProductFormik.dirty) {
      setFormikIsEmpty(true);
    } else if (formikIsEmpty === true) {
      setFormikIsEmpty(false);
    }
  }, [newProductFormik.values]);

  return (
    <React.Fragment>
      <Overlay
        show={showOverlay}
        clickOnOverlayBackground={() => {
          setShowOverlay(false);
        }}
      >
        <CancelNewProductOverlay
          backButtonFunction={() => {
            setShowOverlay(false);
          }}
          cancelButtonFunction={() => {
            history.push("/");
          }}
        />
      </Overlay>
      <Overlay
        show={productPublished}
        clickOnOverlayBackground={() => {
          setShowOverlay(false);
        }}
      >
        <NewProductFinishedOverlay
          handleBack={() => {
            handleBack();
          }}
        />
      </Overlay>
      <NavBack
        title="AGREGAR PRODUCTO"
        extraButton={
          !formikIsEmpty && (
            <Button
              className={classes.cancelButton}
              onClick={() => setShowOverlay(true)}
            >
              Cancelar
            </Button>
          )
        }
        showBackArrow={formikIsEmpty}
      />

      <div className={classes.formContainer}>
        <div className={classes.businessInfo}>
          <Avatar alt="restaurante" sx={{ width: 40, height: 40 }} />
          <span className={classes.title}>{userInfo?.contactName}</span>
        </div>

        <form
          onSubmit={newProductFormik.handleSubmit}
          className={classes.mainForm}
        >
          <div className={classes.inputsContainer}>
            <ImageInput
              updateImageFile={(file) =>
                newProductFormik.setFieldValue("imageFile", file)
              }
              setTouched={() =>
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  imageFile: true,
                })
              }
              previewProductValues={newProductFormik.values}
              previewSellerValues={userInfo}
            />
            {newProductFormik.touched.imageFile && (
              <span className={classes.error}>
                {newProductFormik.errors.imageFile}
              </span>
            )}

            <Input
              name="productName"
              type="text"
              placeholder="Nombre del Producto"
              classTypes={classes.input}
              value={newProductFormik.values.productName}
              onChange={newProductFormik.handleChange}
              onBlur={newProductFormik.handleBlur}
              onKeyDown={(e) => handleInputLength(e, 30)}
            />
            {newProductFormik.touched.productName && (
              <span className={classes.error}>
                {newProductFormik.errors.productName}
              </span>
            )}
            <div className={classes.priceContainer}>
              <span className={classes.staticCurrency}>{currency}</span>
              <Input
                name="productPrice"
                type="number"
                placeholder={"Precio"}
                classTypes={classes.inputSmallHidden}
                value={newProductFormik.values.productPrice}
                onChange={newProductFormik.handleChange}
                onBlur={newProductFormik.handleBlur}
                onKeyDown={(e) => handleInputWithTwoDecimals(e, 4)}
              />
            </div>
            {newProductFormik.touched.productPrice && (
              <span className={classes.error}>
                {newProductFormik.errors.productPrice}
              </span>
            )}

            <SelectCategories
              changeCategories={async (value) => {
                await newProductFormik.setFieldValue("Categories", value);
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  Categories: true,
                });
              }}
              value={newProductFormik.values.Categories}
              askToShowModal={askToShowModal}
            />
            {newProductFormik.touched.Categories && (
              <span className={classes.error}>
                {newProductFormik.errors.Categories}
              </span>
            )}

            <TextArea
              name="productDescription"
              placeholder="Descripción"
              classTypes={classes.textArea}
              value={newProductFormik.values.productDescription}
              onChange={newProductFormik.handleChange}
              onBlur={newProductFormik.handleBlur}
            />
            {newProductFormik.touched.productDescription && (
              <span className={classes.error}>
                {newProductFormik.errors.productDescription}
              </span>
            )}

            <SelectDays
              onChange={(value) => {
                newProductFormik.setFieldValue("days", value);
              }}
              value={newProductFormik.values.days}
              askToShowModal={askToShowModal}
              touched={() => {
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  days: true,
                });
              }}
            />
            {newProductFormik.touched.days && (
              <span className={classes.error}>
                {newProductFormik.errors.days}
              </span>
            )}

            <PriorNoticeHours
              classTypes={classes}
              values={newProductFormik.values.PriorNoticeTime}
              setFieldValue={(newValue) => {
                newProductFormik.setFieldValue(`PriorNoticeTime`, newValue);
              }}
              askToShowModal={askToShowModal}
              touched={() => {
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  PriorNoticeTime: true,
                });
              }}
            />
            {newProductFormik.touched.PriorNoticeTime && (
              <span className={classes.error}>
                {newProductFormik.errors.PriorNoticeTime?.time}
              </span>
            )}
            <div className={classes.timePickerContainer}>
              <div className={classes.timePickerWrapper}>
                <CustomTimePicker
                  value={newProductFormik.values.openingTime}
                  label="Horario Apertura"
                  onChange={(time) => setFormikValue("openingTime", time)}
                  touched={() => {
                    newProductFormik.setTouched({
                      ...newProductFormik.touched,
                      openingTime: true,
                    });
                  }}
                />
                {newProductFormik.touched.openingTime && (
                  <span className={classes.error}>
                    {newProductFormik.errors.openingTime}
                  </span>
                )}
              </div>

              <div className={classes.timePickerWrapper}>
                <CustomTimePicker
                  value={newProductFormik.values.closingTime}
                  label="Horario Clausura"
                  onChange={(time) => setFormikValue("closingTime", time)}
                  touched={() => {
                    newProductFormik.setTouched({
                      ...newProductFormik.touched,
                      closingTime: true,
                    });
                  }}
                />
                {newProductFormik.touched.closingTime && (
                  <span className={classes.error}>
                    {newProductFormik.errors.closingTime}
                  </span>
                )}
              </div>
            </div>

            <SelectDeliveryType
              value={newProductFormik.values.DeliveryType}
              onChange={handleDeliveryTypeChange}
              askToShowModal={askToShowModal}
              touched={() => {
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  DeliveryType: true,
                });
              }}
            />
            {newProductFormik.touched.DeliveryType && (
              <span className={classes.error}>
                {newProductFormik.errors.DeliveryType}
              </span>
            )}
          </div>

          <div className={classes.buttonContainer}>
            <Button
              disableElevation
              className={classes.btnPreview}
              type="button"
              variant="contained"
              onClick={handlePreview}
            >
              Vista Previa
            </Button>
            <Button
              disableElevation
              className={classes.buttonSubmit}
              variant="contained"
              disabled={isLoading}
              type="button"
              onClick={handleSubmitForm}
            >
              {isLoading ? <Spinner /> : "Publicar Producto"}
            </Button>
          </div>
        </form>
      </div>

      {/* Product Preview Dialog */}
      <Dialog
        open={previewMode}
        onClose={handleClosePreview}
        fullWidth
        maxWidth="md"
      >
        <ProductPreview
          userInfo={userInfo}
          data={newProductFormik.values}
          handleClose={handleClosePreview}
          handleSubmit={handleSubmitForm}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default ProductForm;
