import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import useStyles from "./NavbarStyles";
import { useRef, useContext, useState } from "react";
import NewProductForm from "../product/NewProductForm";
import { AuthContext } from "../../Auth";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link, useHistory } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import logoHeader from "../../Images/Logo/Logo.svg";
import { signOut } from "../../dataBaseAdapter";
import MenuUser from "./MenuUser";
import MenuSeller from "./MenuSeller";

import MenuSellerDesktop from "./menuDesktop/MenuSellerDesktop"
import MenuUserDesktop from "./menuDesktop/MenuUserDesktop"

import { Drawer } from "@material-ui/core";

export default function SearchAppBar({ onClickHome }) {
  const classes = useStyles();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const ref = useRef(null);

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleNewProductForm = () => {
    history.push("/newproduct");
  };
  const handleLogOut = async () => {
    signOut();
    history.push("/");
  };
  const { currentUser } = useContext(AuthContext);

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";

  const menuOverlay = (
    <Drawer
      anchor="left"
      open={isMenuOpen}
      onClose={handleMenuClose}
      elevation={0}
      ModalProps={{
        BackdropProps: {
          style: {
            backgroundColor: "rgba(251, 126, 8, 0.2)",
          },
        },
      }}
      PaperProps={{
        style: {
          borderTopRightRadius: "16px",
          borderBottomRightRadius: "16px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      {currentUser ? <MenuSeller /> : <MenuUser />}
    </Drawer>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" elevation={0} className={classes.navBar}>
        <Toolbar>
          <div className={classes.headerMobile}>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            </div>
            <Link
              to="/"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              className={classes.profile}
              onClick={onClickHome}
            >
              <img
                src={logoHeader}
                alt="DesdeCasa footer"
                className={classes.logoHeader}
              />
            </Link>
          </div>
          <Typography className={classes.title} variant="h6" noWrap>
            Cochabamba
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
              {currentUser ? <MenuSellerDesktop></MenuSellerDesktop> : <MenuUserDesktop></MenuUserDesktop>}
          </div>
        </Toolbar>
      </AppBar>
      <NewProductForm ref={ref} />
      {menuOverlay}
    </div>
  );
}
