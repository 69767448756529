import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//import { handleEmptyDays } from "../../utils/DaysProductHandler";
import { handleDays } from "../../../utils/AttentionDaysHandler";
import { handleEmptyNoticeHours } from "../../../utils/NoticeHoursHandler";
import useStyles from "./RecentProductCardStyles";
import ReviewStarsInProduct from "../../reviews/reviewStars/reviewStarsInProduct";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const RecentProductCard = ({ content }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isNavigating, setIsNavigating] = useState(false); // State to track navigation status

  const handleEmptyZone = () => {
    if (content.user.zone) return `📍 ${content.user.zone}`;
    return;
  };

  const navigateProductDetailsPage = async () => {
    if (!isNavigating) {
      const id = content.id;

      setIsNavigating(true); // Set navigating status to true

      history.push(`/product/${content.name}/${id}`);

      await delay(500);
      setIsNavigating(false); // Set navigating status to false
    }
  };

  const handleBigNameStyle = () => {
    if (content.name.length > 32) {
      return { height: "58%" };
    }
    return { height: "75%" };
  };

  return (
    <div
      className={classes.productCardContainer}
      onClick={navigateProductDetailsPage}
    >
      <div className={classes.imageContainer}>
        <img src={content.imageUrl} alt="" className={classes.productImage} />
      </div>
      <div className={classes.infoContainer}>
        <p className={classes.productName}>{content.name}</p>

        <p className={classes.contactName}>{content.user.contactName}</p>

        {content?.reviews && <ReviewStarsInProduct
        
        value={content?.rating || 0}
        reviewsAverage={content?.rating || 0}
        reviewsTotal={content?.reviews?.length}
        />
        }

        <div
          className={classes.extraInfoContainer}
          style={content?.reviews ? { height: "38%" } : handleBigNameStyle()}
        >
          <div className={classes.extraInfo}>
            <div className={classes.row}>
              <p className={classes.hours}>
                {"⏳" + handleEmptyNoticeHours(content)}
              </p>
              <span className={classes.orangeDot}>·</span>
              <span className={classes.days}>
                {/*   📅 {handleEmptyDays(content)}*/}
                📅 {handleDays(content)}
              </span>
            </div>

            <p className={classes.zone}>{handleEmptyZone()}</p>
          </div>

          <div className={classes.priceContainer}>
            <span className={classes.price}>
              <span className={classes.priceLabel}>Bs. </span>
              {content.price}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentProductCard;
