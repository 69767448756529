import React, { useEffect, useState } from "react";
import RecentProductCard from "../product/productCards/RecentProductCard";
import useStyles from "./RecentProductsCarouselStyles";
import { getCategoriesSnapshot } from "../../dataBaseAdapter";
import { convertDocumentToArrayOfProducts } from "../../utils/products.utils";
const RecentProductsCarousel = () => {
  const classes = useStyles();
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    const getDocs = async () => {
      const snapshot = await getCategoriesSnapshot({
        orderingCriteria: "lastUpdate",
        limitDocs: 5,
        descOrder: true,
      });
      const products = convertDocumentToArrayOfProducts(snapshot.docs);
      setProductList(products);
    };
    getDocs();
  }, []);
  return (
    <div>
      <span className={classes.title}>PRODUCTOS RECIENTES</span>
      <div className={classes.recentProductsContainer}>
        {productList.map((product) => (
          <div className={classes.productBox} key={product.id}>
            <RecentProductCard content={product}></RecentProductCard>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentProductsCarousel;
