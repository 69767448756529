import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    PriorTimeContainer: {
        display:"flex",
        flexDirection:"column",
        alignContent:"center",
    },
    timeInput:{
        color:"#253376",
        fontSize: 14,
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        },
        width:"62%"
    },
    typeContainer:{
        width:"35%",
    },
    PriorTimeInputsContainer:{
        display:"flex",
        msFlexDirection:"row",
        width:"100%",
        justifyContent:"space-between"
    }

}))

export default useStyles