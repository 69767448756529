import React from "react";
import useStyles from "./DeleteReviewOverlayStyles";
import { ReactComponent as WarningSVG } from "../../../Images/Icons/warningIcon.svg";
import ClearIcon from "@material-ui/icons/Clear";

const DeleteReviewOverlay = ({
  backButtonFunction,
  confirmDelete,
  isDeleting,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.WarningContainer}>
        <ClearIcon
          style={{ color: "#FB7E08" }}
          className={classes.closeButton}
          onClick={backButtonFunction}
        />
        <WarningSVG className={classes.warningIcon}></WarningSVG>
        <h1 className={classes.title}>IMPORTANTE</h1>
        <p className={classes.warningText}>
          ¿Estás seguro de ELIMINAR tu resena?
        </p>
        <div className={classes.buttonContainer}>
          <button
            className={`${classes.backButton} ${
              isDeleting ? classes.disabledPrimaryButton : ""
            }`}
            onClick={backButtonFunction}
            disabled={isDeleting}
          >
            Volver
          </button>
          <button
            className={`${classes.confirmDeleteButton} ${
              isDeleting ? classes.disabledSecondaryButton : ""
            }`}
            onClick={confirmDelete}
            disabled={isDeleting}
          >
            Si, Eliminar
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DeleteReviewOverlay;
