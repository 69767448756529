import React, { useState } from 'react'
import SelectButton from '../buttons/SelectButton'
import { Input } from '../inputs/Input'
import PriorNoticeHoursOptions from './PriorNoticeHoursOptions'
import useStyles from './PriorNoticeHoursStyles';
import { handleInputWithoutDecimals } from '../../utils/handleInputs';
const QtyTimeField="time"
const typeOfTimeField="type"

const PriorNoticeHours = ({classTypes,values,setFieldValue,askToShowModal,touched}) => {
  const ModalID="PriorNoticeHours"
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false)
  const [selectedType, setSelectedType] = useState(values[typeOfTimeField])
  
  const handleModal = () => {
    if (!askToShowModal(ModalID))
        return
    touched()
    setShowModal(!showModal)
  }

  
  const handleUpdate=(newPriorNoticeTimeValues)=>{
    setFieldValue(newPriorNoticeTimeValues)
  }

  const handletimeUpdate=(newValue)=>{
    let number=newValue.target.value
      let newPriorNoticeTimeValues = {
        ...values,[QtyTimeField]:number
    };
    handleUpdate(newPriorNoticeTimeValues)
  }
  
  const handleTypeUpdate=(value)=>{
    let newPriorNoticeTimeValues = {
        ...values,[typeOfTimeField]:value
    };
    // eslint-disable-next-line eqeqeq
    if (values[typeOfTimeField]!=newPriorNoticeTimeValues[typeOfTimeField])
      newPriorNoticeTimeValues[QtyTimeField]=""
    setSelectedType(value)
    handleUpdate(newPriorNoticeTimeValues)
  }

  return (
    <div className={classes.PriorTimeContainer}>
      <div className={classes.PriorTimeInputsContainer}>
          <Input placeholder="Tiempo de anticipación" 
          type='number'
          classTypes={`${classes.timeInput} ${classTypes.inputSmall}`} 
          value={values[QtyTimeField]} 
          onChange={handletimeUpdate}
          onKeyDown={handleInputWithoutDecimals}
          onBlur={()=>touched()}/>
          <div className={classes.typeContainer}>
            <SelectButton 
            placeholder={"Tiempo"} 
            showModal={handleModal} 
            value={selectedType}
            classTypes={classes.typeInput}
            />
          </div>
          {showModal &&
          <PriorNoticeHoursOptions handleModal={handleModal} show={showModal}
          initialActiveDay={values[typeOfTimeField]}
          handleUpdate={handleTypeUpdate}
          />}
      </div>
      
    </div>
  )
}

export default PriorNoticeHours