import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  carousel: {
    // boxSizing: "border-box",
    // backgroundColor: "#f2f2f2",
    display: "flex",
    margin: "0px 16px",
    flexWrap: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",

    height: "90px",
    width: "calc(100% - 16px)",
    "-webkit-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
