import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const app = firebase.initializeApp({
    apiKey: "AIzaSyD7wOGN7wq7vyeYz53MpJbkNsHenzYn4h4",
    authDomain: "compraventa-webapp.firebaseapp.com",
    projectId: "compraventa-webapp",
    storageBucket: "compraventa-webapp.appspot.com",
    messagingSenderId: "312539093798",
    appId: "1:312539093798:web:255a1d53c9b306b09bffe7",
    measurementId: "G-RSLCPV401G"
  });
  
export const db = app.firestore();

export const storage = app.storage();

export const fieldValue=firebase.firestore.FieldValue
export const facebookProvider=firebase.auth.FacebookAuthProvider
export default app;
