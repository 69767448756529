import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    productCardContainer: {
        backgroundColor: 'white',
        height: "220px",
        minWidth:"278px",
        margin: '8px 0',
        borderRadius: 8,
        boxShadow: '1px 1px 12px #FEE5CE',
        display: 'flex',
        flexDirection:"column"
    },
    imageContainer: {
        width: '100%',
        height: '50%',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    },
    infoContainer: {
        fontFamily: 'Nunito Sans',
        width: '100%',
        padding: '10px 8px 8px 12px',
        borderRadius: 'inherit',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        margin: 0,
    },
    productImage: {
        width: '100%',
        height: '100%',
        borderRadius:"inherit",
        objectFit: 'cover',
    },
    productName: {
        color: '#253376',
        fontSize: 14,
        margin: 'inherit',
        fontWeight: 700,
    },
    contactName: {
        fontSize: 11,
        margin: 'inherit',
        color: '#404040',
    },
    extraInfoContainer: {
        display: 'flex',
        color: '#253376',
        fontSize: 10,
        margin: 'inherit',
        fontWeight: 700,
    },
    extraInfo: {
        margin: 0,
        alignSelf: 'flex-end',
        paddingBottom: 8,
        flex: '1 120px',
        fontWeight: 600,
    },
    row: {
        display: 'flex',
    },
    hours: {
        margin: 'inherit',
        lineHeight:'14px',
        width: 'auto',
    },
    orangeDot: {
        color: '#FB7E08',
        margin: '0px 4px',
    },
    days: {
        lineHeight:'14px',
        width: 'auto',
    },
    zone: {
        margin: 'inherit',
        lineHeight:'14px',
        width: 'auto',
    },
    priceContainer: {
        backgroundColor: '#FEE5CE',
        alignSelf: 'flex-end',
        marginBottom: '10px',
        borderRadius: 8,
        padding: '0px 5px',
    },
    price: {
        paddingBottom: 8,
        fontSize: 24,
        fontWeight: 700,
        flex: '1 30px',
        alignSelf: 'flex-end',
        textAlign: 'end',
    },
    priceLabel: {
        margin: 'inherit',
        lineHeight:'14px',
        fontWeight: 700,
        fontSize: 14,
    },
}));

export default useStyles