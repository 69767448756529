import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 8,
    paddingBottom: 12,
  },
  borderContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    width: "100%",
    height: 188,

    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='orange' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='16' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: 8,

    // backgroundImage: "repeating-linear-gradient(0deg, #FB7E08, #FB7E08 10px, transparent 10px, transparent 20px, #FB7E08 20px), repeating-linear-gradient(90deg, #FB7E08, #FB7E08 10px, transparent 10px, transparent 20px, #FB7E08 20px), repeating-linear-gradient(180deg, #FB7E08, #FB7E08 10px, transparent 10px, transparent 20px, #FB7E08 20px), repeating-linear-gradient(270deg, #FB7E08, #FB7E08 10px, transparent 10px, transparent 20px, #FB7E08 20px)",
    // backgroundSize: "3px 100%, 100% 2px, 2px 100% , 100% 3px",
    // backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
    // backgroundRepeat: "no-repeat",
  },
  iconCarpetContainer: {
    display: "flex",
    marginTop: 38,
  },
  iconCarpet: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 19,
    marginBottom: 9,
  },
  buttonAddImage: {
    textAlign: "center",
    color: "#FB7E08",
    fontSize: 16,
    height: 40,
    width: 247,
    backgroundColor: "white",
    border: "solid 1px #FB7E08",
    borderRadius: 30,
    padding: 9,
  },
  tipText: {
    color: "#ABABAB",
    fontSize: 12,
    textAlign: "center",
  },
  hiddenObject: {
    display: "none",
  },
  previewImgContainer: {
    display: "flex",
    flexDirection: "column",
  },
  clearPreviewImageContainer: {
    marginTop: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  previewText: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: "10px",
    color: "#253376",
  },
}));

export default useStyles;
