import { makeStyles } from "@material-ui/core";
const inputTextColor = "#253376";

const useStyles = makeStyles(() => ({
  input: {
    width: "100%",
    padding: "15px 16px",
    borderRadius: "8px",
    background: "white",
    outline: "none",
    border: "none",
    boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
    marginBlockStart: "10px",
    color: inputTextColor,
    fontSize: "14px",
  },
}));

export default useStyles;
