import React, { useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import useStyles from "./PriorNoticeHoursOptionsStyles";
import { Button } from "@material-ui/core";
import RadioOption from "../inputs/RadioOption";

const PriorNoticeHoursOptions = ({
  handleModal,
  show,
  initialActiveDay = "Horas",
  handleUpdate,
}) => {
  const classes = useStyles();
  const [activeType, setActiveType] = useState(initialActiveDay);

  const options = [
    { option: "Días" },
    { option: "Horas" },
    { option: "Minutos" },
  ];

  const handleExit = (save) => {
    if (save) {
      handleUpdate(activeType);
    }
    handleModal();
  };

  return (
    <div
      className={!show ? classes.modalContainerHidden : classes.modalContainer}
    >
      <ClearIcon
        style={{ color: "#FB7E08" }}
        className={classes.closeButton}
        onClick={() => handleExit(false)}
      />
      <div className={classes.title}>Seleccionar Tipo</div>
      {Object.entries(options).map((opt) => (
        <RadioOption
          key={opt[1].option}
          opt={opt}
          isActive={activeType === opt[1].option}
          onSelect={() => setActiveType(opt[1].option)}
        />
      ))}
      <section className={classes.submitSection}>
        <Button className={classes.btnSubmit} onClick={() => handleExit(true)}>
          Guardar
        </Button>
      </section>
    </div>
  );
};

export default PriorNoticeHoursOptions;
