import React, { useState } from 'react';
import useStyles from './reviewStarsStyles';

import selectedStarImage from '../../../Icons/filledStar.svg'; 
import unselectedStarImage from '../../../Icons/star.svg'; 
const RatingStars = ({value,setValue}) => {
    const classes = useStyles();
    const [rating, setRating] = useState(value);
  
    const handleStarClick = (selectedRating) => {
      setRating(selectedRating);
      setValue(selectedRating)
    };
  
    return (
      <div className={classes.ratingStars}>
        <div className={classes.starsContainer}>
            {[1, 2, 3, 4, 5].map((value) => (
            <span
                key={value}
                className={classes.starContainer}
                onClick={() => handleStarClick(value)}
            >
                {value <= rating ? (
                <img src={selectedStarImage} alt={`Selected Star ${value}`} className={classes.star} />
                ) : (
                <img src={unselectedStarImage} alt={`Unselected Star ${value}`} className={classes.star} />
                )}
            </span>
            ))}
        </div>
        <div className={classes.selectedRating}>{rating} De 5 Estrellas</div>
      </div>
    );
  };
  
  export default RatingStars;