import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import useStyles from "./SearchStyles";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const Search = ({ handleSearchInput, searchValue,FilterButton }) => {
  const classes = useStyles();
  const dispatch = useDispatch();


  const formik = useFormik({
    initialValues: {
      searchInput: "",
    },
    validate: (values) => {
      const errors = {};

      if (values.searchInput.split(" ").length > 20) {
        errors.searchInput = "El texto es demasiado largo (máximo 20 palabras)";
      }

      return errors;
    },
    onSubmit: (values) => {
      handleSearchInput(values.searchInput);
      dispatch({
          type: 'searchs/newSeach',
          searchParam:values.searchInput
      })
      dispatch({
          type: 'fetcher/shouldFetch',
      })
    },
  });

  useEffect(() => {
    if (searchValue === null) {
      formik.resetForm();
      formik.handleSubmit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <div className={classes.containerSearch}>
      <div>
      <form onSubmit={formik.handleSubmit}>
        
        <TextField
          onChange={formik.handleChange}
          value={formik.values.searchInput}
          onBlur={formik.handleBlur}
          name="searchInput"
          variant="standard"
          placeholder="comida, postres, bebida, etc"
          className={classes.inputField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                
                <SearchIcon className={classes.iconSearch} />
                
                
              </InputAdornment>
            ),
            endAdornment:  
              <React.Fragment>
                {formik.values.searchInput && <IconButton
                  onClick={() => {
                    formik.resetForm();
                    formik.handleSubmit();
                  }}
                  edge="end"
                >
                  <ClearIcon style={{ fontSize: 14, color: "#ABABAB" }} />
                </IconButton>}
              {FilterButton && 
              <span className={classes.lineStyles}></span> 
              }
              {FilterButton && 
              FilterButton
              }

              </React.Fragment>
            ,
            className: classes.searchInput,
            disableUnderline: true,
          }}
          error={formik.touched.searchInput && formik.errors.searchInput}
          helperText={formik.touched.searchInput && formik.errors.searchInput}
        />
        
      </form>
      </div>
    </div>
  );
};

export default Search;
