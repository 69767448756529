import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    reviewContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: `24px ${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
        borderRadius: 8,
        width: "100%",
    },
    reviewerInfo: {
        display: "flex",
        alignItems: "center",
        paddingTop: 20,
        paddingBottom: 8,
    },
    reviewerTitle: {
        fontFamily: "Nunito Sans",
        color: "#253376",
        fontWeight: 400,
        fontSize: 15,
        letterSpacing: 1,
        marginLeft: 12,
        maxWidth:"100%",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "70%", 
          },
    },
    blackBoldText: {
        fontWeight: 600,
        fontFamily: "Nunito Sans",
        fontSize: "15px",
    },
    reviewDetailsText: {
        fontFamily: "Nunito Sans",
        fontWeight: 300,
        fontSize: "15px",
    },
    ratingContainer:{
        display:"flex",
        flexDirection:"row",
        flexWrap:"nowrap",
        alignItems:"center",
    },
    dateText:{
        marginLeft:"8px",
        marginTop:"6px",
        textAlign: "center",
        fontFamily:"Nunito Sans",
        width: "100%",
        fontWeight:400,
        fontSize:"13px",
        lineHeight:"17.73px",
        color:"#565959",
        minWidth:"125px",
        textAlign:"left"
    },
    trashCanContainer: {
        position: "absolute",
        right: "32px",
        marginTop:"20px"
      },
}));

export default useStyles;
