import * as yup from "yup";
import moment from "moment";

export const newProductSchema = yup.object().shape({
  productName: yup
    .string()
    .required("El campo nombre es requerido")
    .max(30, "El tamaño maximo del nombre es de 30 caracteres"),
  productPrice: yup
    .number()
    .typeError("El precio debe ser un numero (separador decimal .)")
    .required("El campo precio es requerido")
    .test(
      "decimal-places",
      "El precio solo debe tener 2 decimales",
      (value) => {
        if (value !== undefined && value !== null) {
          const decimalRegex = /^\d+(\.\d{1,2})?$/;
          return decimalRegex.test(value.toString());
        }
        return true;
      }
    )
    .test("max-value", "El precio debe ser menor a 1000", (value) => {
      return value < 1000;
    }),
  productDescription: yup.string().required("Se debe agregar una descripcion"),
  Categories: yup.array().min(1, "Debe seleccionar al menos una categoria"),
  PriorNoticeTime: yup.object().shape({
    time: yup
      .number()
      .when("type", {
        is: "Horas",
        then: () =>
          yup
            .number()
            .typeError("El tiempo debe ser un número")
            .min(1, "El tiempo debe ser de al menos 1 hora")
            .max(
              23,
              "El tiempo debe ser de máximo 23 horas, puedes probar otro tipo"
            )
            .required("El tiempo es requerido"),
      })
      .when("type", {
        is: "Días",
        then: () =>
          yup
            .number()
            .typeError("El tiempo debe ser un número")
            .min(1, "El tiempo debe ser de al menos 1 día")
            .max(30, "El tiempo debe ser de máximo 30 días")
            .required("El tiempo es requerido"),
      })
      .when("type", {
        is: "Minutos",
        then: () =>
          yup
            .number()
            .typeError("El tiempo debe ser un número")
            .min(1, "El tiempo debe ser de al menos 1 minuto")
            .max(
              59,
              "El tiempo debe ser de máximo 59 minutos, puedes probar otro tipo"
            )
            .required("El tiempo es requerido"),
      }),
  }),
  imageFile: yup.mixed().required("Debes agregar una imagen"),

  days: yup
    .array()
    .min(1, "Debe seleccionar al menos un día")
    .required("Este campo es requerido"),

  openingTime: yup.string().required("Este campo es requerido"),
  closingTime: yup
    .string()
    .required("Este campo es requerido")
    .test(
      "closing-time",
      "El horario de clausura debe ser posterior al de apertura",
      function (value) {
        const { openingTime } = this.parent;
        if (openingTime && value) {
          const openingMoment = moment(openingTime, "HH:mm");
          const closingMoment = moment(value, "HH:mm");
          return closingMoment.isAfter(openingMoment);
        }
        return true;
      }
    ),

  DeliveryType: yup.string().required("Este campo es requerido"),
});
