import React, { useContext, useState } from "react";
import useReview from "../../../hooks/useReview";
import NavBack from "../../navbar/Navback";
import useStyles from "./reviewFormStyles";
import { TextArea } from "../../inputs/TextArea";
import { Input } from "../../inputs/Input";
import { Button } from "@material-ui/core";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Product from "../../product/Product";
import Spinner from "../../spinner/Spinner";
import RatingStars from "../reviewStars/reviewStars";
import Overlay from "../../overlay/Overlay";
import ReviewPublishedOverlay from "../reviewPublishedOverlay/reviewPublishedOverlay";
import { AuthContext } from "../../../Auth";
const ReviewForm = ({}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const { currentUser } = useContext(AuthContext);
  const product = location.state;
  const [isLoading, setIsLoading] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);

  const submitCallback = () => {
    //history.goBack();
    setIsLoading(false);
  };
  const handleBack = () => {
    history.goBack();
  };
  const [formik, handleSetAllTouched] = useReview(
    () => submitCallback(),
    {
      reviewerUID: currentUser.uid,
      title: "",
      description: "",
      rating: 1,
    },
    product
  );
  const handleCancel = () => {
    history.goBack();
  };
  const handleSubmitForm = async () => {
    let validations = await formik.validateForm(formik.values);
    if (!isLoading && Object.keys(validations).length === 0) {
      formik.submitForm();
      setIsLoading(true);
    } else {
      handleSetAllTouched();
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Overlay
        show={showOverlay}
        clickOnOverlayBackground={() => {
          setShowOverlay(false);
        }}
      >
        <ReviewPublishedOverlay handleBack={handleBack} />
      </Overlay>
      <div className={classes.Container}>
        <NavBack title={"NUEVA RESEÑA"}></NavBack>

        <div className={classes.mainContainer}>
          <Product key={product.id} content={product} enabled={false} />

          <form onSubmit={formik.handleSubmit}>
            <div>
              <Input
                type="text"
                id="title"
                name="title"
                placeholder={"Título de la reseña"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                classTypes={classes.input}
              />
              {formik.touched.title && formik.errors.title && (
                <span className={classes.error}>{formik.errors.title}</span>
              )}
            </div>
            <div>
              <TextArea
                id="description"
                name="description"
                placeholder={"Descripción"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                classTypes={classes.textArea}
              />
              {formik.touched.description && formik.errors.description && (
                <span className={classes.error}>
                  {formik.errors.description}
                </span>
              )}
            </div>
            <RatingStars
              value={formik.values.rating}
              setValue={(rating) => {
                formik.setFieldValue("rating", rating, false);
              }}
            />

            <div className={classes.buttonContainer}>
              <Button
                disableElevation
                className={classes.btnPreview}
                type="button"
                variant="contained"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                disableElevation
                className={classes.buttonSubmit}
                variant="contained"
                disabled={isLoading}
                onClick={() => {
                  setShowOverlay(true);
                  setIsLoading(true);
                  handleSubmitForm();
                }}
              >
                {isLoading ? <Spinner /> : "Confirmar"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReviewForm;
