import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({ 
    compartir: {
        height: '48px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '19px',
        textAlign: 'center',
        color: '#FB7E08',
        backgroundColor: '#fff',
        border: '1.76471px solid #FB7E08',
        borderRadius: '8px',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#fff"
        },
        "&:focus": {
            backgroundColor: "#fff"
        }
    }
}));

export default useStyles;