import {React} from 'react'
import useStyles from './ModalForCategoriesStyles'
import ClearIcon from "@material-ui/icons/Clear";
import {categories} from '../../utils/Constants'
import CategoryChip from './CategoryChip';
import { Button }from '@material-ui/core'

const ModalForCategories = ({handleModal, show,updateCategory,selectedCategories=[""]}) => {
  const classes = useStyles();
  const categoriesToDisplay={...categories}
  if (categoriesToDisplay.hasOwnProperty("Todos los Productos")){
    delete categoriesToDisplay["Todos los Productos"]
  }
  
  return (
    <div className={!show ? classes.modalContainerHidden : classes.modalContainer}>
        <div id="headerModal">
          <ClearIcon 
            style={{color: '#FB7E08'}} 
            className={classes.closeButton}
            onClick={()=>handleModal(false)}
          />
          <div className={classes.title}>Seleccionar Categoria</div>
        </div>
        <div className={classes.categoriesContainer}>
          {Object.entries(categoriesToDisplay).map((category, index) => (
            <CategoryChip 
            category={category} 
            index={index} 
            handleClickOnFather={updateCategory} 
            initialState={selectedCategories.includes(String(category[0]))}/>
          ))}
        </div>
        <section className={classes.submitSection}>
          <Button className={classes.btnSubmit} onClick={()=>handleModal(true)}>
            Guardar 
          </Button>
        </section>
        
    </div>
  )
}

export default ModalForCategories