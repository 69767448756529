import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  DeliveryOptionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
