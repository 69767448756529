import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    checkbox: {
        appearance: 'none',
        marginRight: 8,
        width: 16,
        height: 16,
        borderRadius: '0.5px',
        border: '0.8px solid #FB7E08',
        outline: 'none',
        cursor: 'pointer',
    },
    checked: {
        appearance: 'none',
        marginRight: 8,
        width: 16,
        height: 16,
        borderRadius: '0.5px',
        border: '0.8px solid #FB7E08',
        outline: 'none',
        cursor: 'pointer',
        backgroundColor: '#FB7E08',
    },
    day: {
        color: '#1A1A1A',
        lineHeight: '27px',
        verticalAlign: 'bottom',
    },
    dayOptionContainer: {
        padding: '4px 24px',
    },
    bottomLine: {
        borderBottom: '0.3px solid #828282',
        marginTop: 6,
    },
}))

export default useStyles;