import React from 'react'
import useStyles from '../business/BusinessInfoStyles';

const BusinessInfo = ({data}) => {
    const classes = useStyles();
    const {contactName, contactNumber, zone} = data;
  return (
    <div className={classes.container}>
      <p className={classes.businessName}>{contactName}</p>
      <div className={classes.infoContainer}>
        <p className={classes.zoneLabel}>📍 Ubicación</p>
        <p className={classes.zone}>{zone}</p> 
        <hr style={{'opacity': '0.5'}}/>
        <p className={classes.contactLabel}>📱 Contacto</p>
        <p className={classes.contactNumber}>{contactNumber}</p>
      </div>
     
    </div>
  )
}

export default BusinessInfo