import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  category: {
    boxSizing: 'border-box',
    flex: '0 0 auto',
    marginRight: '8px',
    
    background: '#FFFFFF',
    boxShadow: '1px 1px 10px rgba(186, 162, 133, 0.3)',
    borderRadius: '8px',
    width: '74px',
    height: '83px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '15px',
    textAlign: 'center',
    letterSpacing: '0.2px',
    height: '25%',
  },
  img: {
    width: '29px',
    height: '28px',
  },
  categorySelected: {
    border: '2px solid #FB7E08',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    marginRight: '8px',
    
    background: '#FFFFFF',
    boxShadow: '1px 1px 10px rgba(186, 162, 133, 0.3)',
    borderRadius: '8px',
    width: '74px',
    height: '83px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));