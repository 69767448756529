import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 8,
        paddingTop: 4,
        textAlign: 'left',
    },
    businessName: {
        color: '#253376',
        fontSize: 32,
        fontWeight: 700,
    },
    contactName: {
        fontSize: 11,
        color: '#404040',
    },
    zone: {
        lineHeight:'14px',
        width: 'auto',
        color: '#253376',
    },
}));

export default useStyles