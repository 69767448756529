import {  makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    MenuContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'end',
        backgroundColor: 'rgba(251, 126, 8, 0.2)',
        zIndex: 2000, 
    },
    Menu:{
        display:"flex",
        flexDirection:"column",
        justifyContent: 'center',
        position: "absolute",
        width: "100%",
        backgroundColor: "white",
        borderRadius: 12,
        backdropFilter: "blur(10px)",
    },
    confirmButtonStyle:{
        margin: '16px auto',
        width: '90.4%',
        minHeight:"48px",
        borderRadius: '8px',
        textTransform: "none",
        backgroundColor: '#FB7E08',
        color: 'white',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight:"700",
        '&:hover': {
            backgroundColor: '#FB7E08',
            }
    },
    filtersTitle:{
        fontFamily:"Nunito Sans",
        fontWeight:"800",
        fontSize:"16px",
        color:"#253376",
        marginBottom:"8px",
        marginTop:"24px",
        textAlign:"center"
    },
    closeButton: {
        width: 24,
        position: 'absolute',
        right: 12,
        top: 12,
    },
  }));

  export default useStyles