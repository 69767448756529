import { Grid, Typography } from '@material-ui/core';
import React from 'react'
import useStyles from './FooterStyles';
import logoFooterWhite from '../../Images/Logo/desdecasalogo-white.svg';
import iconCall from '../../Icons/footer/call.svg';
import iconInstagram from '../../Icons/footer/instagram.svg';
import iconFacebook from '../../Icons/footer/facebook.svg';
import iconMail from '../../Icons/footer/mail.svg';
import Link from '@material-ui/core/Link';


const Footer = () => {
    const classes = useStyles();
    return (
        <div>
            <footer className={classes.root}>

                <Grid container className={classes.container}>
                    <Grid container direction="row">
                        <Grid item xs={12} sm={12} md={12} className={classes.logo}>
                            
                            <img src={logoFooterWhite} alt="logo" />
                        
                        </Grid>
                    </Grid>

                    <Grid container direction="row" className={classes.contactArea}>

                        <Grid item className={classes.contactInfoContainer}>
                            <Typography variant="h6" gutterBottom className={classes.contactInfo}>
                                <Link underline="none" target="_blank" href="https://wa.me/+59172203591/" className={classes.contactLink}>
                                    <img src={iconCall} className={classes.contactIcon} alt="llamar a" />
                                    +591 72203591
                                    
                                </Link>
                            </Typography>
                            <Typography variant="h6" gutterBottom className={classes.contactInfo}>
                                <Link underline="none" target="_blank" href="https://instagram.com/desdecasa.bolivia" className={classes.contactLink}>
                                    <img src={iconInstagram} className={classes.contactIcon} alt="instagram"/>
                                    desdecasa.bolivia
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item className={classes.contactBuffer}></Grid>
                        <Grid item className={classes.contactInfoContainer}>
                            <Typography variant="h6" gutterBottom className={classes.contactInfo}>
                                <Link underline="none" target="_blank" href="https://www.facebook.com/Desde-Casa-Bolivia-Plataforma-para-comida-casera-103413602166975" className={classes.contactLink}>
                                    <img src={iconFacebook} className={classes.contactIcon} alt="facebook"/>
                                    Desde Casa Bolivia
                                </Link>

                            </Typography>
                            <Typography variant="h6" gutterBottom className={classes.contactInfo}>
                                <Link underline="none" target="_blank" href="mailto:hola@desdecasabolivia.com" className={classes.contactLink}>
                                    <img src={iconMail} className={classes.contactIcon} alt="correo"/>
                                    hola@desdecasabolivia.com
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className={classes.cright}>
                        <Typography variant="body2" gutterBottom style={{ margin: "0px", fontSize: "16px" }}>
                            © 2022 DesdeCasa Bolivia
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className={classes.legalText}>
                        <Typography variant="body2" gutterBottom style={{ margin: "0px", fontSize: "11px", marginBottom: "16px" }}>
                            Desde Casa no se hace responsable por los productos comercializados ni el servicio prestado
                        </Typography>
                    </Grid>
                </Grid>
            </footer>
        </div>
    )
}

export default Footer