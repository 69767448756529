import { Button } from '@material-ui/core';
import React from 'react'
import useStyles from './ShareButtonStyles';

const ShareButton = ({shareDetails}) => {
    const classes = useStyles();
    const canonical = document.querySelector("link[rel=canonical]");
    let url = canonical ? canonical.href : document.location.href;
    const share = async () => {
        if (navigator.share) {
            try {
              await navigator.share({ ...shareDetails, url });
              console.log("Data was shared successfully");
            } catch (err) {
              console.error("Share failed:", err.message);
            }
        }
    }

  return (
    <Button
        fullWidth
        disableElevation
        variant="contained"
        color="default"
        onClick={share}
        className={classes.compartir}
    >
        Compartir con tus amigos
    </Button>
  )
}

export default ShareButton