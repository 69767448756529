import React, { useEffect, useState } from 'react';
import app from './firebase';
import { getUser } from './dataBaseAdapter';
import { useDispatch } from "react-redux";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = app.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        console.log("gets User");
        const userData = await getUser(authUser.uid);
        console.log(userData);
        dispatch({ type: "user/set",payload:userData })
        setCurrentUser(authUser);
      } else {
        setCurrentUser(null);
        dispatch({ type: "user/unset"})
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{ currentUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};