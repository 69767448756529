import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    ratingStars: {
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      width:"100%"

    },
    starsContainer: {
        display:"flex",
        flexDirection:"row",
        width:"64%"

    },
    starContainer: {
      cursor: 'pointer',
      margin: '0 5px',
      width: '40px',
      height: '40px',
      position: 'relative',
      transition: 'fill 0.2s',
    },
    star: {
      width: '100%',
      height: '100%',
      fill: 'transparent',
      stroke: '#ccc',
      strokeWidth: 1,
      transition: 'fill 0.2s',
    },
    selectedStar: {
      fill: 'gold',
      stroke: 'none',
    },
    selectedRating: {
        fontFamily:"Open Sans",
        fontSize:"14px",
        fontWeight:"400",
        color:"#253376",
        lineHeight:"19.07px",
        marginTop: '10px',
    },
  });
export default useStyles