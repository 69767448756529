import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  ratingStars: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "4px",
    marginBottom: "4px",
  },
  starsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  starContainer: {
    cursor: "pointer",
    margin: "0 1px",
    width: "16px",
    height: "16px",
    position: "relative",
    transition: "fill 0.2s",
  },
  star: {
    width: "100%",
    height: "100%",
    fill: "transparent",
    stroke: "#ccc",
    strokeWidth: 1,
    transition: "fill 0.2s",
  },
  selectedStar: {
    fill: "gold",
    stroke: "none",
    strokeWidth: 1,
  },
  selectedRating: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "400",
    color: "#253376",
    marginRight: "8px",
  },

  avgRatingRight: {
    fontFamily: "Nunito Sans",
    fontSize: "15px",
    fontWeight: "300",
    color: "#404040",
    marginLeft: "8px",
  },
});
export default useStyles;
