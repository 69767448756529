import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(0.5),
            width: theme.spacing(90),
            height: theme.spacing(30),
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        height: 'auto',
    },
    avatar: {
        backgroundColor: "#ff5722",
    },
    typography: {
        marginBottom: "1rem",
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      headerMobile: {
        display: 'flex',
        alignItems: 'center',
      },
}));

export default useStyles