import React, { useState } from "react";
import useStyles from "./ModalForDeliveryTypeStyles";
import ClearIcon from "@material-ui/icons/Clear";
import RadioOption from "../inputs/RadioOption";
import Button from "@material-ui/core/Button";

const ModalForDeliveryType = ({
  handleModal,
  show,
  initialValue = "",
  onChange,
}) => {
  const classes = useStyles();
  const [activeType, setActiveType] = useState(initialValue);
  const [lastSavedValue, setLastSavedValue] = useState(initialValue);

  const options = [
    { option: "Solo Recoger", value: "solo-recoger" },
    { option: "Costo Aparte", value: "costo-aparte" },
    { option: "Costo Incluido", value: "costo-incluido" },
  ];

  const handleExit = (save) => {
    if (save) {
      setLastSavedValue(activeType);
      onChange(activeType);
    } else {
      setActiveType(lastSavedValue);
    }
    handleModal();
  };

  return (
    <div
      className={!show ? classes.modalContainerHidden : classes.modalContainer}
    >
      <ClearIcon
        style={{ color: "#FB7E08" }}
        className={classes.closeButton}
        onClick={() => handleExit(false)}
      />
      <div className={classes.title}>Tipo de entrega</div>
      <div className={classes.deliveryOptionsContainer}>
        {Object.entries(options).map((opt) => (
          <RadioOption
            opt={opt}
            key={opt[1].option}
            isActive={activeType === opt[1].option}
            onSelect={() => setActiveType(opt[1].option)}
          />
        ))}
      </div>
      <section className={classes.submitSection}>
        <Button className={classes.btnSubmit} onClick={() => handleExit(true)}>
          Guardar
        </Button>
      </section>
    </div>
  );
};

export default ModalForDeliveryType;
