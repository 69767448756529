import React from "react";
import Login from "./views/login/Login";
import Signup from "./views/signup/Signup";
import Recoverpswd from "./views/recoverpswd/Recoverpswd";
import BusinessProfile from "./views/business/BusinessProfile";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./Auth";
import { deepOrange } from "@material-ui/core/colors";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Profile from "./views/profile/Profile";
import { combineReducers } from "redux";
import Home from "./views/home/Home";
import "./style.css";
import PrivateRoute from "./PrivateRoute";
import NewProduct from "./views/newproduct/NewProduct";
import EditProduct from "./views/editproduct/EditProduct";

import { Helmet } from 'react-helmet';
import Reviews from "./views/reviews/Reviews";

const customTheme = createTheme({
  typography: {
    fontFamily: ['"Open Sans"', '"Nunito Sans"'].join("."),
  },
  palette: {
    primary: deepOrange,
    secondary: {
      main: "#ffffff",
      dark: "#FB7E08",
    },
  },
});

export const asyncMiddleware = (store) => (next) => (action) => {
  if (typeof action === "function") {
    return action(store.dispatch, store.getState);
  }
  return next(action);
};

export const fetchThunk = () => (dispatch) => {
  console.log("I am a thunk", dispatch);
};

const initialFetchingState = { loading: "should", error: null };

export const userReducer = (state = {user:null}, action) => {
  switch (action.type) {
    case "user/set": {
      return {user: action.payload };
    }
    case "user/unset": {
      return {user: null };
    }
    default: {
      return state;
    }
  }
};
export const fetchingReducer = (state = initialFetchingState, action) => {
  switch (action.type) {
    case "fetcher/pending": {
      return { ...state, loading: "pending" };
    }
    case "fetcher/succeded": {
      return { ...state, loading: "succeded" };
    }
    case "fetcher/shouldFetch": {
      return { ...state, loading: "should" };
    }
    case "fetcher/guideRefreshed": {
      return { ...state, loading: "guideRefreshed" };
    }
    case "fetcher/refreshList": {
      return { loading: "refreshList" };
    }
    case "fetcher/error": {
      return { error: action.error, loading: "error" };
    }
    default: {
      return state;
    }
  }
};

export const productsReducer = (state = { entities: [], index: 0 }, action) => {
  switch (action.type) {
    case "products/fulfilled":
      return {
        entities: [...state.entities, ...action.payload],
        index: state.index + action.index,
      };
    case "products/switchedFullFilled":
      return { ...state, entities: [...action.payload], index: action.index };
    case "products/allfetched":
      return { entities: [...state.entities], index: "last" };
    default:
      return state;
  }
};

export const searchReducer = (
  state = { entities: [], index: 0, searchParam: "", noProductsFound: false },
  action
) => {
  switch (action.type) {
    case "searchs/fulfilled":
      return {
        entities: [...state.entities, ...action.payload],
        index: state.index + action.index,
        searchParam: state.enabled,
        noProductsFound: false,
      };
    case "searchs/newSeach":
      return {
        searchParam: action.searchParam,
        noProductsFound: false,
      };
    case "searchs/switchedFullFilled":
      return {
        ...state,
        entities: [...action.payload],
        index: action.index,
        noProductsFound: false,
      };
    case "searchs/allfetched":
      return {
        ...state,
        entities: [...state.entities],
        index: "last",
        noProductsFound: false,
      };
    case "searchs/noProductsFound":
      return { ...state, noProductsFound: true };
    default:
      return state;
  }
};

export const categoryReducer = (
  state = { entities: [], index: 0, enabled: false, oldCategory: null },
  action
) => {
  //console.log({ state });
  switch (action.type) {
    case "category/fulfilled":
      return {
        ...state,
        entities: [...state.entities, ...action.payload],
        enabled: action.enabled,
        index: state.index + action.index,
      };
    case "category/disabled":
      return { enabled: action.enabled };
    case "category/newCategory":
      return {
        ...state,
        oldCategory: state.enabled,
        enabled: action.newCategory,
      };
    case "category/switchedFullFilled":
      return {
        ...state,
        entities: [...action.payload],
        enabled: action.enabled,
        index: action.index,
      };

    case "category/allfetched":
      return {
        ...state,
        entities: [...state.entities],
        index: "last",
        enabled: state.enabled,
      };

    default:
      return state;
  }
};
export const guidesReducer = (
  state = { guides: [], categoryGuides: {} },
  action
) => {
  //console.log({ state });
  switch (action.type) {
    case "guides/productsGuideRetrieved":
      return {
        ...state,
        guides: [...action.payload],
      };
    case "guides/addProductToGuide":
      return {
        ...state,
        guides: [...state.guides, action.product],
      };
    case "guides/addCategorieGuide":
      return {
        ...state,
        categoryGuides: {
          ...state.categoryGuides,
          [action.category]: action.payload,
        },
      };
    default:
      return state;
  }
};
export const filtersReducer = (
  state = { entities: [], enabled: false, index: 0, extraFilters: {} },
  action
) => {
  //console.log({ state });
  switch (action.type) {
    case "filter/fulfilled":
      return {
        ...state,
        entities: [...state.entities, ...action.payload],
        index: state.index + action.index,
        enabled: true,
      };
    case "filter/switchedFullFilled":
      return {
        ...state,
        entities: [...action.payload],
        index: action.index,
        enabled: true,
      };
    case "filter/extraFilter":
      return { extraFilters: action.extraFilters };
    case "filter/disabled":
      return { enabled: false, extraFilters: {} };
    case "filter/allfetched":
      return {
        ...state,
        entities: [...state.entities],
        index: "last",
        enabled: true,
      };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  products: productsReducer,
  searchs: searchReducer,
  category: categoryReducer,
  guides: guidesReducer,
  filter: filtersReducer,
  fetcher: fetchingReducer,
  user:userReducer
});

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <AuthProvider>
        <Router>
          <div className="App">
            <Helmet>
              <meta property="og:title" content={"DesdeCasaBolivia"} />
              <meta property="og:description" content={"Descubre la gastronomia artesanal en Cochabamba."}/>
              <meta property="og:image" content={"src/Images/Logo/desdecasalogo-white.svg"}/>
              <meta property="og:type" content={"website"} />
            </Helmet>
            <Switch>
              <Route
                exact
                path="/negocio/:contactName/:id"
                component={BusinessProfile}
              />
              <Route exact path="/profile" component={Profile} />
              <PrivateRoute exact path="/newproduct" component={NewProduct} />
              <PrivateRoute exact path="/editproduct" component={EditProduct} />
              <PrivateRoute exact path="/productReview" component={Reviews} />

              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/recovery" component={Recoverpswd} />
              <Route exact path="/*" component={Home} />
            </Switch>
          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
