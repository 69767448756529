import { getProductsGuideDB } from "../dataBaseAdapter";
import { useSelector } from "react-redux";
const processGuideData = (guideData) => {
    const guideArray = Object.entries(guideData).map(([id, product]) => ({
      id:id,
      ...product
    }));
    
    guideArray.sort((productA, productB) => {

        if(!productB?.rating)
        {
            return -1
        }
        if(!productA?.rating)
        {
            return 1
        }

        if (productB.rating.toFixed(1) === productA.rating.toFixed(1)) {
            return (
                (productB?.reviewsLength) - 
                (productA?.reviewsLength)
            );
        }
    
    return productB.rating - productA.rating
    } )
    return guideArray;
  };
export const useGuides = (  ) => {
    const guide = useSelector(state => state.guides.guides)

    const getProductsGuide = () => async dispatch => {
        dispatch({ type: 'guides/pending' });
        try {
            
            // eslint-disable-next-line eqeqeq
            if (guide?.productGuide && Object.keys(guide["productGuide"]).length != 0)
            {
                return
            }
            let guideData=await getProductsGuideDB()
            let guideArray=processGuideData(guideData)

            dispatch({ 
                type: 'guides/productsGuideRetrieved', 
                payload: guideArray, 
            });

        } catch (error) {
            dispatch({ type: 'guides/error', error: error });
        }
    }

    return {
        getProductsGuide
    }

}