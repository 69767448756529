import { db, storage, fieldValue, facebookProvider } from "./firebase";
import app from "./firebase";

//docGuide
const updateProductGuide = (id, data) => {
  let newGuideData = {};

  if (data?.name) newGuideData.name = data.name;
  if (data?.lastUpdate) newGuideData.lastUpdate = data.lastUpdate; //para productos recientes
  if (data?.price) newGuideData.price = data.price; //para ordenar por precio
  if (data?.photoRating) newGuideData.photoRating = data.photoRating; //ver mejores fotos primero
  if(data?.rating) newGuideData.rating = data.rating; //ver ratings
  if(data?.reviews?.length) newGuideData.reviewsLength = data.reviews.length; //ordenar por mas reviews
  let updateObj = { [id]: newGuideData };
  db.collection("guide").doc("productGuide").set(updateObj, { merge: true });
};
const deleteProductFromGuide = (id) => {
  db.collection("guide")
    .doc("productGuide")
    .update({
      [id]: fieldValue.delete(),
    });
};
const deleteProductFromCategoryGuides = (id, categories) => {
  categories.forEach((categorie) => {
    db.collection("guide")
      .doc(categorie)
      .update({
        products: fieldValue.arrayRemove(id),
      });
  });
};
const getCategoryGuides = async (category) => {
  let doc = await db.collection("guide").doc(category).get();
  return doc.data();
};
const updateCategoriesGuide = (id, categories) => {
  categories.forEach((categorie) => {
    db.collection("guide")
      .doc(categorie)
      .set(
        {
          products: fieldValue.arrayUnion(id),
        },
        { merge: true }
      );
  });
};
const deleteFromCategoriesGuide = (id, categoriesToRemove) => {
  categoriesToRemove.forEach((categorie) => {
    db.collection("guide")
      .doc(categorie)
      .set(
        {
          products: fieldValue.arrayRemove(id),
        },
        { merge: true }
      );
  });
};

const getProductsGuideDB = async () => {
  let doc = await db.collection("guide").doc("productGuide").get();
  return doc.data();
};

//image
const saveImageToDB = (imageID, imageFile, callback) => {
  let uploadTask = storage.ref(`images/${imageID}`).put(imageFile);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      console.log("PROGRESS::", progress);
    },
    (error) => {
      console.log(error);
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        callback(downloadURL);
      });
    }
  );
};
const updateImage = async (productImageURL, newImage) => {
  const imageRef = storage.refFromURL(productImageURL);
  await imageRef.put(newImage);
  const imgUrl = await imageRef.getDownloadURL();
  return imgUrl;
};
const deleteImage = async (imageURL) => {
  await storage.refFromURL(imageURL).delete();
};
//product
const saveNewProduct = async (data) => {
  let docID;
  if (!data?.lastUpdate) data["lastUpdate"] = fieldValue.serverTimestamp();
  await db
    .collection("products")
    .add(data)
    .then((docRef) => {
      docID = docRef.id;
      updateProductGuide(docRef.id, data);
      if (data?.categories) updateCategoriesGuide(docRef.id, data.categories);
    });
  return docID;
};
const deleteProduct = async (id) => {
  let originalProductData = await getProductData(id);
  db.collection("products").doc(id).delete();
  deleteProductFromGuide(id);
  deleteProductFromCategoryGuides(id, originalProductData.categories);
  let modifiedCategoryGuides = originalProductData.categories;
  return modifiedCategoryGuides;
};

const updateProduct = async (id, data) => {
  let originalProductData = await getProductData(id);
  if (!data?.lastUpdate) data["lastUpdate"] = fieldValue.serverTimestamp();
  db.collection("products").doc(id).set(data, { merge: true });
  updateProductGuide(id, data);
  if (data?.categories) {
    updateCategoriesGuide(id, data.categories);

    let categoriesToDelete = originalProductData.categories.filter(
      (item) => !data.categories.includes(item)
    );
    deleteFromCategoriesGuide(id, categoriesToDelete);
  }
};
const getProductData = async (id) => {
  let doc = await db.collection("products").doc(id).get();
  let docData = { id: id, ...doc.data() };
  return docData;
};
const getProductDataByIds = async (ids) => {
  try {
    const productsData = await Promise.all(ids.map((id) => getProductData(id)));
    return productsData.filter((productData) => productData !== null);
  } catch (error) {
    console.error("Error fetching products by IDs:", error);
    return [];
  }
};
const handleQuery = (props) => {
  let query = db.collection("products");
  if (props?.orderingCriteria) {
    query = props?.descOrder
      ? query.orderBy(props.orderingCriteria, "desc")
      : query.orderBy(props.orderingCriteria, "asc");
  }

  if (props?.currentCategory)
    query = query.where("categories", "array-contains", props.currentCategory);

  if (props?.searchedKeyword)
    query = query.where("keywords", "array-contains", props.searchedKeyword);
  if (props?.userUID) query = query.where("user.uid", "==", props.userUID);
  if (props?.limitDocs) query = query.limit(props.limitDocs);

  if (props?.lastVisible) query = query.startAfter(props.lastVisible);
  return query;
};

const getProductsSubscription = async (props, onSnapshotCallback) => {
  let query = handleQuery(props);
  let unsubscribe = query.onSnapshot((snapshot) => {
    onSnapshotCallback(snapshot);
  });
  return unsubscribe;
};
const getCategoriesSnapshot = async (props) => {
  let query = handleQuery(props);
  const snapshot = await query.get();
  return snapshot;
};
/*
props:{
            orderingCriteria:"photoRating",
            descOrder:true
            currentCategory:category,
            searchedKeyword:"t",
            userUID:3,
            limitDocs:20,
            startDoc:5
        }
*/

//user
const createUserWithMailAndPassword = (
  mail,
  password,
  succesfulCallback,
  errorCallback
) => {
  app
    .auth()
    .createUserWithEmailAndPassword(mail, password)
    .then(() => {
      succesfulCallback();
    })
    .catch((error) => {
      errorCallback(error);
    });
};
const login = (values, succesfulCallback, errorCallback) => {
  app
    .auth()
    .signInWithEmailAndPassword(values.email, values.password)
    .then(() => {
      succesfulCallback();
    })
    .catch((e) => {
      errorCallback(e);
    });
};
const loginWithFacebook = (succesfulCallback, errorCallback) => {
  let provider = new facebookProvider
  provider.addScope('email');

  app
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      var user = result.user;
      succesfulCallback(user)
    })
    .catch((e) => {
      console.log(e);
      errorCallback(e);
    });
};


const subscribeToAuthStatus = (authChangedCallback) => {
  let unsubscribe = app.auth().onAuthStateChanged(async (user) => {
    authChangedCallback(user);
  });
  return unsubscribe;
};
const getCurrentUID = () => {
  return app.auth().currentUser.uid;
};
const createUserDoc = (uid, userData) => {
  db.collection("users").doc(uid).set(userData,{merge:true});
};
const getUser = async (userUID) => {
  const userRef = db.collection("users").doc(userUID);
  const doc = await userRef.get();
  if (!doc.exists) {
    console.log("No such document!");
  } else {
    return doc.data();
  }
};
const sendPaswordResetMail = (email, succesfulCallback, errorCallback) => {
  app
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      succesfulCallback();
    })
    .catch((error) => {
      errorCallback(error);
    });
};
const signOut = () => {
  app.auth().signOut();
};
const updateUser = async (uid, userInfo,reviewsRef=[] ,nameUpdated=false,updateProducts = false) => {
  await db.collection("users").doc(uid).set(userInfo, { merge: true });
  if (updateProducts) {
    await db
      .collection("products")
      .where("user.email", "==", userInfo.email)
      .get()
      .then((prods) => {
        const productsArray = prods.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        productsArray.forEach(async (prod) => {
          await db.collection("products").doc(prod.id).update({
            user: userInfo,
          });
        });
      });
  }
  if(nameUpdated){
    reviewsRef.forEach(reviewRef => {
      let objectToUpdate={"reviewers":{[uid]:userInfo.contactName}}
      db.collection("products").doc(reviewRef).set(objectToUpdate,{merge:true})
    });
  }
};

const addReviewRef=async(userUid,reviewRef)=>{
  let docRef=db.collection("users").doc(userUid)
  let infoToUpdate={
    reviewsRef:fieldValue.arrayUnion(reviewRef)
  }
  await docRef.set(infoToUpdate,{merge:true})
}
const deleteReviewRef=async(userUid,reviewRef)=>{
  let docRef=db.collection("users").doc(userUid)
  let infoToUpdate={
    reviewsRef:fieldValue.arrayRemove(reviewRef)
  }
  await docRef.set(infoToUpdate,{merge:true})
}

const fetchProductImages = async () => {
  try {
    const snapshot = await db.collection("highlightPictures").get();
    const productImages = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return productImages;
  } catch (error) {
    console.log("Error fetching product images:", error);
    return [];
  }
};

//deep lore
const updateCurrentProductsToAddThemToGuide = async () => {
  const productsSnapshot = await db.collection("products").get();
  const products = [];

  productsSnapshot.forEach((doc) => {
    // Add each product document to the products array
    products.push({ id: doc.id, ...doc.data() });
  });

  products.forEach((productOG) => {
    let product = { ...productOG };
    if (!product?.lastUpdate)
      product["lastUpdate"] = fieldValue.serverTimestamp();
    product["price"] = Number(product["price"]);
    updateProductGuide(product.id, product);
    if (product?.categories)
      updateCategoriesGuide(product.id, product.categories);
  });

  //console.log("done")
};

export {
  saveImageToDB,
  deleteImage,
  saveNewProduct,
  deleteProduct,
  getUser,
  login,
  getCategoriesSnapshot,
  createUserWithMailAndPassword,
  createUserDoc,
  getCurrentUID,
  getProductData,
  sendPaswordResetMail,
  signOut,
  updateProduct,
  updateImage,
  getProductsSubscription,
  updateUser,
  subscribeToAuthStatus,
  getProductsGuideDB,
  getProductDataByIds,
  getCategoryGuides,
  updateCurrentProductsToAddThemToGuide,
  fetchProductImages,
  loginWithFacebook,
  addReviewRef,
  deleteReviewRef
};
