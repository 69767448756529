import Category from "./Category";
import { useStyles } from "./CarouselCategoriesStyles";
import { categories } from "../../utils/Constants";

const CarouselCategories = () => {
  const classes = useStyles();

  return (
    <div className={classes.carousel}>
      {Object.entries(categories).map((category, index) => (
        <div key={index}>
          <Category key={index} category={category} />
        </div>
      ))}
    </div>
  );
};

export default CarouselCategories;
