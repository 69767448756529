import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Typography,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import useStyles from "./SignupStyles";
import { useSignUp } from "../../hooks/useSignUp";
import logo from "../../Images/Logo/desdecasalogo-white.svg";
import { Input } from "../../components/inputs/Input";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import ZoneMenu from "../../components/zoneMenu/zoneMenu";


const Signup = ({ history }) => {
  const classes = useStyles();
  const { formik } = useSignUp(history);
  const hasErrors = Object.keys(formik.errors).length > 0;
  const handleLogoClick = () => {
    history.push("/");
  };
  return (
    <Grid container className={classes.loginBackground}>
      <div className={classes.loginHead}
      onClick={handleLogoClick}>
        <img
          src={logo}
          className={classes.loginHeadImage}
          alt="Logo de desdecasa"
        />
      </div>
      <section className={classes.formSection}>
        <div className={classes.wrapper}>
          <div className={classes.formContainer}>
            <h2 className={classes.title}>
              Crear Cuenta <br /> Para Mi Negocio
            </h2>
            <div className={classes.fieldLeft}>
              <p className={classes.font600}>Información para mi cuenta</p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Input
                type="email"
                placeholder="Correo Electrónico"
                name="email"
                classTypes={classes.input}
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && (
                <Typography variant="body2" className={classes.error}>
                  {formik.errors.email}
                </Typography>
              )}
              <Input
                type="password"
                placeholder="Contraseña"
                name="password"
                classTypes={classes.input}
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
              />
              {formik.touched.password &&(
                <Typography variant="body2" className={classes.error}>
                  {formik.errors.password}
                </Typography>
              )}
              <Input
                type="password"
                placeholder="Confirmar Contraseña"
                name="confirmPassword"
                classTypes={classes.input}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                onBlur={formik.handleBlur}
              />{" "}
              {formik.touched.confirmPassword && (
                <Typography variant="body2" className={classes.error}>
                  {formik.errors.confirmPassword}
                </Typography>
              )}
              <div className={classes.businessContainer}>
                <div className={classes.fieldLeft}>
                  <p className={classes.font600}>Información de mi negocio</p>
                </div>
                <Input
                  type="tel"
                  placeholder="Número de WhatsApp"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  name="contactNumber"
                  classTypes={classes.input}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,8}$/.test(value)) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.contactNumber}
                  onBlur={formik.handleBlur}
                  maxLength={8} 
                />
                {formik.touched.contactNumber && (
                  <Typography variant="body2" className={classes.error}>
                    {formik.errors.contactNumber}
                  </Typography>
                )}
                <Input
                  type="text"
                  placeholder="Nombre del Negocio o Marca"
                  name="contactName"
                  classTypes={classes.input}
                  onChange={formik.handleChange}
                  value={formik.values.contactName}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.contactName && (
                  <Typography variant="body2" className={classes.error}>
                    {formik.errors.contactName}
                  </Typography>
                )}
                <ZoneMenu
                  placeholder={formik.values.zone ? formik.values.zone : "Zona Temporal"}
                  onSelect={(value) => formik.setFieldValue("zone", value)}
                  value={formik.values.zone}
                />
              </div>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                type="submit"
                target="_blank"
                className={`${classes.button} ${
                  !hasErrors ? classes.orangeButton : classes.grayButton
                }`}
              >
                Crear Cuenta
              </Button>
              <div className={classes.fieldLeft}>
                <p className={`font400 ${classes.bold}`}>
                  Al hacer clic en Crear Cuenta, acepto que he leído y aceptado
                  los{" "}
                  <span className={`${classes.textOrange} ${classes.bold}`}>
                    Términos de Uso y la Política de Privacidad
                  </span>{" "}
                  de DesdeCasa Bolivia.
                </p>
              </div>
              <div className={classes.fieldCenter}>
                <p style={{ fontSize: "14px" }}>
                  ¿Tienes una cuenta?{" "}
                  <Link
                    className={`${classes.textOrange14} ${classes.bold}`}
                    to="/login"
                    style={{ fontWeight: "bold" }}
                  >
                    Iniciar Sesión
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </Grid>
  );
};

export default withRouter(Signup);
