import React from "react";

export const TextArea = ({
  placeholder,
  classTypes,
  name,
  value = "",
  onChange,
  onBlur
}) => {
  return (
    <textarea
      placeholder={placeholder}
      className={classTypes}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
