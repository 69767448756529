import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import BusinessInfo from "../../components/business/BusinessInfo";
import BusinessProducts from "../../components/business/BusinessProducts";
import NavBack from "../../components/navbar/Navback";
import useStyles from "./BusinessProfileStyles";
import houseicon from "../../Images/cafehouseicon.png";
import ShareButton from "../../components/shareButton/ShareButton";
import { getUser, getCategoriesSnapshot } from "../../dataBaseAdapter";
const BusinessProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const id = history.location.pathname.split("/").pop();
  const business_id = history.location.state ? history.location.state.id : id;
  const [isLoading, setIsLoading] = useState(true);
  const [businessData, setBusinessData] = useState();
  const [businessProducts, setBusinessProducts] = useState();

  useEffect(() => {
    const fetchBusinessData = async () => {
      await getUser(business_id).then((data) => {
        setBusinessData(data);
        setIsLoading(false);
      });
    };
    fetchBusinessData();
  }, [business_id]);

  useEffect(() => {
    const fetchBusinessProducts = async () => {
      try {
        await getCategoriesSnapshot({ userUID: business_id }).then(
          (snapshot) => {
            let products = [];

            snapshot.forEach((doc) =>
              products.push({ ...doc.data(), id: doc.id })
            );

            setBusinessProducts(products);
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchBusinessProducts();
  }, [business_id]);

  return (
    <div>
      <NavBack />
      <div className={classes.imageContainer}>
        <div className={classes.circleBackground}>
          {businessData ? (
            businessData.logo ? (
              <img
                src={businessData.logo}
                alt={console.log("NO LOGO")}
                className={classes.img}
              />
            ) : (
              <img src={houseicon} className={classes.img} alt="" />
            )
          ) : (
            console.log("no business data")
          )}
        </div>
      </div>
      {isLoading ? <p>cargando...</p> : <BusinessInfo data={businessData} />}
      <p className={classes.title}>PRODUCTOS</p>
      <BusinessProducts data={businessProducts} />
      {isLoading ? (
        <p>cargando...</p>
      ) : (
        <div
          style={{
            padding: "12px 20px",
            position: "sticky",
            bottom: 0,
            "background-color": "white",
          }}
        >
          <ShareButton
            shareDetails={{
              title: businessData.contactName,
              text: businessData.contactNumber,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BusinessProfile;
