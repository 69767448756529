import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStylesNav from "./NavbackStyles";

const NavbackPreview = ({ title, handleClick }) => {
  const history = useHistory();
  const classes = useStylesNav();

  const handleTitle = () => {
    const pathname = history.location.pathname;
    const titlepath = pathname.split("/").filter((e) => e !== "");
    if (titlepath[0] === "negocio") {
      return "PERFIL VENDEDOR";
    }
    return title || "VISTA PREVIA";
  };

  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          className={classes.iconBack}
          edge="start"
          onClick={handleClick}
          aria-label="close"
        >
          <ArrowBackIcon style={{ color: "#FB7E08" }} />
        </IconButton>
        <Typography className={classes.title} align="center">
          {handleTitle()}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default NavbackPreview;
