import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  horarios: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '15px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: '460px',
    width: '400px',
    fontFamily: 'Roboto',
    overflow: 'auto',
    [theme.breakpoints.down(500)]: {
      height: 'auto',
      width: 'auto',
    },
  },
  image: {
    width: 240,
    height: 240,
  },
  input: {
    display: 'none',
  },
  horarioLabel: {
    marginRight: "8px",
    [theme.breakpoints.down(380)]: {
      width: '100px',
    },
  },
  textfield: {
    margin: '4px 0px',
    
  },
  uploadButton: {
    margin: '0px',
  },
  buttonPublicar: {
    margin: '16px 0px',
  },
  title: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
  },
  thumbnail: {
    marginLeft: '16px',
    objectFit: 'cover',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    margin: '0px',
  },
  buttonProgress: {
    marginLeft: '16px',
  },
}));

export default useStyles