/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Product from "./Product";
import useStyles from "./ProductListStyles";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFetchProducts } from "../../hooks/useFetchProducts";

const ProductList = ({ searchInput }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const guide = useSelector((state) => state.guides.guides);
  const shouldFetch = useSelector((state) => state.fetcher.loading);

  const productsFromStore = useSelector((state) => state.products.entities);
  const lastVisibleFromStore = useSelector((state) => state.products.index);
  const productsFromSearch = useSelector((state) => state.searchs.entities);
  const lastProductInSearch = useSelector((state) => state.searchs.index);
  const productsFromCategory = useSelector((state) => state.category.entities);
  const isCategorySelected = useSelector((state) => state.category.enabled);
  const lastProductInCategory = useSelector((state) => state.category.index);

  const productsFromFilter = useSelector((state) => state.filter.entities);
  const filterEnabled = useSelector((state) => state.filter.enabled);
  const lastFilter = useSelector((state) => state.filter.index);

  const extraFilters = useSelector((state) => state.filter.extraFilters);

  const [showingProductListRef, setShowingProductListRef] = useState();

  const classes = useStyles();

  const { fetchApropiateProducts } = useFetchProducts();

  useEffect(() => {
    const fetchData = async (guideRefreshed = false) => {
      try {
        //console.log(productsFromStore.length==0 || guideRefreshed );
        if (
          Object.keys(extraFilters).length > 0 ||
          searchInput ||
          searchInput != "" ||
          (isCategorySelected && isCategorySelected != "Todos los Productos")
        ) {
          dispatch(fetchApropiateProducts(false));
        } else if (productsFromStore.length == 0 || guideRefreshed) {
          dispatch(fetchApropiateProducts(true));
        } else {
          dispatch({ type: "filter/disabled" });
          dispatch({ type: "fetcher/succeded" });
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (guide.length == 0) return;
    if (shouldFetch != "should" && shouldFetch != "guideRefreshed") return;
    setLoading(true);
    let guideRefreshed = shouldFetch === "guideRefreshed" ? true : false;

    fetchData(guideRefreshed);
  }, [dispatch, shouldFetch, guide]);

  const handleLoadMore = () => {
    if (
      Object.keys(extraFilters).length > 0 ||
      searchInput ||
      (isCategorySelected && isCategorySelected != "Todos los Productos")
    ) {
      dispatch(fetchApropiateProducts(false, true));
    } else {
      dispatch(fetchApropiateProducts(true, true));
    }
  };

  const handleButton = () => {
    if (searchInput) {
      if (lastProductInSearch === "last") {
        return true;
      }
    }
    if (filterEnabled) {
      if (lastFilter === "last") {
        return true;
      }
    }
    if (isCategorySelected) {
      if (lastProductInCategory === "last") {
        return true;
      }
    } else {
      if (lastVisibleFromStore === "last") {
        return true;
      }
    }
    return false;
  };

  const determineWhichProductListShouldBeShowed = () => {
    if (filterEnabled) {
      setShowingProductListRef(productsFromFilter);
    } else if (
      isCategorySelected &&
      isCategorySelected != "Todos los Productos"
    ) {
      setShowingProductListRef(productsFromCategory);
    } else if (searchInput) {
      setShowingProductListRef(productsFromSearch);
    } else {
      setShowingProductListRef(productsFromStore);
    }
  };
  useEffect(() => {
    if (shouldFetch == "succeded" || shouldFetch == "refreshList") {
      determineWhichProductListShouldBeShowed();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  return (
    <React.Fragment>
      {isCategorySelected ? (
        <p className={classes.titleProductList}>{isCategorySelected}</p>
      ) : (
        <p className={classes.titleProductList}>TODOS LOS PRODUCTOS</p>
      )}

      {searchInput && (
        <div className={classes.resultados}>
          Resultados ({showingProductListRef && showingProductListRef.length})
        </div>
      )}
      {loading ? (
        <div className={classes.loadingSpinner}>
          <CircularProgress />
        </div>
      ) : (
        <ul className={classes.list}>
          {showingProductListRef &&
            showingProductListRef.map((product) => (
              <Product key={product.id} content={product} />
            ))}
        </ul>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "32px 0px",
        }}
      >
        {handleButton() ? (
          "no hay mas productos"
        ) : (
          <Button
            disableElevation
            className={classes.btnSeeMore}
            type="submit"
            variant="contained"
            onClick={() => handleLoadMore()}
          >
            Ver más
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProductList;
