import { addReviewRef, getUser, updateProduct,deleteReviewRef } from "../dataBaseAdapter";
import { fieldValue } from "../firebase";
class reviewPort {
  static createReview = async (reviewInfo, productInfo, user) => {
    let ratingCalculation;
    let reviews;
    if (productInfo?.reviews) {
      let numberOfRatings = productInfo.reviews.length;
      ratingCalculation =
        (Number(reviewInfo.rating) + numberOfRatings * productInfo.rating) /
        (numberOfRatings + 1);
      ratingCalculation = Math.round(ratingCalculation * 100) / 100;
      reviews = [...productInfo.reviews];
    } else {
      ratingCalculation = reviewInfo.rating;
      reviews = [];
    }
    let currentDate = new Date();
    let date = {
      day: currentDate.getDate(),
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
      hour: currentDate.getHours(),
      minute: currentDate.getMinutes(),
      second: currentDate.getSeconds()
    };
    let reviewToAdd = {
      ...reviewInfo,
      date: date,
      reviewerUID:user.uid
    };
    let infoToUpdate = {
      reviewers:{[user.uid]:user.contactName},
      rating: ratingCalculation,
      reviews: [...reviews, reviewToAdd],
    };
    await updateProduct(productInfo.id, infoToUpdate);
    await addReviewRef(user.uid,productInfo.id)
    console.log("updating review", infoToUpdate);
  };




  static deleteReview = async (reviewToDelete, product) => {
    let productCopy = { ...product };
    let oldReviewsLen = productCopy.reviews.length;
    const updatedReviews = productCopy.reviews.filter(
      (review) => !this.compareReviews(review, reviewToDelete)
    );

    let newRating =
      (productCopy.rating * oldReviewsLen - reviewToDelete.rating) /
      updatedReviews.length;
    newRating = Math.round(newRating * 100) / 100;
    
    let infoToUpdate = { rating: newRating, reviews: updatedReviews };
    if(!this.checkIfReviewerIdExistsInReviews(reviewToDelete.reviewerUID,updatedReviews))
    {
      infoToUpdate={...infoToUpdate,"reviewers":{[reviewToDelete.reviewerUID]:fieldValue.delete()}}
      deleteReviewRef(reviewToDelete.reviewerUID,product.id)
    }
    await updateProduct(productCopy.id, infoToUpdate);
    productCopy.rating = newRating;
    productCopy.reviews = updatedReviews;
    return productCopy;
  };
  static compareReviews(review1, review2) {
    return (
      review1.title === review2.title &&
      review1.reviewerName === review2.reviewerName &&
      review1.description === review2.description &&
      review1.rating === review2.rating
    );
  }
  static checkIfReviewerIdExistsInReviews(uid, reviews) {

    for (let i = 0; i < reviews.length; i++) {
      if (reviews[i].reviewerUID === uid) {
        return true;
      }
    }
    return false; 
  }
}
export default reviewPort;
