export const handleDays = (content) => {
  const daysOfWeek = ["Lu", "Ma", "Mi", "Ju", "Vi", "Sá", "Do"];
  const activeColor = "orange";
  const inactiveColor = "lightgray";

  const isDaySelected = (day) => {
    const selectedDays = content.days;
    return (
      selectedDays &&
      (selectedDays.includes("Todos los días") ||
        selectedDays.some((selectedDay) =>
          selectedDay.includes(daysOfWeek[day])
        ))
    );
  };

  return daysOfWeek.map((day, index) => (
    <span
      key={day}
      style={{
        color: isDaySelected(index) ? activeColor : inactiveColor,
        marginRight: "4px",
      }}
    >
      {day.charAt(0)}
    </span>
  ));
};

export const handleDaysForDetail = (content) => {
  const daysOfWeek = ["Lu", "Ma", "Mi", "Ju", "Vi", "Sá", "Do"];
  const activeColor = "orange";
  const inactiveColor = "gray";

  const isDaySelected = (day) => {
    const selectedDays = content.days;
    return (
      selectedDays &&
      (selectedDays.includes("Todos los días") ||
        selectedDays.some((selectedDay) =>
          selectedDay.includes(daysOfWeek[day])
        ))
    );
  };

  return daysOfWeek.map((day, index) => (
    <span
      key={day}
      style={{
        color: isDaySelected(index) ? activeColor : inactiveColor,
        marginRight: "4px",
      }}
    >
      {day}
    </span>
  ));
};
