import { makeStyles } from "@material-ui/core";

const useStylesNav = makeStyles((theme) => ({
    appBar: {
        backgroundColor:  "#fff",
        zIndex:900,
    },
    title: {
        width: "100%",
        color: "#FB7E08",
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "19px",
        letterSpacing: "0px",
        textAlign: "center",
    },
    toolbar:{

    },
    extraButtonContainer:{
        position:"absolute",
        right:0
    },
    iconBack: {
        position: "absolute",
    }
}));

export default useStylesNav