import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { handleDays } from "../../utils/AttentionDaysHandler";
import Tooltip from "@mui/material/Tooltip";
import { handleEmptyNoticeHours } from "../../utils/NoticeHoursHandler";
import useStyles from "./ProductStyles";
import ReviewStarsInProduct from "../reviews/reviewStars/reviewStarsInProduct";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Product = ({ content, enabled = true }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isNavigating, setIsNavigating] = useState(false);
  const handleEmptyZone = () => {
    if (content.user.zone) return `📍 ${content.user.zone}`;
    return;
  };

  const navigateProductDetailsPage = async () => {
    if (!isNavigating && enabled) {
      const id = content.id;
      setIsNavigating(true);

      history.push(`/product/${content.name}/${id}`);

      await delay(500);
      setIsNavigating(false);
    }
  };

  const handleBigNameStyle = () => {
    if (content.name.length > 32) {
      return { height: "58%" };
    }
    return { height: "75%" };
  };
  
  return (
    <div
      className={classes.productCardContainer}
      onClick={navigateProductDetailsPage}
    >
      <div className={classes.imageContainer}>
        <img src={content.imageUrl} alt="" className={classes.productImage} />
      </div>
      <div className={classes.infoContainer}>

        <p className={classes.productName}>{content.name}</p>

        <p className={classes.contactName}>{content.user.contactName}</p>

        {content?.reviews && <ReviewStarsInProduct
        
            value={content?.rating || 0}
            reviewsAverage={content?.rating || 0}
            reviewsTotal={content?.reviews?.length}
            />
            }

        <div
          className={classes.extraInfoContainer}
          //style={handleBigNameStyle()}
          style={content?.reviews ? { height: "50%" } : handleBigNameStyle()}
        >
          <div className={classes.extraInfo}>
            <div className={classes.row}>
              <p className={classes.hours}>
                {"⏳" + handleEmptyNoticeHours(content)}
              </p>
              <span className={classes.orangeDot}>·</span>
              <span className={classes.days}>
                {/*   📅 {handleEmptyDays(content)}*/}
                📅 {handleDays(content)}
              </span>
            </div>

            <p className={classes.zone}>{handleEmptyZone()}</p>
          </div>
          {/* Conditionally render the price or livehelp icon */}
          {content.price == 0 ? (
            <Tooltip title="Consultar precio">
              <div className={classes.priceContainer}>
                <span className={classes.price}>
                  <span className={classes.priceLabel}>Bs. </span>
                  {"?"}
                </span>
              </div>
            </Tooltip>
          ) : (
            <div className={classes.priceContainer}>
              <span className={classes.price}>
                <span className={classes.priceLabel}>Bs. </span>
                {content.price}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
