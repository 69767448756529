import React from "react";
import ProductForm from "../../components/product/productform/ProductForm";

const NewProduct = () => {
  return (
    <>
      {/* <NavBack title="NUEVA PUBLICACION"/> */}
      <ProductForm />
    </>
  );
};

export default NewProduct;
