import React from "react"
import useStyles from "./CancelNewProductOverlayStyles"
import { ReactComponent as WarningSVG } from "../../../Images/Icons/warningIcon.svg";
import ClearIcon from "@material-ui/icons/Clear";

const CancelNewProductOverlay=({backButtonFunction,cancelButtonFunction})=>{
  const classes = useStyles();
    return(
        <React.Fragment>
            <div className={classes.WarningContainer}>
                    <ClearIcon 
                style={{color: '#FB7E08'}} 
                className={classes.closeButton}
                onClick={backButtonFunction}
                />
                <WarningSVG className={classes.warningIcon}></WarningSVG>
                <h1 className={classes.title}>IMPORTANTE</h1>
                <p className={classes.warningText}>Estas seguro de Cancelar tu nueva publicacion?</p>
                <div className={classes.buttonContainer}>
                    <button className={classes.backButton} onClick={backButtonFunction}>Volver</button>
                    <button className={classes.cancelButton} onClick={cancelButtonFunction}>Si, Cancelar</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CancelNewProductOverlay