import React, { useState } from "react";
import SelectButton from "../buttons/SelectButton";
import ModalForDeliveryType from "./ModalForDeliveryType";
import useStyles from "./SelectDeliveryTypeStyles";

const SelectDeliveryType = ({ value, onChange, askToShowModal, touched }) => {
  const classes = useStyles();
  const ModalID = "SelectDeliveryType";
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState(value);

  const handleModal = () => {
    if (askToShowModal(ModalID) === false) return;
    setShowModal(!showModal);
  };

  const handleDeliveryTypeChange = async (type) => {
    setSelectedType(type);
    await onChange(type);
    touched();
  };

  return (
    <React.Fragment>
      <div className={classes.DeliveryOptionsContainer}>
        <SelectButton
          placeholder={"Delivery"} // Set the placeholder to "Delivery" if no value is selected
          showModal={handleModal}
          value={selectedType}
        />

        <ModalForDeliveryType
          handleModal={handleModal}
          show={showModal}
          onChange={handleDeliveryTypeChange}
          value={selectedType}
        />
      </div>
    </React.Fragment>
  );
};

export default SelectDeliveryType;
