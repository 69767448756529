import React, { useEffect, useState } from "react";
import ModalForCategories from "./ModalForCategories";
import SelectButton from "../buttons/SelectButton";

const SelectCategories = ({ changeCategories, value, askToShowModal }) => {
  const ModalID = "SelectCategories";
  const [showModal, setShowModal] = useState(false);
  const [selectedValuesTXT, setSelectedValuesTXT] = useState("Categoria");
  const selectedCategories = [...value];

  useEffect(() => {
    if (showModal) return;
    let txtValue;
    if (selectedCategories.length > 0) {
      txtValue = selectedCategories.slice(0, 3).join(", ");
    }
    if (selectedCategories.length > 3) {
      txtValue += "...";
    }
    setSelectedValuesTXT(txtValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleCategoriesUpdate = (categorie, selected) => {
    selected
      ? selectedCategories.push(categorie)
      : selectedCategories.splice(selectedCategories.indexOf(categorie), 1);
  };

  const handleModal = (save) => {
    if (askToShowModal(ModalID) == false) return;
    setShowModal(!showModal);
    if (!showModal === false && save === true) {
      changeCategories(selectedCategories);
    }
  };

  return (
    <div>
      <SelectButton
        placeholder={"Categoria"}
        showModal={handleModal}
        value={selectedValuesTXT}
      />
      {showModal && (
        <ModalForCategories
          handleModal={handleModal}
          show={showModal}
          updateCategory={handleCategoriesUpdate}
          selectedCategories={value}
        />
      )}
    </div>
  );
};

export default SelectCategories;
