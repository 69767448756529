import { useSelector } from "react-redux";
import { getProductDataByIds } from "../dataBaseAdapter";
import { getCategoryGuides } from "../dataBaseAdapter";
import { filterHandler } from "../components/search/filterMenu";

const filterDataByIDs = (dataList, idsList) => {
  return dataList.filter((item) => idsList.includes(item.id));
};
export const useFetchProducts = () => {
  //guides
  const guide = useSelector((state) => state.guides.guides);
  const categoryGuides = useSelector((state) => state.guides.categoryGuides);

  const searchedParam = useSelector((state) => state.searchs.searchParam);

  //category var
  const lastCategorySelected = useSelector(
    (state) => state.category.oldCategory
  );
  const newCategorySelected = useSelector((state) => state.category.enabled);

  //extra filtersVar
  const extraFilters = useSelector((state) => state.filter.extraFilters);

  //indexes
  const lastProductInFilter = useSelector((state) => state.filter.index);
  const lastProductInCategory = useSelector((state) => state.category.index);
  const lastProductInSearch = useSelector((state) => state.searchs.index);
  const lastVisibleFromStore = useSelector((state) => state.products.index);

  // eslint-disable-next-line no-unused-vars
  const filterEnabled = useSelector((state) => state.filter.enabled);

  const fetchApropiateProducts =
    (fetchStore = false, useIndex = false) =>
    async (dispatch) => {
      let productsGuideRef = guide;
      let numberOfFilters = 0;
      let typeOfFetch;
      let index = 0;
      let productsList = [];
      let enabledParam = false;


      if (!productsGuideRef || Object.keys(productsGuideRef).length == 0)
        return;

      dispatch({
        type: "fetcher/pending",
      });
      if (lastCategorySelected || newCategorySelected) {

        enabledParam = newCategorySelected
          ? newCategorySelected
          : lastCategorySelected;
        typeOfFetch = "category";
        index = useIndex ? lastProductInCategory : 0;

        let categoryRef;
        if (!categoryGuides?.categoryGuideName) {
          let categoryGuide = await getCategoryGuides(enabledParam);
          dispatch({
            type: "guides/addCategorieGuide",
            payload: categoryGuide,
            category: enabledParam,
          });
          categoryRef = categoryGuide["products"];
        } else {
          categoryRef = categoryGuides.categoryGuideName["products"];
        }

        productsList =
          productsList.length > 0
            ? filterDataByIDs(productsList, categoryRef)
            : filterDataByIDs(productsGuideRef, categoryRef);

        numberOfFilters += 1;
      }
      if (searchedParam) {
        typeOfFetch = "searchs";
        enabledParam = searchedParam;
        index = useIndex ? lastProductInSearch : 0;
        const searchTermLower = searchedParam.toLowerCase().trim();

        productsList =
          productsList.length > 0
            ? productsList.filter((product) => {
                return product.name.toLowerCase().includes(searchTermLower);
              })
            : productsGuideRef.filter((product) => {
                return product.name.toLowerCase().includes(searchTermLower);
              });

        numberOfFilters += 1;
      }

      if (Object.keys(extraFilters).length > 0) {
        productsList =
          productsList.length > 0
            ? filterHandler(extraFilters, productsList)
            : filterHandler(extraFilters, productsGuideRef);
      }
      if (fetchStore) {
        index = useIndex ? lastVisibleFromStore : 0;
        typeOfFetch = "products";
        productsList = productsGuideRef;
      }

      if (numberOfFilters > 1 || Object.keys(extraFilters).length > 0) {
        typeOfFetch = "filter";
        enabledParam = true;
        if (useIndex) index = lastProductInFilter;
      } else {
        dispatch({ type: "filter/disabled" });
      }

      if (productsList.length > 20)
        productsList = productsList.slice(index, index + 20);
      else productsList = productsList.slice(index, productsList.length);

      let keysToRetrieve = productsList.map((product) => product.id);

      try {
        const newProducts = await getProductDataByIds(keysToRetrieve);
        if (newProducts.length === 0 && useIndex) {
          console.log("NO HAY MAS PRODUCTOS");
          dispatch({
            type: typeOfFetch + "/allfetched",
          });
        } else if (!useIndex) {
          dispatch({
            type: typeOfFetch + "/switchedFullFilled",
            payload: newProducts,
            index: newProducts.length,
            enabled: enabledParam,
          });
        } else {
          dispatch({
            type: typeOfFetch + "/fulfilled",
            payload: newProducts,
            index: newProducts.length,
            enabled: enabledParam,
          });
        }
        if (productsList.length == 0 && searchedParam) {
          dispatch({ type: "searchs/noProductsFound" });
        }
        dispatch({
          type: "fetcher/succeded",
        });
      } catch (error) {
        dispatch({ type: "guides/error", error: error });
      }
    };

  return {
    fetchApropiateProducts,
  };
};
