import { alpha, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    menu: {
      marginTop: 0,
      marginLeft:0,
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputField: {
      width: '100%',
    },
    inputInput: {
      color: 'inherit',
      //padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: '48px',
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menuIcon: {
      color: theme.palette.secondary.dark,
      height: '24px',
      width: '24px',
    },
    logoHeader: {
      marginTop: '2px',
      height: '28px',
      width: '146.3px',
    },
    link: {
      color: '#fff',
      backgroundColor: '#ffffff', 
    },
    button: {
      maxHeight: '40px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    navBar: {
      backgroundColor: '#fff',
    },
    profile: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    headerMobile: {
      display: 'flex',
      alignItems: 'center',
    },

    //////////////////////////////////mover esta parte despues

    buttonMenu: {
      backgroundcolor: '#FB7E08'
    },

  }));

  export default useStyles