import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        padding: "15px 16px",
        borderRadius: "8px",
        background: "white",
        outline: "none",
        border: "none",
        boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
        marginBlockStart: "10px",
        display: 'flex',
        justifyContent: 'space-between'
    },
    placeholder: {
        color: '#ABABAB',
        fontSize: 14,
    },
    value:{
        color:"#253376",
        fontSize: 14,
    }
}))

export default useStyles;