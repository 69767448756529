import * as Yup from 'yup';

const profileSchema = Yup.object().shape({
  contactName: Yup.string().required('Debes agregar un nombre para tu negocio'),
  name:Yup.string().required("Agrega tu nombre de vendedor"),
  contactNumber: Yup.string()
    .required('Debes agregar un numero de contacto')
    .matches(/^[67]/, 'El numero deberia empezar con 6 o 7')
    .min(8,"El numero deberia ser de 8 digitos")
    .max(8, 'El numero maximo de numeros es 8'),
  zone: Yup.string().required('Debes seleccionar una zona'),
});

export default profileSchema;