import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  daysContainer: {
    marginBottom: "3rem", // Add margin at the bottom of the days container
  },
  modalContainer: {
    backgroundColor: "white",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 800,
    position: "fixed",
    maxHeight: "80%",
    overflowY: "auto",
    paddingBottom: "1.5rem",
  },
  modalContainerHidden: {
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    left: 0,
    width: "100%",
    height: "100%",
    display: "none",
  },
  closeButton: {
    width: 24,
    position: "absolute",
    right: 12,
    top: 12,
  },
  title: {
    color: "#253376",
    fontFamily: "Nunito Sans",
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: 800,
    textAlign: "center",
    paddingTop: 24,
    marginBottom: 40,
  },
  submitSection: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    marginTop: "2rem", // Add margin at the top of the submit section
  },

  btnSubmit: {
    width: "95%",
    height: "48px", // Increase the height of the button
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "center",
    borderRadius: "8px",
    border: "1px solid #FB7E08",
    color: "#FB7E08",
    backgroundColor: "transparent",
    textTransform: "none",
  },

  // Media query for small screens
  "@media (max-height: 640px)": {
    submitSection: {
      marginTop: "1rem", // Decrease the margin at the top of the submit section for smaller screens
    },
    daysContainer: {
      marginBottom: "0.5rem", // Add margin at the bottom of the days container
    },
  },
}));

export default useStyles;
