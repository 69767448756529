import React, { useState, useEffect } from "react";
import NewProductForm from "../../components/product/NewProductForm";
import ProductList from "../../components/product/ProductList";
import ReactGA from "react-ga4";
import Footer from "../../components/footer/Footer";
import useStyles from "./HomeStyles";
import Navbar from "../../components/navbar/Navbar";
import Typography from "@material-ui/core/Typography";
import Search from "../../components/search/Search";
import SorryText from "../../components/search/sorryText";
import DetailProduct from "../../views/productdetails/DetailProduct";
import CarouselCategories from "../../components/carousel/CarouselCategories";
import { Route } from "react-router-dom";
import CarouselHighlights from "../../components/carousel/highlightedProducts/CarouselHighlights";

import RecentProductsCarousel from "../../components/carousel/RecentProductsCarousel";
import { FilterMenu } from "../../components/search/filterMenu";

import { useGuides } from "../../hooks/useGuides";
import { useDispatch } from "react-redux";
//import { updateCurrentProductsToAddThemToGuide } from "../../dataBaseAdapter";
ReactGA.initialize("G-4SC0HNFPX9");

const Home = () => {
  const classes = useStyles();
  const { getProductsGuide } = useGuides();
  const dispatch = useDispatch();

  // Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    dispatch(getProductsGuide());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDBModification = () => {
    //updateCurrentProductsToAddThemToGuide()
  };

  const [searchValue, setSearchValue] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  return (
    <div className={classes.home}>
      <Navbar onClickHome={() => setSearchValue(null)} />
      <div className={classes.containerGreeting}>
        <Typography className={classes.greeting}>
          Qué estas
          <br /> buscando case?
        </Typography>
        <Typography className={classes.city}>📍 Cochabamba</Typography>
      </div>
      <Search
        searchValue={searchValue}
        handleSearchInput={(value) => setSearchValue(value)}
        FilterButton={
          <button
            className={classes.filterButton}
            type="button"
            onClick={() => {
              setShowFilters(true);
            }}
          >
            <img src={require("../../Icons/filterIcon.svg").default} alt="" />
          </button>
        }
      />
      <button onClick={handleDBModification} style={{ display: "none" }}>
        MODIFICAR BASE DE DATOS A NUEVO FORMATO
      </button>
      <FilterMenu
        show={showFilters}
        close={() => {
          setShowFilters(false);
        }}
      />
      <SorryText />
      <CarouselCategories />
      <CarouselHighlights />

      <RecentProductsCarousel />
      <ProductList searchInput={searchValue} />
      <NewProductForm />
      <Footer />
      <Route exact path="/product/:product/:id" component={DetailProduct} />
    </div>
  );
};

export default Home;
