import React, { useState, useEffect } from "react";
import SelectButton from "../buttons/SelectButton";
import ModalForDays from "./ModalForDays";
import useStyles from "./SelectDaysStyles";
const SelectDays = ({ value, onChange, askToShowModal, touched }) => {
  const ModalID = "SelectDays";
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [selectedValuesTXT, setSelectedValuesTXT] = useState("");
  const [save, setSave] = useState(false);

  const handleModal = () => {
    if (askToShowModal(ModalID) === false) return;
    setShowModal(!showModal);
  };

  const handleDaysChange = async (selectedOptions) => {
    if (save) {
      await onChange(selectedOptions);
      touched();
    }
  };

  useEffect(() => {
    let txtValue = "";
    if (value.length > 0) {
      txtValue = value.join(", ");
    }
    // let txtValue = value.length > 0 ? value.join(", ") : "Días de atención";
    setSelectedValuesTXT(txtValue);
  }, [value]);

  return (
    <div>
      <SelectButton
        className={classes.input}
        placeholder="Días de atención" // Set the placeholder to "Delivery" if no value is selected
        showModal={handleModal}
        value={selectedValuesTXT}
      />

      <ModalForDays
        handleModal={handleModal}
        show={showModal}
        onChange={handleDaysChange}
        value={value}
        setSave={setSave}
      />
    </div>
  );
};

export default SelectDays;
