import { Button } from "@material-ui/core";
import React from "react";
import useStyles from "./ProductPreviewStyles";
import Dialog from "@material-ui/core/Dialog";
import NavbackPreview from "../navbar/NavbackPreview";
import Tooltip from "@mui/material/Tooltip";
import { handleDaysForDetail } from "../../utils/AttentionDaysHandler";
import { handleEmptyNoticeHoursPreview } from "../../utils/NoticeHoursHandler";
import { handleEmptyAtentionTimes } from "../../utils/AtentionTimesHandler";

const ProductPreview = ({
  userInfo,
  data,
  handleClose,
  handleSubmit,
  initialImageUrl = "",
}) => {
  const classes = useStyles();

  const handleFormSubmit = () => {
    handleSubmit(data);
    handleClose(); // Call the handleSubmit function passed as a prop with the form data
  };

  const handleBack = () => {
    handleClose(); // Call the handleClose function provided as a prop to close the dialog
  };
  return (
    <Dialog fullScreen open>
      <div className={classes.sectionDesktop}>
        <NavbackPreview title="VISTA PREVIA" handleClick={handleBack} />
        <div className={classes.header}>
          <img
            className={classes.img}
            alt="could not load"
            src={
              data.imageFile
                ? URL.createObjectURL(data.imageFile)
                : initialImageUrl
            }
          />
        </div>
        <div className={classes.infoProduct}>
          <div className={classes.nameProduct}>
            <div className={classes.firstName}>{data.productName}</div>
            <div className={classes.secondName}>
              {userInfo?.contactName}{" "}
              <span className={classes.businessProfile}>Ver Negocio</span>
            </div>
          </div>
          { data.productPrice == 0 ? (
                <Tooltip title="Consultar precio">
                  <div className={classes.price}>
                  <span className={classes.currency}>Bs.</span>
                  <span className={classes.money}>{"?"}</span>
                  </div>
                </Tooltip>
              ) : (
                <div className={classes.price}>
                <span className={classes.currency}>Bs.</span>
                <span className={classes.money}>{data.productPrice}</span>
                </div>
              )}
        </div>

        <div className={classes.productInfoContainer}>
          <div
            className={classes.zone}
            style={{ "border-bottom": "1px solid rgba(171, 171, 171, 0.5)" }}
          >
            <p className={classes.label}>🗓 Atención</p>
            <p className={classes.infoContent}> {handleDaysForDetail(data)}</p>
          </div>

          <div
            className={classes.otherInfo}
            style={{ "border-right": "1px solid rgba(171, 171, 171, 0.5)" }}
          >
            <p className={classes.label}>📍 Ubicación</p>
            <p className={classes.infoContent}>{userInfo?.zone}</p>
          </div>
          <div className={classes.otherInfo}>
            <p className={classes.label}>⏰ Horarios</p>
            <p className={classes.infoContent}>
              {handleEmptyAtentionTimes(data)}
            </p>
          </div>
          <div
            className={classes.otherInfo}
            style={{ "border-right": "1px solid rgba(171, 171, 171, 0.5)" }}
          >
            <p className={classes.label}>⏳ Reservar</p>
            <p className={classes.infoContent}>
              {handleEmptyNoticeHoursPreview(data)}
            </p>
          </div>
          <div className={classes.otherInfo}>
            <p className={classes.label}>🛵 Delivery</p>
            <p className={classes.infoContent}>{data.DeliveryType}</p>
          </div>
        </div>
        <div className={classes.tituloDescripcion}>
          <div className={classes.labelDescripcion}>Descripción</div>
          <span className={classes.descripcion}>{data.productDescription}</span>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            className={classes.btnEdit}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Editar
          </Button>

          <Button
            className={classes.buttonSubmit}
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
          >
            Publicar Producto
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ProductPreview;
