import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "./ProductsTableStyles";
import EditProductForm from "../product/editProductForm/EditProductForm";
import Tooltip from "@mui/material/Tooltip";
import edit from "../../Images/Icons/edit.svg";
import { getCurrentUID, getProductsSubscription } from "../../dataBaseAdapter";

import { useHistory } from "react-router-dom";

const ProductsTable = () => {
  const classes = useStyles();
  const history = useHistory();
  const [productsList, setProductsList] = useState([]);
  const [userUID, setUserUID] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [selProduct, setselProduct] = useState();
  const [editForm, setEditForm] = useState(false);

  const handleEditProductForm = (product) => {
    history.push({ pathname: `/editProduct`, state: product });

    // setselProduct(product);
    //setEditForm(true);
  };

  useEffect(() => {
    const getUID = async () => {
      try {
        const uid = getCurrentUID();
        if (uid) {
          setUserUID(uid);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUID();
  }, []);

  useEffect(() => {
    if (userUID == "") return;
    const handleSnapshot = (snapshot) => {
      setProductsList(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    };

    const unsubscribe = getProductsSubscription(
      { userUID: userUID },
      handleSnapshot
    );

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [userUID]);

  return (
    <React.Fragment>
      <section className={classes.myProductsContainer}>
        <div className={classes.titleContainer}>
          <h2 className={classes.tableTitle}>Mis Productos</h2>
        </div>
        <div className={classes.productsList}>
          {productsList.map((product) => (
            <div
              className={classes.productBox}
              key={product.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <img
                src={product.imageUrl}
                alt="Imagen del producto"
                className={classes.image}
              />
              <div className={classes.itemInfo}>
                <span className={classes.productName}>{product.name}</span>
                <div className={classes.productBottomInfo}>
                  <Button
                    align="left"
                    className={classes.editButton}
                    onClick={() => handleEditProductForm(product)}
                  >
                    <img
                      src={edit}
                      alt="edit icon"
                      className={classes.editIconSVG}
                    />
                    Editar Producto
                  </Button>
                  {product.price == 0 ? (
                    <Tooltip title="Consultar precio">
                      <div className={classes.priceContainer}>
                        <span className={classes.bsText}>Bs.</span>
                        <span className={classes.price}>{"?"}</span>
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={classes.priceContainer}>
                      <span className={classes.bsText}>Bs.</span>
                      <span className={classes.price}>{product.price}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {editForm && (
        <EditProductForm
          product={selProduct}
          handleClose={() => {
            setEditForm(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ProductsTable;
