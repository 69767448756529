import { useFormik } from "formik";
import { createUserWithMailAndPassword,createUserDoc,getCurrentUID } from "../dataBaseAdapter";
const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

const isValidPhoneNumber = (contactNumber) => {
    const contactNumberRegex = /^\d+$/;
    return contactNumberRegex.test(contactNumber);
  };


export const useSignUp = ( history ) => {
    const handleSuccessSignUp=()=>{
        let values=formik.values
        let currentUID=getCurrentUID()
        createUserDoc(currentUID,{
            email: values.email,
            contactName: values.contactName,
            contactNumber: values.contactNumber,
            zone: values.zone,
            uid: currentUID
        })
        history.push('/')
    }
    const handleErrorSignUp=(error)=>{
        console.log(error)
        if (error.code === 'auth/email-already-in-use') {
            formik.setFieldError('email', 'El correo ya esta en uso')
        }
    }
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
            contactName: '',
            contactNumber: '',
            zone: '',

        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: values => {
            const errors = {};
            if (!values.email) {
                errors.email = 'Requerido';
            }
            if (!isValidEmail(values.email)) {
                errors.email = 'Ingresa un correo electrónico válido';
            }
            if (!values.password) {
                errors.password = 'Requerido';
            }
            if (!values.confirmPassword) {
                errors.confirmPassword = 'Requerido';
            }
            if (!values.contactName) {
                errors.contactName = 'Requerido';
            }
            if (values.contactName.length > 40) {
                errors.contactName = 'Elija un nombre de usuario mas corto';
            }
            if (!values.contactNumber) {
                errors.contactNumber = 'Requerido';
            }
            if (values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Las contraseñas no coinciden';
            }
            if (values.contactNumber.length !== 8) {
                errors.contactNumber = 'El numero debe tener 8 digitos';
            }
            if (!values.zone) {
                errors.zone = 'Requerido';
            }
            if (values.password.length < 6) {
                errors.password = 'La contraseña debe tener 6 digitos minimo';
            }
            if (!isValidPhoneNumber(values.contactNumber)) {
                errors.contactNumber = 'Ingresa un número de teléfono válido';
              }
            return errors;
        },
        onSubmit: values => {
            createUserWithMailAndPassword(values.email,values.password,handleSuccessSignUp,handleErrorSignUp)
        },
    });

    return {
        formik
    }

}