import React, { useState} from "react";
import { Button, Divider } from "@material-ui/core";

import useStyles from "./MenuSellerDesktopStyles";
import logoHeader from "../../../Icons/menu/white-LogoV-white@2x 1.svg";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import iconFacebook from "../../../Icons/menu/facebook.svg";
import iconHelp from "../../../Icons/menu/help-circle.svg";
import iconHome from "../../../Icons/menu/home.svg";
import iconProducts from "../../../Icons/menu/categories.svg";
import iconLogout from "../../../Icons/menu/log-out.svg";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { signOut } from "../../../dataBaseAdapter";

const MenuSeller = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    history.push("/");
  };

  const handleLogin = () => {
    history.push("/login");
  };

  const handleNewProduct = () => {
    history.push("/newproduct");
  };

  const handleFAQ = () => {
    //history.push('/faq');
    console.log("to FAQ");
  };

  const handleProfile = () => {
    history.push("/profile");
  };

  const handleLogout = async () => {
    signOut();
    history.push("/");
    dispatch({ type: "user/unset"})
    // window.location.reload();
  };

  const navigateToProducts = () => {
    history.push("/profile");
    setTimeout(() => {
      const contactSection = document.getElementById("products");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  };
  return (
    <div className={classes.userMenuContainer}>
        <div className={classes.menuItem} onClick={handleFAQ}>
        <img src={iconHelp} alt="Plus Icon" className={classes.icon} />
        Preguntas Frecuentes
        </div>
        <div className={classes.menuItem} onClick={navigateToProducts}>
        <img src={iconProducts} alt="User Icon" className={classes.icon} />
        Mi Perfil
        </div>
        <div className={classes.menuItem} onClick={handleLogout}>
        <img src={iconLogout} alt="Exit Icon" className={classes.icon} />
        Cerrar Sesión
        </div>
        <Button
        variant="contained"
        color="primary"
        className={classes.buttonMenu}
        onClick={handleNewProduct}
      >
        Publicar nuevos productos!
      </Button>
    </div>
  );
};

export default MenuSeller;
