import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./CategoryStyles";
const Category = ({ category }) => {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);

  const isCategorySelected = useSelector((state) => state.category.enabled);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isCategorySelected !== category[0] && clicked) {
      setClicked(false);
    }
    if (!isCategorySelected && category[0] === "Todos los Productos") {
      setClicked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCategorySelected]);

  useEffect(() => {
    if (!isCategorySelected && category[0] === "Todos los Productos") {
      setClicked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectCategory = async (e) => {
    setClicked(true);

    if (isCategorySelected === category[0]) {
      setClicked(false);
      dispatch({
        type: "category/disabled",
        enabled: false,
      });
      dispatch({
        type: "fetcher/shouldFetch",
      });
      return;
    }
    if (category[0] === "Todos los Productos") {
      dispatch({
        type: "category/disabled",
        enabled: false,
      });
      dispatch({
        type: "fetcher/shouldFetch",
      });
    } else {
      dispatch({
        type: "category/newCategory",
        newCategory: category[0],
      });
      dispatch({
        type: "fetcher/shouldFetch",
      });
    }
  };

  return (
    <div
      className={clicked ? classes.categorySelected : classes.category}
      onClick={(e) => handleSelectCategory(e)}
    >
      <div>
        <img className={classes.img} src={category[1]} alt="" />
      </div>
      <div className={classes.text}>{category[0]}</div>
    </div>
  );
};

export default Category;
