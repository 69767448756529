import React from "react";

export const Input = ({
  type = "text",
  placeholder,
  classTypes,
  name,
  value = "",
  onChange,
  onKeyDown,
  onBlur
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      className={classTypes}
      name={name}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
};
