import React, {  useEffect, useState } from "react";
import useStyles from "./ReviewCardStyles";
import { Avatar } from "@material-ui/core";
import ReviewStarsInProduct from "../reviewStars/reviewStarsInProduct";
import trashCan from "../../../Icons/trashCan.svg";
import Overlay from "../../overlay/Overlay";
import DeleteReviewOverlay from "../deleteReviewOverlay/deleteReviewOverlay";
import {  useSelector } from "react-redux";

const ReviewCard = ({ review, deleteReview,reviewerName="UndefinedName" }) => {
  const classes = useStyles();
  const [userData, setUserData] = useState();
  const [showOverlay, setShowOverlay] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const loggedUser = useSelector((state) => state.user.user);

  useEffect(() => {
    if (loggedUser) setUserData(loggedUser);
  }, [loggedUser]);
  const handleDateNumber = (month) => {
    let monthsOfYear = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    return monthsOfYear[month-1];
  };
  const handleDeleteReview = async () => {
    setIsDeleting(true);
    await deleteReview(review);
  };
  


  return (
    <React.Fragment>
      <Overlay
        show={showOverlay}
        clickOnOverlayBackground={() => {
          setShowOverlay(false);
        }}
      >
        <DeleteReviewOverlay
          backButtonFunction={() => {
            setShowOverlay(false);
            setIsDeleting(false);
          }}
          confirmDelete={() => {
            handleDeleteReview();
          }}
          isDeleting={isDeleting}
        />
      </Overlay>

      <div className={classes.reviewContainer}>
        {userData && userData.uid == review?.reviewerUID && (
          <div className={classes.trashCanContainer}>
            <img
              src={trashCan}
              alt={`Delete Review`}
              onClick={() => {
                setShowOverlay(true);
              }}
            />
          </div>
        )}
        <div className={classes.reviewerInfo}>
          <Avatar alt="reviewer" sx={{ width: 40, height: 40 }} />
          <span className={classes.reviewerTitle}>{reviewerName}</span>
        </div>
        <div className={classes.ratingContainer}>
          <ReviewStarsInProduct value={review.rating} />
          {review?.date && (
            <span className={classes.dateText}>
              {review.date.day} {handleDateNumber(review.date.month)}{" "}
              {review.date.year}
            </span>
          )}
        </div>
        <div className={classes.reviewTitle}>
          <span className={classes.blackBoldText}>{review.title}</span>
        </div>
        <div className={classes.reviewDetails}>
          <span className={classes.reviewDetailsText}>
            {review.description}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ReviewCard;
