import React, { useState } from 'react'
import useStyles from './CategoryChipStyles';

const CategoryChip = ({category,handleClickOnFather,initialState}) => {
  const [selected, setSelected] = useState(initialState);
  const classes = useStyles();
  const handleClick = () => {
    setSelected(!selected);
    handleClickOnFather(category[0],!selected)
  }

  return (
    <span className={selected ? classes.selectedContainer : classes.container } onClick={() => handleClick()}>
      <img src={category[1]} alt="" height={16} className={classes.icon}/>
      <span>{category[0]}</span>
    </span>
  )
}

export default CategoryChip