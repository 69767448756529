import React from "react";
import ReviewForm from "../../components/reviews/reviewForm/reviewForm";

const Reviews = () => {
  return (
    <React.Fragment>
      <ReviewForm onSubmit={()=>{}}></ReviewForm>
    </React.Fragment>
  );
};

export default Reviews;
