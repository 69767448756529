import React, { useCallback, useState } from "react";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/Lock";
import Typography from "@material-ui/core/Typography";
import useStyles from "./RecoverpswdStyles";

import { sendPaswordResetMail } from "../../dataBaseAdapter";
import MenuUser from "../../components/navbar/MenuUser";
import { Drawer } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import logoHeader from "../../Images/Logo/Logo.svg";

const Recoverpswd = () => {
  const [email, setEmail] = useState("");
  const classes = useStyles();
  const sendRecovery = () => {
    sendPaswordResetMail(email,()=>{alert("Revise su correo");},(error)=>{
      if (error.code.includes("auth/user-not-found"))
          handleError(error);
    })
  };

  const [error, setError] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleError = useCallback(err => {
    if (err.code.includes('auth/user-not-found')) {
      setError('El usuario no existe');
    }
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };



  const menuOverlay = (
  <Drawer
    anchor="left"
    open={isMenuOpen}
    onClose={handleMenuClose}
    elevation={0}
    ModalProps={{
      BackdropProps: {
        style: {
          backgroundColor: "rgba(251, 126, 8, 0.2)",
        },
      },
    }}
    PaperProps={{
      style: {
        borderTopRightRadius: "16px",
        borderBottomRightRadius: "16px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
      },
    }}
  >
    <MenuUser />
  </Drawer>
  );

  return (
    <>
    <div className={classes.headerMobile}>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMenuOpen}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            </div>
            <Link
              to="/"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              className={classes.profile}
            >
              <img
                src={logoHeader}
                alt="DesdeCasa footer"
                className={classes.logoHeader}
              />
            </Link>
          </div>
    {menuOverlay}
    <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12} sm={10} md={6} className={classes.root} >
          <Paper elevation={3} className={classes.paper}>
            <Grid align="center">
              <Avatar className={classes.avatar}>
                {" "}
                <LockIcon />{" "}
              </Avatar>
              <Typography className={classes.typography} variant="h5">Recuperar contraseña</Typography>
            </Grid>
            <div>
              <TextField
                type="email"
                name="txtEmail"
                id="txtEmail"
                onChange={(e) => setEmail(e.target.value)}
                label="Correo electrónico"
                variant="outlined"
                fullWidth
                required
              />
              {
                error &&
                <Typography variant='body2' color='error'>
                  {error}
                </Typography>
              }
              <p>El link de recuperación se enviará a este correo.</p>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendRecovery}
                    disabled={
                      !(
                        email.includes("@") &&
                        email.includes(".com") &&
                        email.length > 3
                      )
                    }
                    fullWidth
                  >
                    ENVIAR
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Recoverpswd;