import { makeStyles } from "@material-ui/core";
const inputTextColor = "#253376";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formContainer: {
    marginTop: "60px",
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "#FFF9F2",
    display: "flex",
    flexDirection: "column",
  },
  businessInfo: {
    display: "flex",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 8,
  },
  title: {
    fontFamily: "Nunito Sans",
    color: "#253376",
    fontWeight: 800,
    fontSize: 16,
    letterSpacing: 1,
    marginLeft: 12,
  },
  input: {
    width: "100%",
    padding: "15px 16px",
    borderRadius: "8px",
    background: "white",
    outline: "none",
    border: "none",
    boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
    marginBlockStart: "10px",
    color: inputTextColor,
    fontSize: "14px",
  },
  inputSmall: {
    width: "60%",
    padding: "15px 16px",
    borderRadius: "8px",
    background: "white",
    outline: "none",
    border: "none",
    boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
    marginBlockStart: "10px",
    color: inputTextColor,
    fontSize: "14px",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
  },
  inputSmallHidden: {
    width: "90%",
    padding: "15px 7px",
    borderRadius: "8px",
    background: "transparent",
    outline: "none",
    border: "none",
    color: inputTextColor,
    fontSize: "14px",
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
  },
  priceContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    height: "46px",
    width: "100%",
    padding: "15px 16px",
    borderRadius: "8px",
    background: "white",
    outline: "none",
    border: "none",
    boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
    marginBlockStart: "10px",
    color: inputTextColor,
  },
  text: {
    color: "#ABABAB",
    fontSize: 14,
    marginLeft: 12,
  },
  staticCurrency: {
    fontSize: "14",
    fontWeight: "400",
    color: inputTextColor,
    fontFamily: "Open Sans",
  },
  textArea: {
    fontFamily: "Open Sans",
    width: "100%",
    height: 100,
    padding: "15px 16px",
    borderRadius: "8px",
    background: "white",
    outline: "none",
    border: "none",
    boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
    marginBlockStart: "10px",
    "&::-webkit-input-placeholder": { color: "#ABABAB", fontSize: "14px" },
    color: inputTextColor,
    fontSize: "14px",
  },
  error: {
    fontSize: "13px",
    color: "red",
    fontFamily: "Open Sans",
    textAlign: "justify",
    width: "80%",
  },

  inputsContainer: {
    marginBottom: "82px",
    display: "flex",
    flexDirection: "column",
    //overflowY: "auto",
  },

  buttonContainer: {
    width: "100vw",
    padding: "12px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
    flexDirection: "row",
    justifyContent: "space-between",

    display: "flex",
    zIndex: 100,
    position: "fixed",
    bottom: 0,
    left: 0,

    backgroundColor: "white",
  },

  btnPreview: {
    width: "47%",
    height: "48px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "center",
    borderRadius: "8px",
    border: "1px solid #FB7E08",
    color: "#FB7E08",
    backgroundColor: "transparent",
    textTransform: "none",

    fontStyle: "normal",
    paddingBlock: "12px",
  },

  buttonSubmit: {
    width: "47%",
    height: "48px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "center",
    borderRadius: "8px",
    color: "white",
    backgroundColor: "#FB7E08",
    textTransform: "none",

    fontStyle: "normal",
    paddingBlock: "12px",
  },
  cancelButton: {
    fontSize: "14px",
    color: "#FB7E08",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  mainForm: {
    display: "flex",
    flexDirection: "column",
  },

  timePickerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  timePickerWrapper: {
    marginTop: "10px",
  },
}));

export default useStyles;
