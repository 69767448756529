import {  Button, Typography } from "@material-ui/core";
import { Grid} from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { Redirect, withRouter } from "react-router";
import { AuthContext } from "../../Auth";
import { Input } from "../../components/inputs/Input";
import { useLogin } from "../../hooks/useLogin";
import logo from "../../Images/Logo/desdecasalogo-white.svg";
import useStyles from "./LoginStyles";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import { createUserDoc, loginWithFacebook } from "../../dataBaseAdapter";
import { ReactComponent as FacebookSVG } from "../../Icons/facebookIcon2.svg";

const Login = ({ history }) => {
  const classes = useStyles();

  const { formik, error } = useLogin(history);

  const { currentUser } = useContext(AuthContext);

  const handleLogoClick = () => {
    history.push("/");
  };

  if (currentUser) {
    return <Redirect to="/" />;
  }
  const handleUserCreation=(user)=>{
    let userToCreate={name:user.displayName,email:user.email,uid:user.uid}
    createUserDoc(user.uid,userToCreate)
  }
  const handleFbLogin=(event)=>{
    event.preventDefault();
    console.log("Facebook Login");
    loginWithFacebook(handleUserCreation,()=>{})
  }
  return (
    <Grid container className={classes.loginBackground}>
      <div className={classes.loginHead}
      onClick={handleLogoClick}>
        <img
          src={logo}
          className={classes.loginHeadImage}
          alt="Logo de desdecasa"
        />
      </div>
      <section className={classes.formSection}>
        <div className={classes.wrapper}>
          <div className={classes.formContainer}>
            <h2 className={classes.title}>Bienvenido de vuelta!</h2>
            <form onSubmit={formik.handleSubmit}>
              {error ? (
                <Typography
                  variant="body2"
                  color="error"
                  className={classes.error}
                >
                  {error}
                </Typography>
              ) : null}
              <Input
                type="email"
                placeholder="Correo Electrónico"
                name="email"
                classTypes={classes.input}
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                />
              {formik.touched.email &&(
                <Typography variant="body2" className={classes.error}>
                  {formik.errors.email}
                </Typography>
              )}
              <Input
                type="password"
                placeholder="Contraseña"
                name="password"
                classTypes={classes.input}
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                />
              {formik.touched.password &&(
                <Typography variant="body2" className={classes.error}>
                  {formik.errors.password}
                </Typography>
              )}
              <div className={classes.fieldRight}>
                <Link
                  to="/recovery"
                  className={`${classes.textOrange} font400`}
                  style={{ textDecoration: "none" }}
                >
                  Olvidé mi contraseña
                </Link>
              </div>
              <div>

              </div>

              <div className={classes.loginButtonsContainer}>
                <Button
                  fullWidth
                  disableElevation
                  variant="contained"
                  target="_blank"
                  className={classes.button}
                  type="submit"
                >
                  Iniciar Sesión
                </Button>

                <Button
                  fullWidth
                  disableElevation
                  variant="contained"
                  onClick={handleFbLogin}
                  className={classes.button}
                  style={{display: 'none'}}
                >
                <FacebookSVG className={classes.facebookLogo}></FacebookSVG>

                  Iniciar con Facebook
                </Button>         
              </div>
              <div className={classes.fieldCenter}>
                <p className={`font400 ${classes.text14}`}>
                  ¿Nuevo aquí?{" "}
                  <Link
                    to="/signup"
                    className={classes.textOrange}
                    style={{ fontWeight: "bold" }}
                  >
                    Regístrate
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </Grid>
  );
};

export default withRouter(Login);
