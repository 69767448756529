import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  radioButton: {
    appearance: "none",
    marginRight: 8,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "2px solid #FB7E08",
    outline: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  radioButtonChecked: {
    appearance: "none",
    marginRight: 8,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "2px solid #FB7E08",
    outline: "none",
    cursor: "pointer",
    backgroundColor: "#FB7E08",
  },
  option: {
    color: "#1A1A1A",
    lineHeight: "27px",
    verticalAlign: "bottom",
  },
  radioOptionContainer: {
    padding: "4px 24px",
  },
  bottomLine: {
    borderBottom: "0.3px solid #828282",
    marginTop: 6,
  },
}));

export default useStyles;
