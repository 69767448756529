import React from "react";
import { Button, Divider } from "@material-ui/core";
import useStyles from "./MenuUserStyles";
import logoHeader from "../../Icons/menu/white-LogoV-white@2x 1.svg";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import iconFacebook from "../../Icons/menu/facebook.svg";
import iconHelp from "../../Icons/menu/help-circle.svg";
import iconUser from "../../Icons/menu/user-check.svg";
import iconPlus from "../../Icons/menu/user-plus.svg";
import { useHistory } from "react-router-dom";

const MenuUser = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleLogoClick = () => {
    history.push("/");
  };

  const handleLogin = () => {
    history.push("/login");
  };

  const handleNewProduct = () => {
    history.push("/newproduct");
  };
  const handleSignUp = () => {
    history.push("/signup");
  };

  const handleFAQ = () => {
    //history.push('/faq');
  };

  return (
    <div className={classes.userMenuContainer}>
      <div className={classes.logoContainer}
      onClick={handleLogoClick}>
        <img
          src={logoHeader}
          alt="DesdeCasa footer"
          className={classes.logoHeader}
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        className={classes.buttonMenu}
        onClick={handleNewProduct}
      >
        Quiero publicar mis productos!
      </Button>

      <div>
        <div className={classes.menuItem} onClick={handleLogin}>
          <img src={iconUser} alt="Help Icon" className={classes.icon} />
          Iniciar Sesión
        </div>
        <div className={classes.menuItem} onClick={handleSignUp}>
          <img src={iconPlus} alt="User Icon" className={classes.icon} />
          Crear cuenta
        </div>
        <div className={classes.menuItem} onClick={handleFAQ}>
          <img src={iconHelp} alt="Plus Icon" className={classes.icon} />
          Preguntas Frecuentes
        </div>
      </div>

      <Divider
        style={{ backgroundColor: "orange", margin: "0 4vw", marginTop: "15%" }}
      />
      <div className={classes.divStyles}>Siguenos en:</div>
      <div className={classes.socialIconsContainer}>
        <img
          src={iconFacebook}
          className={classes.socialIcon}
          alt="facebook"
          onClick={() =>
            window.open(
              "https://www.facebook.com/Desde-Casa-Bolivia-Plataforma-para-comida-casera-103413602166975"
            )
          }
        />
        <InstagramIcon
          className={classes.socialIcon}
          onClick={() => window.open("https://instagram.com/desdecasa.bolivia")}
        />
        <WhatsAppIcon
          className={classes.socialIcon}
          onClick={() => window.open("https://wa.me/59172203591")}
        />
      </div>
      <div className={classes.divStylesBig}>
        Hecho con amor 🧡 | DesdeCasa 2023 v.0.3
      </div>
    </div>
  );
};

export default MenuUser;
