import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "168px",
  },
  ultimo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "450px",
    margin: "15px 0px -15px 0px",
    color: "#737373",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  header: {
    marginTop: "60px",
    width: "100%",
    height: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "200px",
    },
  },
  name: {
    fontWeight: "bold",
    fontSize: "20px",
    margin: "16px 0px 0px 0px",
    width: "450px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "400px",
    },
  },
  priceRedesSociales: {
    display: "flex",
    width: "450px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "16px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "400px",
    },
  },
  iconRedesSociales: {
    display: "flex",
    flexDirection: "row",
  },
  pedirButton: {
    fontSize: "13px",
    background: "#ff5722",
    color: "#ffffff",
    textTransform: "inherit",
    marginTop: "16px",
    width: "150px",
  },
  sectionIcono: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "400px",
    },
  },
  img: {
    margin: "auto",
    width: "100%",
    height: "100%",

    objectFit: "cover",
    backgroundColor: "#e3e3e3",
  },
  ul: {
    display: "flex",
    flexWrap: "wrap",
    width: "450px",
    paddingLeft: "0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  li: {
    listStyle: "none",
    flex: "0 50%",
    margin: "5px 0px 0px 0px",
  },
  tituloDescripcion: {
    padding: "12px 16px",
    width: "calc(100% - 32px)",
  },
  extraInfo: {
    display: "flex",
    alignItems: "center",
    color: "#737373",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    height: "32px",
    marginRight: "4px",
  },
  backgroundImage: {
    backgroundColor: "rgba(62, 34,8, 0.9)",
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1500,
  },
  imgExpanded: {
    position: "fixed",
    top: "100px",
    maxWidth: "100%",
    maxHeight: "85%",
    objectFit: "contain",
    width: "100%",
    marginBottom: "100px",
  },
  closeIconContainer: {
    display: "flex",
    width: "100%",
    height: "36px",
    justifyContent: "end",
    paddingTop: "40px",
    paddingRight: "20px",
  },
  closeIconCircle: {
    backgroundColor: "white",
    width: 36,
    height: 36,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
  },

  infoProduct: {
    display: "flex",
    width: "calc(100% - 32px)",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "28px",
    paddingBottom: "11px",
  },
  firstName: {
    fontFamily: "Nunito Sans",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "33px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "rgba(37, 51, 118, 1)",
  },
  secondName: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#707070",
  },
  price: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    paddingTop: "2px",
    marginLeft: "8px",
  },
  currency: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#253376",
    marginRight: "2px",
  },
  money: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "36px",
    lineHeight: "49px",
    color: "#253376",
  },
  labelDescripcion: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    lineHeight: "22px",
    textTransform: "uppercase",
    color: "#253376",
  },
  descripcion: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontsize: "16px",
    lineHeight: "22px",
    color: "#707070",
  },
  businessProfile: {
    color: "#FB7E08",
    fontWeight: 700,
    fontSize: "12px",
    textDecorationLine: "underline",
  },

  productInfoContainer: {
    fontFamily: "Nunito Sans",
    backgroundColor: "#FEE5CE",
    width: "90%",
    borderRadius: 8,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  zone: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    padding: "12px 0px 8px 0px",
    textAlign: "center",
    "& > p": {
      margin: 0,
    },
  },
  otherInfo: {
    padding: "10px 30px",
    "& > p": {
      margin: 0,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 300,
  },
  infoContent: {
    fontSize: 14,
    fontWeight: 400,
    color: "#253376",
  },

  reviewsContainer: {
    paddingTop: "16px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  ratingStars: {},

  title: {
    fontFamily: "Nunito Sans",
    color: "#253376",
    fontWeight: 800,
    fontSize: 16,
    letterSpacing: 1,
    marginLeft: 12,
  },
  orangeLine: {
    width: "90%",
    borderBottom: `1px solid ${"#FB7E08"}`,
    marginBottom: "16px",
    marginTop: "16px",
  },
  buttonContainer: {
    width: "85%",
    display: "flex",
    justifyContent: "flex-end",
  },

  addReviewButton: {
    color: "#FB7E08",
    border: "1.76px solid #FB7E08",
    width: "100%",
    backgroundColor: "transparent",
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "14px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textTransform: "none", // Allow lowercase letters
  },
  arrow: {
    position: "absolute",
    right: "10px",
  },

  totalReviews: {
    fontFamily: "Nunito Sans",
    color: "#253376",
    fontWeight: 300,
    fontSize: "15px",
  },
}));

export default useStyles;
