import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
   searchInput: {
      margin: "12px",
      padding: "12px",
      border: "0px",
      borderRadius: "50px",
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "19px",
      letterSpacing: "0em",
      textAlign: "left",
      height: "40px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
   },
   inputField: {
      width: "100%",
   },
   iconSearch: {
      color: "#FB7E08",
   },
   containerSearch: {
      position: "sticky",
      top: "56px",
      zIndex: "1300",
      backgroundColor: "#FFF9F2"
   },
   lineStyles : {
      flexGrow: 1,
      width: '1px',
      background: '#ABABAB',
      margin: '0 12px',
      height: '23.5px',
    }
}));

export default useStyles;
