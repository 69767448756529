import { useFormik } from "formik";

import { keywordsArrayCreator } from "../utils/KeyWordsMethods";
import {
  getUser,
  saveImageToDB,
  saveNewProduct,
  updateProduct,
  updateImage,
} from "../dataBaseAdapter";
import { editingSchema } from "../utils/schemas/editingSchema";

import { newProductSchema } from "../utils/schemas/newProductSchema";
import {  useSelector } from "react-redux";

const defaultValues = {
  imageFile: null,
  productName: "",
  productPrice: "",
  productDescription: "",
  Categories: [],
  PriorNoticeTime: { time: "", type: "Horas" },
  days: [],
  openingTime: "",
  closingTime: "",
  DeliveryType: "",
};

const useNewProduct = (
  callbackAfterSubmit,
  customInitialValues = null,
  editingProps = {},
  defaultUrl = ""
) => {
  const loggedUser = useSelector((state) => state.user.user);

  const newProductFormik = useFormik({
    initialValues: customInitialValues
      ? { ...customInitialValues }
      : { ...defaultValues },

    validationSchema: editingProps?.isEditing
      ? editingSchema
      : newProductSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      // console.log(getUser());
      try {
        const user=loggedUser
        const imageID =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);

        const saveProductCallback = async (imageUrl) => {
          let keywordArray = keywordsArrayCreator(values.productName);
          let productToSave = {
            name: values.productName,
            price: values.productPrice,
            description: values.productDescription,
            categories: values.Categories,
            priorNoticeHours: values.PriorNoticeTime,
            days: values.days,
            openingTime: values.openingTime,
            closingTime: values.closingTime,
            delivery: values.DeliveryType,
            imageUrl: imageUrl,
            keywords: keywordArray,
            photoRating: 8,
            user: {
              uid: user.uid,
              email: user.email,
              contactNumber: user.contactNumber,
              contactName: user.contactName,
              zone: user.zone,
            },
          };
          let docID;
          if (editingProps?.isEditing) {
            docID = editingProps.productID;
            await updateProduct(editingProps.productID, productToSave);
          } else {
            // Call the function to save the new product
            docID = await saveNewProduct(productToSave);
          }
          callbackAfterSubmit(productToSave, docID);
        };

        if (editingProps?.isEditing) {
          let newUrl = null;

          if (values.imageFile) {
            newUrl = await updateImage(defaultUrl, values.imageFile);
          }
          saveProductCallback(newUrl ? newUrl : defaultUrl);

          //updateProduct(editingProps.productID, productToSave);
        } else {
          // Call the function to save the new product
          saveImageToDB(imageID, values.imageFile, saveProductCallback);
        }

        // saveImageToDB(imageID, values.imageFile, saveProductCallback);
      } catch (error) {
        setSubmitting(false);

        console.log(error);
      }
    },
  });
  const formikHasDefaultValues = () => {
    const strDefaultValues = JSON.stringify(defaultValues);
    const formikValues = JSON.stringify(newProductFormik.values);
    return strDefaultValues === formikValues;
  };
  return [newProductFormik, formikHasDefaultValues];
};

export default useNewProduct;
