import { db } from "../firebase";

const splitBySpace = (str) => {
  return str.split(" ");
};

const createKeywords = (word) => {
  let arrName = [];
  let curName = "";
  word.split("").forEach((letter) => {
    curName += letter;
    arrName.push(curName.toLocaleLowerCase());
  });
  return arrName;
};

export const keywordsArrayCreator = (fullname) => {
  let keywordsArray = [];
  splitBySpace(fullname).forEach((word) => {
    keywordsArray = keywordsArray.concat(createKeywords(word));
  });
  keywordsArray = keywordsArray.concat(createKeywords(fullname));
  return keywordsArray;
};

//This method is used only to create the arraykeyword once for old products that was in production state
// export const keywordArrayOldProducts = () => {
//     db.collection('products').get().then(snapshot => {
//         snapshot.docs.forEach(doc => {
//             let name = doc.data().name;
//             let keywordArray = keywordsArrayCreator(name);
//             db.collection('products').doc(doc.id).update({
//                 keywords: keywordArray
//             })

//         })
//     })
// }

//This method is used only to generate on all documents a field value that contains a number between 1-10, starting in 8.
//the number 1 means that the image is a Highrated image
export const photoQualityGenerator = () => {
  db.collection("products")
    .get()
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        db.collection("products").doc(doc.id).update({
          photoRating: 8,
        });
      });
    });
};

export const defaultCategoryGenerator = () => {
  db.collection("products")
    .get()
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        db.collection("products")
          .doc(doc.id)
          .get()
          .then((doc) => {
            const pcategory = doc.data().categories;

            pcategory.push("Todos los Productos");

            db.collection("products").doc(doc.id).update({
              categories: pcategory,
            });
          });
      });
    });
};
