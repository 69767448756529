import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
import { fetchProductImages } from "../../../dataBaseAdapter.js";

const CarouselHighlights = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      const productImages = await fetchProductImages();
      setFeaturedProducts(productImages);
    };

    fetchFeaturedProducts();
  }, []);
  //console.log(featuredProducts[0].imageURL);

  const settings = {
    dots: true,
    arrows: false,
    prevArrow: null,
    nextArrow: null,

    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 500,

    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => <div className="ft-slick__dots--custom"></div>,
  };

  return (
    <Slider {...settings} className="carousel-container">
      {featuredProducts.map((product) => (
        <div key={product.id}>
          <img src={product.imageURL} alt={`Product ${product.id}`} />
        </div>
      ))}
    </Slider>
  );
};

export default CarouselHighlights;
