import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
    minHeight: "30vh",
    width: 280,
    margin: "20px auto",
  },
  avatar: {
    backgroundColor: "#ff5722",
  },
  link: {
    marginTop: "16px",
    display: "flex",
  },
  error: {
    color: "red",
    fontSize: "13px",
  },
  loginHead: {
    backgroundColor: "#FB7E08",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBlockEnd: "40px",
    clipPath: "ellipse(100% 90% at 50% 0%)",
  },
  loginHeadImage: {
    marginBlock: "68px 50px",
  },
  title: {
    color: "#253376",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "20px",
    marginBlockEnd: "30px",
    textTransform: "uppercase",
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
  },
  wrapper: {
    width: "90%",
    margin: "0 auto",
  },
  formContainer: {
    marginBlockStart: "3rem",
    marginBlockEnd: "45px",
    fontFamily: "Open Sans",
  },
  input: {
    width: "100%",
    padding: "15px 16px",
    borderRadius: "8px",
    background: "white",
    outline: "none",
    border: "none",
    boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
    marginBlockStart: "10px",
  },
  loginBackground: {
    backgroundColor: "#FFF9F2",
    color: "#253376",
    fontFamily: "Open Sans",
  },
  fieldRight: {
    display: "flex",
    justifyContent: "end",
  },
  fieldCenter: {
    display: "flex",
    justifyContent: "center",
  },
  textOrange: {
    color: "#FB7E08",
    marginBlock: "8px 35px",
  },

  debug: {
    border: "3px solid brown",
  },
  button: {
    backgroundColor: "#FB7E08",
    margin: "16px 0px 0px 0px",
    color: "white",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "19px",
    paddingBlock: "12px",
    borderRadius: "8px",
    textTransform: "none",
    height:"48px"
  },
  loginButtonsContainer:{
    display:"flex",
    flexDirection:"column",
    marginBottom:"16px"
  },
  text14: {
    fontSize: "14px",
    lineHeight: "19px",
  },
  error: {
    color: "red",
    fontSize: "13px",
  },
  facebookLogo:{
    color:"blue",
    aspectRatio:1/1,
    height:"24px",
  }
}));

export default useStyles;
