import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  userMenuContainer: {
    paddingTop: "80px", //quitar luego
    height: "100vh",
    width: "80vw",
    margin: 0,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    marginBottom: "20px",
  },
  logoHeader: {
    height: "100%",
    width: "auto",
  },
  buttonMenu: {
    margin: "16px auto",
    padding: "12px",
    paddingLeft: "27px",
    paddingRight: "27px",
    borderRadius: "10px",
    textTransform: "none",
    backgroundColor: "#FB7E08",
    color: "white",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "14px",
    fontWeight: "600",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FB7E08",
    },
  },
  menuItem: {
    backgroundColor: "#FFFFFF",
    color: "#253376",
    justifyContent: "left",
    padding: "12px",
    marginBottom: "8px",
    marginLeft: "15px",
    marginRight: "15px",
    borderRadius: "10px",
    textTransform: "none",
    fontFamily: "Open Sans, sans-serif",
    "&:hover": {
      backgroundColor: "#FEE5CE",
    },
    display: "flex",
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "8px",
  },
  divStyles: {
    color: "#253376",
    marginTop: "12px",
    alignItems: "center",
    fontWeight: "600",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "12px",
    textAlign: "center",
  },
  divStylesBig: {
    color: "#253376",
    fontWeight: "500",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "12vh",
  },
  divStylesBigSeller: {
    color: "#253376",
    fontWeight: "600",

    fontFamily: "Open Sans, sans-serif",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "27px",
  },

  socialIconsContainer: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center",
  },
  socialIcon: {
    color: "#FB7E08",
    width: "24px",
    height: "24px",
    fill: "#FB7E08",
    margin: "0 8px",
    cursor: "pointer",
    "&:hover": {
      color: "#FFA040",
    },
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "30px",
  },

  sidePanel: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: "1%",
    backgroundColor: "orange",
    zIndex: -1, // Para asegurarnos de que esté detrás de los otros elementos
  },
}));

export default useStyles;
