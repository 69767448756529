const Symbols = ["e", "E", "+", "-", ".", ","];

const handleInputWithoutDecimals = (event) => {
  if (Symbols.includes(event.key)) {
    event.preventDefault();
    return false;
  }
  return true;
};
const handleInputWithTwoDecimals = (event, max = null) => {
  const inputValue = event.target.value + event.key;
  const regexTemp = max
    ? `^\\d{1,${max}}(\\.\\d{1,2})?$`
    : `^\\d*\\.?\\d{0,2}$`;

  var decimalRegex = new RegExp(regexTemp);

  if (event.key === "Backspace" || event.key === ".") {
    return true;
  }

  if (!decimalRegex.test(inputValue) || !handleInputWithoutDecimals(event)) {
    event.preventDefault();
    return false;
  }
  return true;
};
const handleInputLength = (event, max, validateSymbols = false) => {
  if (event.key === "Backspace") {
    return true;
  }
  if (validateSymbols && !handleInputWithoutDecimals(event)) {
    event.preventDefault();
    return false;
  }
  if (event.target.value.length >= max) {
    event.preventDefault();
    return false;
  }
  return true;
};

export {
  handleInputWithTwoDecimals,
  handleInputWithoutDecimals,
  handleInputLength,
};
