import { useCallback, useState } from "react";
import { useFormik } from "formik";
import { login } from "../dataBaseAdapter";
const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

export const useLogin = ( history ) => {

    const [error, setError] = useState(null);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validateOnChange: true,
        validateOnBlur: true,
        validate: values => {
            const errors = {};
            if (!values.email) {
                errors.email = 'Requerido';
            }
            if (!isValidEmail(values.email)) {
                errors.email = 'Ingresa un correo electrónico válido';
            }
            if (!values.password) {
                errors.password = 'Requerido';
            }
            if (values.password.length < 6) {
                errors.password = 'La contraseña debe tener 6 digitos minimo';
            }
            return errors;
        },
        onSubmit: values => {
            login(values,()=>{history.push('/')},handleError)
        }
    });

    const handleError = useCallback(error => {
        if (error.code === 'auth/wrong-password') {
            setError('Contraseña incorrecta, intente nuevamente.')
        } else if (error.code === 'auth/user-not-found') {
            setError('El usuario no fue encontrado.')
        } else {
            setError('Ocurrió un error! Revise sus datos.')
        }

    }, [])

    return {
        formik,
        error
    }
}