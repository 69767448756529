import React from "react";
import { Button, Divider } from "@material-ui/core";

import useStyles from "./MenuUserStyles";
import logoHeader from "../../Icons/menu/white-LogoV-white@2x 1.svg";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import iconFacebook from "../../Icons/menu/facebook.svg";
import iconHelp from "../../Icons/menu/help-circle.svg";
import iconHome from "../../Icons/menu/home.svg";
import iconProducts from "../../Icons/menu/categories.svg";
import iconLogout from "../../Icons/menu/log-out.svg";
import { useHistory } from "react-router-dom";

import { signOut } from "../../dataBaseAdapter";
import { useDispatch } from "react-redux";

const MenuSeller = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    history.push("/");
  };

  const handleNewProduct = () => {
    history.push("/newproduct");
  };

  const handleFAQ = () => {
    console.log("to FAQ");
  };

  const handleProfile = () => {
    history.push("/profile");
  };

  const handleLogout = async () => {
    signOut();
    history.push("/");
    dispatch({ type: "user/unset"})

  };

  const navigateToProducts = () => {
    history.push("/profile");
    setTimeout(() => {
      const contactSection = document.getElementById("products");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  };
  return (
    <div className={classes.userMenuContainer}>
      <div className={classes.logoContainer} onClick={handleLogoClick}>
        <img
          src={logoHeader}
          alt="DesdeCasa footer"
          className={classes.logoHeader}
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        className={classes.buttonMenu}
        onClick={handleNewProduct}
      >
        Publicar nuevos productos!
      </Button>

      <div>
        <div className={classes.menuItem} onClick={handleProfile}>
          <img src={iconHome} alt="Help Icon" className={classes.icon} />
          Perfil de mi negocio
        </div>
        <div className={classes.menuItem} onClick={navigateToProducts}>
          <img src={iconProducts} alt="User Icon" className={classes.icon} />
          Mis productos
        </div>
        <div className={classes.menuItem} onClick={handleFAQ}>
          <img src={iconHelp} alt="Plus Icon" className={classes.icon} />
          Preguntas Frecuentes
        </div>
        <div className={classes.menuItem} onClick={handleLogout}>
          <img src={iconLogout} alt="Exit Icon" className={classes.icon} />
          Cerrar Sesión
        </div>
      </div>

      <Divider
        style={{ backgroundColor: "orange", margin: "0 4vw", marginTop: "15%" }}
      />
      <div className={classes.divStyles}>Siguenos en:</div>
      <div className={classes.socialIconsContainer}>
        <img
          src={iconFacebook}
          className={classes.socialIcon}
          alt="facebook"
          onClick={() =>
            window.open(
              "https://www.facebook.com/Desde-Casa-Bolivia-Plataforma-para-comida-casera-103413602166975"
            )
          }
        />
        <InstagramIcon
          className={classes.socialIcon}
          onClick={() => window.open("https://instagram.com/desdecasa.bolivia")}
        />
        <WhatsAppIcon
          className={classes.socialIcon}
          onClick={() => window.open("https://wa.me/59172203591")}
        />
      </div>
      <div className={classes.divStylesBigSeller}>
        Hecho con amor 🧡 | DesdeCasa 2023 v.0.3
      </div>
    </div>
  );
};

export default MenuSeller;
