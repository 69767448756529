import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: 'white',
        border: '1px solid #FB7E08',
        borderRadius: '8px',
        padding: '7px 16px',
        margin: 8,
        color: '#FB7E08',
        display: 'inline-block',
        width: 'fit-content',
        lineHeight: '18px',
    },
    selectedContainer: {
        backgroundColor: '#FEE5CE',  
        border: '1px solid #FB7E08',
        borderRadius: '8px',
        padding: '7px 16px',
        margin: 8,
        color: '#FB7E08',
        display: 'inline-block',
        width: 'fit-content',
        lineHeight: '18px',
    },
    icon: {
        marginRight: 4,
    },
}))

export default useStyles;