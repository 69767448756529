import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    flexShrink: 0,
    padding: "6px 15px",
    borderRadius: "8px",
    background: "white",
    outline: "none",
    border: "none",
    boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
    display: "flex",
    boxSizing: "unset",
  },
  timePickerBox: {
    width: "100%",
  },

}));

export default useStyles;
