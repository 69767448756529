import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overlayContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  checkIcon: {
    fontSize: "6rem",
    color: "#4CAF50",
    alignItems: "center",
  },
  title: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    alignItems: "center",
    paddingLeft: "10px",
    textAlign: "center",
    color: "#253376",
  },
  description: {
    color: "#253376",
    textAlign: "center",
    alignItems: "center",
    marginBottom: "0",
  },
  descriptionBig: {
    marginTop: "0",
    color: "#253376",
    textAlign: "center",
    alignItems: "center",
  },
  okButton: {
    margin: "16px auto",
    padding: "12px",
    width: "100%",
    borderRadius: "10px",
    textTransform: "none",
    backgroundColor: "#FB7E08",
    color: "white",
    fontWeight: "Bold",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "14px",
    //fontWeight: "600",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FB7E08",
    },
  },
  checkIconContainer: {
    backgroundColor: "#FEE5CE",
    borderRadius: "12%",
    padding: "20px",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
