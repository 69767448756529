import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: "12px 12px",
    margin: "0px",
    [theme.breakpoints.up("sm")]: {
      padding: "60px",
    },
  },
  titleProductList: {
    marginBottom: "0px",
    fontFamily: "Nunito Sans",
    fontSize: "16px",
    fontWeight: 800,
    lineHeight: "22px",
    letterSpacing: "1px",
    textAlign: "left",
    color: "#253376",
    paddingLeft: 12,
    textTransform: "uppercase",
  },
  btnSeeMore: {
    width: "112px",
    height: "40px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "center",
    borderRadius: "8px",
    border: "1px solid #FB7E08",
    color: "#FB7E08",
    backgroundColor: "transparent",
    textTransform: "none",
  },
  resultados: {
    fontFamily: "Nunito Sans",
    fontSize: "16px",
    fontWeight: 800,
    lineHeight: "22px",
    letterSpacing: "1px",
    textAlign: "left",
    color: "#253376",
    marginLeft: "12px",
    marginTop: "16px",
  },
  loadingSpinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
