export const handleEmptyNoticeHours = (content) => {
  if (content.priorNoticeHours) {
    if (content.priorNoticeHours === "inmediato/flexible") {
      return "Inmediato";
    }
    if (content.priorNoticeHours?.type) {
      let type = "";
      if (content.priorNoticeHours.type === "Horas") {
        type = "h";
      } else if (content.priorNoticeHours.type === "Días") {
        type = "d";
      } else if (content.priorNoticeHours.type === "Minutos") {
        type = "min";
      }

      return content.priorNoticeHours.time + type + " antes";
    }
    return content.priorNoticeHours + " h antes";
  }
  return "Consultar";
};

export const handleEmptyNoticeHoursPreview = (content) => {
  if (content.PriorNoticeTime) {
    return (
      content.PriorNoticeTime.time +
      " " +
      content.PriorNoticeTime.type +
      " de anticipacion"
    );
  }
  return "Consultar";
};
