import {  makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    OverlayContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(251, 126, 8, 0.2)',
        zIndex: 999, 
    },
    Overlay:{
        display:"flex",
        justifyContent: 'center',
        alignItems: 'center',
        position: "absolute",
        width: "91%",
        height: "57%",
        backgroundColor: "white",
        borderRadius: 12,
        backdropFilter: "blur(10px)",
    }
  }));

  export default useStyles