import React, { useEffect, useRef } from "react";
import useStyles from "./OverlayStyles";

const Overlay = ({ show, children, clickOnOverlayBackground }) => {
  const classes = useStyles();
  const overlayContainerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (overlayContainerRef.current === event.target) {
      clickOnOverlayBackground();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      {show && (
        <div className={classes.OverlayContainer} ref={overlayContainerRef}>
          <div className={classes.Overlay}>{children}</div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Overlay;
