import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    home:{
        backgroundColor: '#FFF9F2',
    },
    containerGreeting: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px',
        paddingTop: '80px',
    },
    greeting: {
        fontWeight: 800,
        fontSize: '20px',
        lineHeight: '20px',
        color: '#253376',
        fontFamily: 'Open Sans',
    },
    city: {
        border: '0.5px solid #FB7E08',
        borderRadius: '105.882px',
        padding: '8px 16px',
        color: "#FB7E08",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '0px',
        textAlign: 'center',
        fontFamily: 'Open Sans',
    },
    filterButton:{
        width: '16px',
        height: '16px',
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    }
}));

export default useStyles