import React, { useState} from "react";
import { Menu, MenuItem, Paper } from '@material-ui/core';
import useStyles from './zoneMenuStyles'
import DownArrow from '../../Icons/chevron-down.svg'

const ZoneMenu = ({ placeholder, onSelect }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
  
    const handleMenuItemClick = (value) => {
      onSelect(value);
      handleCloseMenu();
    };
  
    const options = [
      { value: 'Recoleta', label: 'Recoleta' },
      { value: 'Cala Cala', label: 'Cala Cala' },
      { value: 'Quillacollo', label: 'Quillacollo' },
      { value: 'Zona Norte', label: 'Zona Norte' },
      { value: 'Zona Sur', label: 'Zona Sur' },
      { value: 'Zona Centro', label: 'Zona Centro' },
      { value: 'Zona Este', label: 'Zona Este' },
      { value: 'Zona Oeste', label: 'Zona Oeste' },
      { value: 'Zona Noroeste', label: 'Zona Noroeste' },
    ];
  
    return (
      <div>
        <div className={classes.container} onClick={handleOpenMenu}>
          <span className={classes.placeholder}>{placeholder}</span>
          <img src={DownArrow} alt="Selecciona una Zona" />
        </div>
        <Paper sx={{ width: 260}}>
          <Menu 
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            PaperProps={{style:{width: '90vw'}}}
          >
            {options.map((option) => (
              <MenuItem 
                key={option.value}
                value={option.value}
                onClick={() => handleMenuItemClick(option.value)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Paper>
      </div>
    );
  };
  
  export default ZoneMenu;