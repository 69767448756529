import { makeStyles } from "@material-ui/core";
const inputTextColor = "#253376";
const useStyles = makeStyles((theme) => ({
  Container: {
    display: "flex",
    flexDirection: "column",
    height:"100vh"

  },
  mainContainer: {
    height:"100%",
    marginTop: "60px",
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "#FFF9F2",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    width: "100%",
    padding: "15px 16px",
    borderRadius: "8px",
    background: "white",
    outline: "none",
    border: "none",
    boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
    marginBlockStart: "10px",
    color: inputTextColor,
    fontSize: "14px",
  },
  textArea: {
    fontFamily: "Open Sans",
    width: "100%",
    height: 100,
    padding: "15px 16px",
    borderRadius: "8px",
    background: "white",
    outline: "none",
    border: "none",
    boxShadow: "0px 1.13018px 5.65088px rgba(0, 0, 0, 0.16)",
    marginBlockStart: "10px",
    "&::-webkit-input-placeholder": { color: "#ABABAB", fontSize: "14px" },
    color: inputTextColor,
    fontSize: "14px",
  },
  error: {
    fontSize: "13px",
    color: "red",
    fontFamily: "Open Sans",
    textAlign: "justify",
    width: "80%",
  },

  buttonContainer: {
    width: "100vw",
    padding: "12px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
    flexDirection: "row",
    justifyContent: "space-between",

    display: "flex",
    zIndex: 100,
    position: "fixed",
    bottom: 0,
    left: 0,

    backgroundColor: "white",
  },

  btnPreview: {
    width: "47%",
    height: "48px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "center",
    borderRadius: "8px",
    border: "1px solid #FB7E08",
    color: "#FB7E08",
    backgroundColor: "transparent",
    textTransform: "none",

    fontStyle: "normal",
    paddingBlock: "12px",
  },

  buttonSubmit: {
    width: "47%",
    height: "48px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "center",
    borderRadius: "8px",
    color: "white",
    backgroundColor: "#FB7E08",
    textTransform: "none",

    fontStyle: "normal",
    paddingBlock: "12px",
  },


}));

export default useStyles;
