import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  recentProductsContainer:{
    display:"flex",
    flexDirection:"row",
    overflowX:"auto",
    overflowY: "hidden",
  },
  productBox:{
    minWidth:"278px",
    width:"75%",
    marginLeft:"12px"
  },
  title:{
    fontFamily:"Nunito Sans",
    fontWeight:800,
    fontSize:"16px",
    marginLeft:"12px",
    color:"#253376"
  }
}));
export default useStyles