import React from "react";
import useStyles from "./sorryTextStyles";
import SearchIcon from "@material-ui/icons/Search";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const SorryText = ({ placeholder, onSelect }) => {
  const searchedParam = useSelector((state) => state.searchs.noProductsFound);

  const classes = useStyles();
  useEffect(() => [searchedParam]);
  return (
    <React.Fragment>
      {searchedParam === true && (
        <div className={classes.container}>
          <SearchIcon className={classes.icon} />
          <div className={classes.title}>Lo sentimos</div>
          <div className={classes.subTitle}>
            No hemos encontrado resultados que
          </div>
          <div className={classes.subTitle}>coincidan con su búsqueda.</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SorryText;
