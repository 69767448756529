import palta from "../Images/categories/avocado.svg";
import ensalada from "../Images/categories/ensalada3.svg";
import bowl from "../Images/categories/bowls3.svg";
import flag from "../Images/categories/nacional.svg";
import world from "../Images/categories/internacional.svg";
import breakfast from "../Images/categories/desayunos1.svg";
import soup from "../Images/categories/sopa.svg";
import lettuce from "../Images/categories/vegetariano.svg";
import pizza from "../Images/categories/pizza1.svg";
import pasta from "../Images/categories/pasta.svg";
import burger from "../Images/categories/burger.svg";
import chicken from "../Images/categories/pollo.svg";
import fish from "../Images/categories/pescados.svg";
import steak from "../Images/categories/parrilla2.svg";
import fastfood from "../Images/categories/comida rapida.svg";
import bread from "../Images/categories/panaderia2.svg";
import cupcake from "../Images/categories/reposteria.svg";
import pot from "../Images/categories/artesanal.svg";
import sandwich from "../Images/categories/sandwich1.svg";
import apple from "../Images/categories/saludable2.svg";
import bottle from "../Images/categories/bebidas.svg";
import house from "../Images/categories/casa.svg";
import gluten from "../Images/categories/gluten-free.svg";

export const categories = {
  "Todos los Productos": house,
  Ensaladas: ensalada,
  Bowls: bowl,
  Nacional: flag,
  Internacional: world,
  Desayunos: breakfast,
  Sopas: soup,
  Vegetariano: lettuce,
  Pizzas: pizza,
  Pastas: pasta,
  Hamburguesas: burger,
  Pollo: chicken,
  "Pescados & Mariscos": fish,
  Parrilla: steak,
  "Comida Rápida": fastfood,
  Panadería: bread,
  Repostería: cupcake,
  //Postres: pot,
  Bebidas: bottle,
  "Alimentos Artesanales": pot,
  Sandwiches: sandwich,
  "Saludable & Fit": apple,
  Vegano: palta,
  "Gluten Free": gluten,
};
