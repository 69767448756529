import React, { useState, useEffect } from "react";
import useStyles from "./DayOptionStyles";

const DayOption = ({ day, handleDaySelection }) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(day.isSelected);

  useEffect(() => {
    setIsChecked(day.isSelected);
  }, [day.isSelected]);

  const handleClick = () => {
    setIsChecked(!isChecked);
    handleDaySelection(day.option, !isChecked);
  };

  return (
    <div className={classes.dayOptionContainer} onClick={handleClick}>
      <input
        type="checkbox"
        className={isChecked ? classes.checked : classes.checkbox}
      />
      <span className={classes.day}>{day.option}</span>
      <div className={classes.bottomLine}></div>
    </div>
  );
};

export default DayOption;
