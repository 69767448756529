import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: 0,
        fontSize: 16,
        color: '#253376',
        fontWeight: 800,
        fontFamily: 'Nunito Sans',
        paddingLeft: 16,
        letterSpacing: 1,
    },
    imageContainer: {
        backgroundColor: '#fee5ce',
        height: 88,
        position: 'relative',
        top: 10,
        zIndex: -1,
        marginTop: 45,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    circleBackground: {
        backgroundColor: 'white',
        borderRadius: '50%',
        width: 50,
        height: 50,
        display: 'flex',
    },
    img: {
        objectFit: 'scale-down',
    }
}))

export default useStyles;