import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px', 
        textAlign: 'center',
        fontFamily: 'Nunito Sans',
        paddingBottom: 24,
        backgroundColor: 'white',
    },
    businessName: {
        color: '#253376',
        fontSize: 24,
        fontWeight: 700,
        margin: 0,
        padding: '16px 0px',
    },
    infoContainer: {
        background: 'rgba(254,229,206,0.5)',
        margin: '0 20px 0 16px',
        borderRadius: 8,
        padding: 12,
        color: '#707070'
    },
    contactLabel: {
        margin: 0,
        fontSize: 12,
        fontWeight: 300,
    },
    contactNumber: {
        margin: 0,
        fontWeight: 400,
        fontSize: 14,
        color: '#253376',
    },
    zoneLabel: {
        margin: 0,
        fontSize: 12,
        fontWeight: 300,
    },
    zone: {
        margin: 0,
        fontSize: 14,
        fontWeight: 400,        
        width: 'auto',
        color: '#253376',
    },
}));

export default useStyles