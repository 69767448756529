import React, { useState } from "react";
import useStyles from "./ImageInputStyles";
import IconCarpet from "../../Icons/empty-folder.svg";
import Resizer from "react-image-file-resizer";
import Product from "../product/Product";

const ImageInput = ({
  updateImageFile,
  setTouched,
  previewProductValues,
  previewSellerValues,
  initialImageUri = "",
}) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState(null);
  const [imageUri, setImageUri] = useState(initialImageUri);

  const sellerTemplate = {
    name: previewProductValues?.productName
      ? previewProductValues?.productName
      : "Nombre Del Producto",
    user: {
      contactName: previewSellerValues?.contactName
        ? previewSellerValues?.contactName
        : "Galletitas Morazul",
      zone: previewSellerValues?.zone ? previewSellerValues?.zone : "norte",
    },
    price: previewProductValues?.productPrice
      ? previewProductValues?.productPrice
      : "00",
    imageUrl: "",
    priorNoticeHours: previewProductValues?.PriorNoticeTime
      ? previewProductValues?.PriorNoticeTime
      : null,
    days: previewProductValues?.days ? previewProductValues?.days : null,
  };
  const imageCallback = async (image) => {
    let imageUri = URL.createObjectURL(image);
    setImageUri(imageUri);
    setImage(image);
    updateImageFile(image);
    setTouched();
  };
  const resizeFile = async (file) => {
    try {
      return Resizer.imageFileResizer(
        file,
        600,
        600,
        "JPEG",
        90,
        0,
        (uri) => {
          imageCallback(uri);
        },
        "file"
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleImageChange = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      await resizeFile(file);
    }
  };
  return (
    <div className={classes.mainContainer}>
      {!imageUri && (
        <div className={classes.borderContainer}>
          <React.Fragment>
            <div className={classes.iconCarpetContainer}>
              <img
                src={IconCarpet}
                alt="Subir Imagen"
                className={classes.iconCarpet}
              />
            </div>
            <div className={classes.buttonContainer}>
              <input
                type="file"
                id="newProductImg"
                accept="image/*"
                className={classes.hiddenObject}
                onChange={handleImageChange}
              />
              <label htmlFor="newProductImg" className={classes.buttonAddImage}>
                Agregar foto del producto
              </label>
            </div>
            <div className={classes.tipText}>
              Tip: Tu producto sera mas visible si tu foto es atractiva
            </div>
          </React.Fragment>
        </div>
      )}
      {imageUri && (
        <div className={classes.previewImgContainer}>
          <Product
            content={{ ...sellerTemplate, imageUrl: imageUri }}
            enabled={false}
          />
          <span className={classes.previewText}>
            *Vista Previa Del Producto
          </span>
          <div className={classes.clearPreviewImageContainer}>
            <input
              type="file"
              id="newProductImg"
              accept="image/*"
              className={classes.hiddenObject}
              onChange={handleImageChange}
            />
            <label htmlFor="newProductImg" className={classes.buttonAddImage}>
              Cambiar Imagen
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageInput;
