/* eslint-disable react-hooks/exhaustive-deps */
import NavbackPreview from "../../navbar/NavbackPreview";

import { Avatar, Button } from "@material-ui/core";
import React, { useRef, useState } from "react";
import ImageInput from "../../imageInput/ImageInput";
import { deleteProduct, deleteImage } from "../../../dataBaseAdapter";
import useStyles from "./EditProductFormStyles";
import { Input } from "../../inputs/Input";
import SelectCategories from "../../selectcategories/SelectCategories";
import { TextArea } from "../../inputs/TextArea";
import SelectDays from "../../selectdays/SelectDays";
import PriorNoticeHours from "../../priornoticehours/PriorNoticeHours";
import CustomTimePicker from "../../timepicker/TimePicker";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SelectDeliveryType from "../../selectdeliverytype/SelectDeliveryType";
import Overlay from "../../overlay/Overlay";
import NewProductFinishedOverlay from "../newProductFinishedOverlay/NewProductFinishedOverlay";
import ProductPreview from "../ProductPreview";
import Dialog from "@material-ui/core/Dialog";
import useNewProduct from "../../../hooks/useNewProduct";
import { useEffect } from "react";
import app, { db } from "../../../firebase";
import { handleProducts } from "../../../utils/fireBaseToFormikAdapter";
import {
  handleInputWithTwoDecimals,
  handleInputLength,
} from "../../../utils/handleInputs";
import DeleteProductOverlay from "../deleteProductOverlay/DeleteProductOverlay";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const currency = "Bs.";

const EditProductForm = () => {
  const history = useHistory();
  const location = useLocation();
  const product = location.state;

  const initialValues = handleProducts(product);
  const initialImageUri = product.imageUrl;
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({
    uid: "",
    email: "",
    contactName: "",
    contactNumber: "",
    zone: "",
  });

  const classes = useStyles();

  const submitNewProduct = (productValues, docId) => {
    let newGuideEntry = {
      id: docId,
      name: productValues.name,
      lastUpdate: productValues.lastUpdate,
      price: productValues.price,
      photoRating: productValues.photoRating,
    };
    let guideUpdated = guide
      .slice()
      .map((item) => (item.id === newGuideEntry.id ? newGuideEntry : item));
    dispatch({
      type: "guides/productsGuideRetrieved",
      payload: guideUpdated,
    });
    newProductFormik.values.Categories.forEach((category) => {
      dispatch({
        type: "guides/addCategorieGuide",
        payload: null,
        category: category,
      });
    });
    dispatch({
      type: "fetcher/guideRefreshed",
    });
    setProductPublished(true);
  };

  const handleBack = () => {
    history.goBack();
  };

  // eslint-disable-next-line no-unused-vars
  const [newProductFormik, formikHasDefaultValues] = useNewProduct(
    submitNewProduct,
    initialValues,
    { isEditing: true, productID: product.id },
    initialImageUri
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showChangedOverlay, setShowChangedOverlay] = useState(false);
  const [showingModal, setShowingModal] = useState("");
  const [previewMode, setPreviewMode] = useState(false);
  const [productPublished, setProductPublished] = useState(false);
  const guide = useSelector((state) => state.guides.guides);

  const formButtonRef = useRef();

  const handleDelete = async () => {
    setIsDeleting(true);
    let modifiedCategoryGuides = await deleteProduct(product.id);
    await deleteImage(initialImageUri);
    let guideUpdated = guide.slice().filter((item) => item.id !== product.id);
    dispatch({
      type: "guides/productsGuideRetrieved",
      payload: guideUpdated,
    });
    modifiedCategoryGuides.forEach((category) => {
      dispatch({
        type: "guides/addCategorieGuide",
        payload: null,
        category: category,
      });
    });
    dispatch({
      type: "fetcher/guideRefreshed",
    });
    handleBack();
  };

  const handlePreview = () => {
    setPreviewMode(true);
  };

  const handleClosePreview = () => {
    setPreviewMode(false);
  };
  const handleExternalSubmit = () => {
    formButtonRef.current.click();
  };

  const askToShowModal = (modalId) => {
    switch (showingModal) {
      case "":
        setShowingModal(modalId);
        return true;
      default:
        if (showingModal !== modalId) return false;
        setShowingModal("");
        return true;
    }
  };

  const handleDeliveryTypeChange = (selectedOption) => {
    newProductFormik.setFieldValue("DeliveryType", selectedOption);
  };

  const setFormikValue = async (field, value) => {
    await newProductFormik.setFieldValue(field, value, false); // Update the closingTime field value
    newProductFormik.setErrors(await newProductFormik.validateForm());
  };

  useEffect(() => {
    const unsubscribe = app.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            setUserInfo(doc.data());
          });
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <React.Fragment>
      <Overlay
        show={showOverlay}
        clickOnOverlayBackground={() => {
          setShowOverlay(false);
        }}
      >
        <DeleteProductOverlay
          backButtonFunction={() => {
            setShowOverlay(false);
            setIsDeleting(false);
          }}
          confirmDelete={() => {
            handleDelete();
          }}
          isDeleting={isDeleting}
        />
      </Overlay>

      <Overlay
        show={productPublished}
        clickOnOverlayBackground={() => {
          setShowChangedOverlay(false);
        }}
      >
        <NewProductFinishedOverlay
          handleBack={() => {
            handleBack();
          }}
        />
      </Overlay>

      <NavbackPreview title="EDITAR PUBLICACION" handleClick={handleBack} />

      <div className={classes.formContainer}>
        <div className={classes.businessInfo}>
          <Avatar alt="restaurante" sx={{ width: 40, height: 40 }} />
          <span className={classes.title}>{userInfo?.contactName}</span>
        </div>

        <form
          onSubmit={newProductFormik.handleSubmit}
          className={classes.mainForm}
        >
          <div className={classes.inputsContainer}>
            <ImageInput
              updateImageFile={(file) =>
                newProductFormik.setFieldValue("imageFile", file)
              }
              setTouched={() =>
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  imageFile: true,
                })
              }
              previewProductValues={newProductFormik.values}
              previewSellerValues={userInfo}
              initialImageUri={initialImageUri}
            />
            {newProductFormik.touched.imageFile && (
              <span className={classes.error}>
                {newProductFormik.errors.imageFile}
              </span>
            )}

            <Input
              name="productName"
              type="text"
              placeholder="Nombre del Producto"
              classTypes={classes.input}
              value={newProductFormik.values.productName}
              onChange={newProductFormik.handleChange}
              onBlur={newProductFormik.handleBlur}
              onKeyDown={(e) => handleInputLength(e, 30)}
            />
            {newProductFormik.touched.productName && (
              <span className={classes.error}>
                {newProductFormik.errors.productName}
              </span>
            )}

            <div className={classes.priceContainer}>
              <span className={classes.staticCurrency}>{currency}</span>
              <Input
                name="productPrice"
                type="number"
                placeholder={"Precio"}
                classTypes={classes.inputSmallHidden}
                value={newProductFormik.values.productPrice}
                onChange={newProductFormik.handleChange}
                onBlur={newProductFormik.handleBlur}
                onKeyDown={(e) => handleInputWithTwoDecimals(e, 4)}
              />
            </div>
            {newProductFormik.touched.productPrice && (
              <span className={classes.error}>
                {newProductFormik.errors.productPrice}
              </span>
            )}

            <SelectCategories
              changeCategories={async (value) => {
                await newProductFormik.setFieldValue("Categories", value);
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  Categories: true,
                });
              }}
              value={newProductFormik.values.Categories}
              askToShowModal={askToShowModal}
            />
            {newProductFormik.touched.Categories && (
              <span className={classes.error}>
                {newProductFormik.errors.Categories}
              </span>
            )}

            <TextArea
              name="productDescription"
              placeholder="Descripción"
              classTypes={classes.textArea}
              value={newProductFormik.values.productDescription}
              onChange={newProductFormik.handleChange}
              onBlur={newProductFormik.handleBlur}
            />
            {newProductFormik.touched.productDescription && (
              <span className={classes.error}>
                {newProductFormik.errors.productDescription}
              </span>
            )}

            <SelectDays
              onChange={(value) => {
                newProductFormik.setFieldValue("days", value);
              }}
              value={newProductFormik.values.days}
              askToShowModal={askToShowModal}
              touched={() => {
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  days: true,
                });
              }}
            />
            {newProductFormik.touched.days && (
              <span className={classes.error}>
                {newProductFormik.errors.days}
              </span>
            )}

            <PriorNoticeHours
              classTypes={classes}
              values={newProductFormik.values.PriorNoticeTime}
              setFieldValue={(newValue) => {
                newProductFormik.setFieldValue(`PriorNoticeTime`, newValue);
              }}
              askToShowModal={askToShowModal}
              touched={() => {
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  PriorNoticeTime: true,
                });
              }}
            />
            {newProductFormik.touched.PriorNoticeTime && (
              <span className={classes.error}>
                {newProductFormik.errors.PriorNoticeTime?.time}
              </span>
            )}
            <div className={classes.timePickerContainer}>
              <div className={classes.timePickerWrapper}>
                <CustomTimePicker
                  value={newProductFormik.values.openingTime}
                  label="Horario Apertura"
                  onChange={(time) => setFormikValue("openingTime", time)}
                  touched={() => {
                    newProductFormik.setTouched({
                      ...newProductFormik.touched,
                      openingTime: true,
                    });
                  }}
                />
                {newProductFormik.touched.openingTime && (
                  <span className={classes.error}>
                    {newProductFormik.errors.openingTime}
                  </span>
                )}
              </div>

              <div className={classes.timePickerWrapper}>
                <CustomTimePicker
                  value={newProductFormik.values.closingTime}
                  label="Horario Clausura"
                  onChange={(time) => setFormikValue("closingTime", time)}
                  touched={() => {
                    newProductFormik.setTouched({
                      ...newProductFormik.touched,
                      closingTime: true,
                    });
                  }}
                />
                {newProductFormik.touched.closingTime && (
                  <span className={classes.error}>
                    {newProductFormik.errors.closingTime}
                  </span>
                )}
              </div>
            </div>

            <SelectDeliveryType
              value={newProductFormik.values.DeliveryType}
              onChange={handleDeliveryTypeChange}
              askToShowModal={askToShowModal}
              touched={() => {
                newProductFormik.setTouched({
                  ...newProductFormik.touched,
                  DeliveryType: true,
                });
              }}
            />
            {newProductFormik.touched.DeliveryType && (
              <span className={classes.error}>
                {newProductFormik.errors.DeliveryType}
              </span>
            )}
          </div>

          <div className={classes.buttonContainer}>
            <div className={classes.previewDeleteButtonWrapper}>
              <Button
                disableElevation
                className={classes.btnPreview}
                type="button"
                variant="contained"
                onClick={handlePreview}
              >
                Vista Previa
              </Button>

              <Button
                disableElevation
                className={classes.buttonDelete}
                type="button"
                variant="contained"
                onClick={() => {
                  setShowOverlay(true);
                }}
              >
                Eliminar
              </Button>
            </div>

            <div className={classes.submitButtonWrapper}>
              <Button
                disableElevation
                className={classes.buttonSubmit}
                type="submit"
                variant="contained"
                ref={formButtonRef}
                style={{
                  backgroundColor: productPublished ? "#e8e4e4" : "#FB7E08",
                }}
              >
                Actualizar Producto
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Dialog
        open={previewMode}
        onClose={handleClosePreview}
        fullWidth
        maxWidth="md"
      >
        <ProductPreview
          userInfo={userInfo}
          data={newProductFormik.values}
          handleClose={handleClosePreview}
          handleSubmit={handleExternalSubmit}
          initialImageUrl={initialImageUri}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default EditProductForm;
