import React from "react";
import useStyles from "./TimePickerDCStyles";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
const CustomTimePicker = ({ label, onChange, value, touched }) => {
  const classes = useStyles();

  const setValue = (newValue) => {
    touched(); // Call the touched callback to update the field's touched state

    if (!newValue) {
      return;
    }
    const selectedDate = new Date(newValue["$d"]);
    const hours = selectedDate.getHours().toString().padStart(2, "0");
    const minutes = selectedDate.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;
    onChange(formattedTime); // Call the onChange callback with the selected time
  };

  return (
    <div className={classes.container}>
      <div className={classes.timePickerBox}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            label={label}
            minutesStep={15} // Set the interval to 15 minutes
            sx={{
              "& input": {
                color: "#253376", // Change the color of the time picker text here
              },
              "& label": {
                color: "#ABABAB",
              },
              "& .MuiPickersClock-clockNumberSelected": {
                color: "#253376", // Change the color of the selected time here
              },
              "& .MuiPickersClock-pin": {
                // backgroundColor: "#253376", // Change the background color of the selector here
              },
              "& svg": {
                color: "#253376", // Change the color of the label text here
              },
            }}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                variant: "standard",
              },
            }}
            defaultValue={value ? dayjs(value, "HH:mm") : null}
            onChange={(newValue) => setValue(newValue)}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default CustomTimePicker;
