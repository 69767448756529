/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useStyles from "./ProfileStyles";
import ZoneMenu from "../zoneMenu/zoneMenu";
import cafehouseicon from "../../Images/cafehouseicon.png";
import { getUser } from "../../dataBaseAdapter";
import { subscribeToAuthStatus } from "../../dataBaseAdapter";
import useProfile from "../../hooks/useProfile";
import { handleInputLength } from "../../utils/handleInputs";
import {  useSelector,useDispatch } from "react-redux";

//implementar esto para eliminar las lineas al momento de ingresar texto en TextField
const themeOverrides = {
  MuiInput: {
    underline: {
      "&:before": {
        left: "unset",
        right: "unset",
      },
      "&:after": {
        left: "unset",
        right: "unset",
      },
    },
  },
};
// eslint-disable-next-line no-unused-vars
const theme = createTheme({
  overrides: themeOverrides,
});

const ProfileInfoCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.user.user);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const succesfulUserUpdate=(values)=>{
    setIsLoading(false);
    setIsEditing(false);
    dispatch({ type: "user/set",payload:{...loggedUser,...values} })
  }
  const [profileFormik,setOriginalUser] = useProfile(() => {
    setIsLoading(false);
    setIsEditing(false);
  })

  const handleFormikUpdate = (prop, value) => {
    profileFormik.setValues({ ...profileFormik.values, [prop]: value });
  };

  useEffect(() => {
    const handleUserStatus = async (user) => {
      if (user) {
        let userData = await getUser(user.uid);
        setOriginalUser(userData)
        let formikObj = {
          uid: userData.uid,
          name:userData?.name,
          email: userData.email,
          contactName: userData.contactName,
          contactNumber: userData.contactNumber,
          zone: userData.zone,
        };

        profileFormik.setValues(formikObj);
      }
    };
    const unsubscribe = subscribeToAuthStatus(handleUserStatus);
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  const actualizar = async () => {
    try {
      setIsLoading(true);
      profileFormik.handleSubmit().then(() => {});
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.sectionDesktop}>
      <div className={classes.backgroundImageContainer}>
        <div className={classes.roundedImage}>
          <img
            src={cafehouseicon}
            alt="cafehouseicon"
            className={classes.backgroundImage}
          />
        </div>
      </div>
      <div className={classes.root}>
        <Paper elevation={0} className={classes.paperWeb}>
          <h2 className={classes.infoTitle}>
            {profileFormik.values.contactName}
          </h2>

          <h2 className={classes.fieldLabel}>Correo Electronico</h2>
          <Grid container direction="row" className={classes.profileInput}>
            <Typography variant="subtitle1" style={{ color: "#a2a2a2" }}>
              {profileFormik.values.email}
            </Typography>
          </Grid>

          
          <h2 className={classes.fieldLabel}>Nombre del Negocio</h2>
          <Grid container direction="row" className={classes.profileInput}>
            <Typography variant="subtitle1" style={{ color: "#253376" }}>
              {isEditing ? (
                <React.Fragment>
                  
                  <TextField
                    placeholder="Nombre de Negocio o Marca"
                    value={profileFormik.values.contactName}
                    onChange={(e) => {
                      handleFormikUpdate("contactName", e.target.value);
                    }}
                    onBlur={() => {
                      profileFormik.setTouched({
                        ...profileFormik.touched,
                        contactName: true,
                      });
                    }}
                    inputProps={{ maxLength: 40 }}
                  />
                  <br />
                  {profileFormik.touched.contactName && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      {profileFormik.errors.contactName}
                    </Typography>
                  )}
                </React.Fragment>
              ) : (
                profileFormik.values.contactName
              )}
            </Typography>
          </Grid>
          <h2 className={classes.fieldLabel}>Número de WhatsApp</h2>
          <Grid container direction="row" className={classes.profileInput}>
            <Typography
              variant="subtitle1"
              style={{
                color: "#253376",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {isEditing ? (
                <>
                  <TextField
                    placeholder="Número de WhatsApp"
                    style={{ color: "black" }}
                    className={classes.numericInput}
                    value={profileFormik.values.contactNumber}
                    onChange={(e) => {
                      handleFormikUpdate("contactNumber", e.target.value);
                    }}
                    onBlur={() => {
                      profileFormik.setTouched({
                        ...profileFormik.touched,
                        contactNumber: true,
                      });
                    }}
                    onKeyDown={(e) => handleInputLength(e, 8, true)}
                    type="number"
                  />
                  {profileFormik.touched.contactNumber && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      {profileFormik.errors.contactNumber}
                    </Typography>
                  )}
                </>
              ) : (
                profileFormik.values.contactNumber
              )}
            </Typography>
          </Grid>
          <h2 className={classes.fieldLabel}>Zona</h2>

          <div
            className={`${classes.zoneInput} ${
              isEditing ? classes.zoneInput : classes.zoneInputEditing
            }`}
          >
            <Typography variant="subtitle1" style={{ color: "#253376" }}>
              {isEditing ? (
                <ZoneMenu
                  onSelect={(value) => {
                    handleFormikUpdate("zone", value);
                  }}
                  placeholder={profileFormik.values.zone}
                  onBlur={profileFormik.handleBlur}
                  PaperProps={{ style: { width: "90vw" } }}
                ></ZoneMenu>
              ) : (
                <span>{profileFormik.values.zone}</span>
              )}
            </Typography>
          </div>

          <Grid container direction="row" style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={() => {
                if (isEditing) {
                  actualizar();
                } else {
                  setIsEditing(true);
                }
              }}
              name="updateButton"
              type="submit"
              className={classes.updateButton}
            >
              {isLoading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : isEditing ? (
                "Guardar Cambios"
              ) : (
                "Editar información"
              )}
            </Button>
          </Grid>
        </Paper>
      </div>
      <Divider style={{ backgroundColor: "orange", margin: "16px 0" }} />
    </div>
  );
};

export default ProfileInfoCard;
