import React, { useEffect, useRef } from "react";
import useStyles from "./filterMenuStyles";
import { Button } from "@material-ui/core";
import PriceFilter from "./filterOptions/priceFilter";
import { useDispatch } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";

const filterHandler = (filtersApplied, productsList) => {
  let productListCopy = productsList.slice();
  // eslint-disable-next-line array-callback-return
  Object.entries(filtersApplied).every(([key, value]) => {
    switch (key) {
      case "price":
        productListCopy = productListCopy.sort((a, b) => {
          return value === "Más bajo" ? a.price - b.price : b.price - a.price;
        });
        break;
      default:
        return true;
    }
  });
  return productListCopy;
  /*
    if (filterKey=="price"){
        fetchProps["orderingCriteria"]="price"
        if (option=="Más alto")
            fetchProps["descOrder"]=true
    }
    return fetchProps*/
};
const FilterMenu = ({ show, close }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const overlayContainerRef = useRef(null);
  const selectedFilters = {};
  const handleClickOutside = (event) => {
    if (overlayContainerRef.current === event.target) {
      close();
    }
  };
  const updateSelectedFilter = (key, value) => {
    selectedFilters[key] = value;
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedFilters = () => {
    dispatch({ type: "filter/extraFilter", extraFilters: selectedFilters });
    dispatch({ type: "fetcher/shouldFetch" });
    close();
  };
  return (
    <React.Fragment>
      {show && (
        <div className={classes.MenuContainer} ref={overlayContainerRef}>
          <div className={classes.Menu}>
            <ClearIcon
              style={{ color: "#FB7E08" }}
              className={classes.closeButton}
              onClick={() => {
                close();
              }}
            />
            <span className={classes.filtersTitle}>FILTROS</span>
            <PriceFilter
              keyValue={"price"}
              setSelectedExternal={updateSelectedFilter}
            ></PriceFilter>
            <Button
              className={classes.confirmButtonStyle}
              onClick={handleSelectedFilters}
            >
              Ver Resultados
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export { FilterMenu, filterHandler };
