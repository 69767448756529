import React from "react";
import useStyles from "./RadioOptionStyles";

const RadioOption = ({ opt, isActive, onSelect }) => {
  const classes = useStyles();

  const handleClick = () => {
    onSelect();
  };

  return (
    <div className={classes.radioOptionContainer} onClick={() => handleClick()}>
      <input
        type="radio"
        className={!isActive ? classes.radioButton : classes.radioButtonChecked}
        checked={isActive}
        onChange={onSelect}
      />
      <span className={classes.option}>{opt[1].option}</span>
      <div className={classes.bottomLine}></div>
    </div>
  );
};

export default RadioOption;
