import React from "react";
import EditProductForm from "../../components/product/editProductForm/EditProductForm";
const EditProduct = () => {
  return (
    <>
      {/* <NavBack title="NUEVA PUBLICACIOON"/> */}
      <EditProductForm />
    </>
  );
};

export default EditProduct;
