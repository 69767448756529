import React, { useState} from "react";
import { Button, Divider } from "@material-ui/core";

import useStyles from "./MenuSellerDesktopStyles";
import logoHeader from "../../../Icons/menu/white-LogoV-white@2x 1.svg";
import iconHelp from "../../../Icons/menu/help-circle.svg";
import iconUser from "../../../Icons/menu/user-check.svg";
import iconPlus from "../../../Icons/menu/user-plus.svg";
import { useHistory } from "react-router-dom";

import { signOut } from "../../../dataBaseAdapter";

const MenuUserDesktop = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleLogoClick = () => {
    history.push("/");
  };

  const handleLogin = () => {
    history.push("/login");
  };

  const handleNewProduct = () => {
    history.push("/newproduct");
  };

  const handleFAQ = () => {
    //history.push('/faq');
    console.log("to FAQ");
  };

  const handleProfile = () => {
    history.push("/profile");
  };

  const handleSignUp = () => {
    history.push("/signup");
  };

  const navigateToProducts = () => {
    history.push("/profile");
    setTimeout(() => {
      const contactSection = document.getElementById("products");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay for smoother scroll
  };
  return (
    <div className={classes.userMenuContainer}>
        <div className={classes.menuItem}>
          <img src={iconHelp} alt="Plus Icon" className={classes.icon} />
          Preguntas Frecuentes
        </div>
        <div className={classes.menuItem} onClick={handleSignUp}>
          <img src={iconPlus} alt="User Icon" className={classes.icon} />
          Crear cuenta
        </div>
        <div className={classes.menuItem} onClick={handleLogin}>
          <img src={iconUser} alt="Help Icon" className={classes.icon} />
          Iniciar Sesión
        </div>
        
    
        <Button
        variant="contained"
        color="primary"
        className={classes.buttonMenu}
        onClick={handleNewProduct}
      >
        Publicar nuevos productos!
      </Button>
    </div>
  );
};

export default MenuUserDesktop;
