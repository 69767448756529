import { useFormik } from "formik";

import reviewSchema from "../utils/schemas/reviewSchema";
import reviewPort from "../ports/reviews.port";

import {  useSelector } from "react-redux";

const defaultValues = {
  title: "",
  description: "",
  rating: null,
};

const useReview = (callbackAfterSubmit, customInitialValues = null,product=null) => {
  const loggedUser = useSelector((state) => state.user.user);

  const handleSetAllTouched = () => {
    const touchedFields = Object.keys(reviewFormik.values).reduce(
      (touched, fieldName) => {
        touched[fieldName] = true;
        return touched;
      },
      {}
    );
    reviewFormik.setTouched(touchedFields);
  };
  const reviewFormik = useFormik({
    initialValues: customInitialValues
      ? { ...customInitialValues }
      : { ...defaultValues },

    validationSchema: reviewSchema,

    onSubmit: async (values) => {
      try {
        let userData=loggedUser
        await reviewPort.createReview(values,product,userData)
        callbackAfterSubmit();
      } catch (error) {
        console.log(error);
      }
    },
  });
  return [reviewFormik,handleSetAllTouched];
};

export default useReview;