import { useFormik } from "formik";

import { updateUser } from "../dataBaseAdapter";
import profileSchema from "../utils/schemas/profileSchema";
import { useState } from "react";

const defaultValues = {
  uid: null,
  email: null,
  contactName: null,
  contactNumber: "",
  zone: "",
};

const useProfile = (callbackAfterSubmit, customInitialValues = null) => {
  const [originalUser,setOriginalUser]=useState()
  const profileFormik = useFormik({
    initialValues: customInitialValues
      ? { ...customInitialValues }
      : { ...defaultValues },

    validationSchema: profileSchema,

    onSubmit: async (values) => {
      try {
        let nameUpdated=false
        if(values.contactName!=originalUser.contactName)
          nameUpdated=true
        await updateUser(values.uid, values,originalUser?.reviewsRef?originalUser.reviewsRef:[], nameUpdated, true);
        callbackAfterSubmit(values);
      } catch (error) {
        console.log(error);
      }
    },
  });
  return [profileFormik,setOriginalUser];
};

export default useProfile;
