import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(0.5),
            width: theme.spacing(90),
            height: theme.spacing(30),
        },

    },
    paperWeb: {
        padding: theme.spacing(1),
        maxWidth: 560,
        height: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: '5%',
        margin: '0px',
    },

    zoneInput: {
        marginLeft: '8px',
        width:'96%',
        boxShadow: '0 0 4px 1px rgba(204, 204, 204, 0.5)',
        padding: '0',
        borderRadius: '10px',
    },
    zoneInputEditing: {
        marginLeft: '8px',
        width:'96%',
        boxShadow: '0 0 4px 1px rgba(204, 204, 204, 0.5)',
        padding: '10px 15px',
        borderRadius: '10px',
    },

    profileInput: {
        
        alignItems: 'center',
        padding: '10px 15px',
        borderRadius: '10px',
        margin: '5px 0',
        marginLeft: '8px',
        width:'96%',
        boxShadow: '0 0 4px 1px rgba(204, 204, 204, 0.5)',
        
    },
    numericInput:{
        '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '& input[type=number]': {
            '-moz-appearance': 'textfield',
          },
    },
    label: {
        paddingRight: '10px'
    },
    sectionDesktop: {
        paddingTop: '60px',
        paddingBottom: '16px',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },

    updateButton: {
        margin: '16px auto',
        width: '60%',
        padding: '16px 0',
        borderRadius: '10px',
        textTransform: "none",
        backgroundColor: '#FB7E08',
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '16px',
        '&:hover': {
            backgroundColor: '#FB7E08',
          }
    },

    infoTitle: {
        textAlign: 'center',
        color: '#253376',
    },
    buttonProgress: {
        color: '#fff',
    },
    imageBackground: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      backgroundImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FEE5CE',
        padding: '12px',
        paddingBottom:'26px',
        marginBottom: '-14px'
      },
      roundedImage: {
        backgroundColor: '#FFF',
        borderRadius: '100%',
        padding: '12px',
      },
      fieldLabel:{
        marginLeft:"12px",
        fontWeight:"600",
        fontSize:"0.9rem",
        lineHeight:"14px",
        color:"#253376",
        marginBottom:"0"
      }
      
}))

export default useStyles