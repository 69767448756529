import {  makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    selectedButton:{
        backgroundColor:"#FB7E08",
        width:"45%",
        minWidth:"170px",
        height:"40px",
        color:"white",
        border:"none",
        '&:first-child': {
            borderTopLeftRadius: '4px',  
            borderBottomLeftRadius: '4px', 
        },
        '&:last-child': {
            borderTopRightRadius: '4px',  
            borderBottomRightRadius: '4px', 
        }
    },
    button:{
        width:"45%",
        minWidth:"170px",
        height:"40px",
        border:"1px solid #FB7E08",
        color:"#FB7E08",
        fontFamily:"Open Sans",
        fontSize:"12px",
        fontWeight:"600",
        backgroundColor:"transparent",
        '&:first-child': {
            borderTopLeftRadius: '4px',  // Rounded top-left corner
            borderBottomLeftRadius: '4px', // Rounded bottom-left corner
          },
          '&:last-child': {
            borderTopRightRadius: '4px',  // Rounded top-right corner
            borderBottomRightRadius: '4px', // Rounded bottom-right corner
          }
    },
    filterContainer:{
        display:"flex",
        flexDirection:"column",
        width:"100%"
    },
    buttonContainer:{
        display:"flex",
        flexDirection:"row",
        width:"100%",
        justifyContent:"center"

    },
    title:{
        fontFamily:"Nunito Sans",
        fontWeight:"700",
        fontSize:"16px",
        color:"#253376",
        textAlign:"start",
        marginLeft:"5%",
        marginBottom:"8px",
    }
  }));

  export default useStyles