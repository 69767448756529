export const handleProducts = (product) => {
  const adaptedProduct = {
    imageFile: null,
    productName: product.name || "",
    productPrice: product.price.toString() || "",
    productDescription: product.description || "",
    Categories: product.categories || [],
    PriorNoticeTime: {
      time: product.priorNoticeHours?.time || "",
      type: product.priorNoticeHours?.type || "Horas",
    },
    days: product.days || [],
    openingTime: product.openingTime || "",
    closingTime: product.closingTime || "",
    DeliveryType: product.delivery || "",
  };
  return adaptedProduct;
};
