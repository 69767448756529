import React  from "react";
import useStyles from "./reviewPublishedOverlayStyles";
import Button from "@material-ui/core/Button";
import check from "../../../Icons/check.svg";

const ReviewPublishedOverlay = ({handleBack}) => {
  const classes = useStyles();

  const handleOkClick = () => {
    handleBack();
  };



  return (
    <div className={classes.overlayContainer}>
    <div className={classes.checkIconContainer}>
      <img src={check} alt="Check Icon" className={classes.checkIcon} 
    /></div>
      <h2 className={classes.title}>RESEÑA PUBLICADA</h2>
      <p className={classes.description}>
        Excelente! Tu reseña se 
      </p>
      <p className={classes.descriptionBig}>
        publicó con éxito. 
      </p>
      <Button
        variant="contained"
        color="primary"
        className={classes.okButton}
        onClick={handleOkClick}
      >
        OK
      </Button>
    </div>
  );
};

export default ReviewPublishedOverlay;