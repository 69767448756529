import React, { useState } from "react";
import useStyles from "./reviewStarsInProductStyles";

import selectedStarImage from "../../../Icons/filledStar.svg";
import unselectedStarImage from "../../../Icons/star.svg";
import halfStarImage from "../../../Icons/halfFilledStar.svg";

const ReviewStarsInProduct = ({
  value,
  reviewsAverage=undefined,
  reviewsTotal,
  endAvgLabel = false,
}) => {
  const classes = useStyles();
  const [rating, setRating] = useState(value);
  
  const renderStar = (starValue) => {
    if (rating === 5 || rating === 4.9) {
      return (
        <img
          src={selectedStarImage}
          alt={`Selected Star ${starValue}`}
          className={classes.star}
        />
      );
    } else if (rating >= starValue - 0.2) {
      return (
        <img
          src={selectedStarImage}
          alt={`Selected Star ${starValue}`}
          className={classes.star}
        />
      );
    } else if (rating >= starValue - 0.7 && rating <= starValue - 0.2) {
      return (
        <img
          src={halfStarImage}
          alt={`Half Star ${starValue}`}
          className={classes.star}
        />
      );
    } else {
      return (
        <img
          src={unselectedStarImage}
          alt={`Unselected Star ${starValue}`}
          className={classes.star}
        />
      );
    }
  };
  
  return (
    <div className={classes.ratingStars}>
      {!endAvgLabel && reviewsAverage !== undefined && (
        <div className={classes.selectedRating}>
          {reviewsAverage.toFixed(1)}
        </div>
      )}
      <div className={classes.starsContainer}>
        {[1, 2, 3, 4, 5].map((value) => (
          <span key={value} className={classes.starContainer}>
            {renderStar(value)}
          </span>
        ))}
      </div>

      {endAvgLabel && reviewsAverage !== undefined && (
        <div className={classes.avgRatingRight}>{`${reviewsAverage.toFixed(
          1
        )} de 5`}</div>
      )}
      {reviewsTotal && <div className={classes.selectedRating} style={{ marginLeft: "5px" }}>
        {reviewsTotal}
      </div>}
    </div>
  );
};

export default ReviewStarsInProduct;
