import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        
    },
    container: {
        backgroundColor: "#FB7E08",
        color: "white",
        fontFamily: "Roboto",
    },
    logo: {
        display: "flex",
        justifyContent: "center",
        marginTop: '40px',
        marginBottom: '50px',
    },
    image: {
        width: "300px",
        [theme.breakpoints.down('500')]: {
            width: "200px",
        },
    },
    contactArea: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "16px",
    },
    contactInfoContainer: {
        [theme.breakpoints.down('500')]: {
            display: "flex",
            justifyContent: "center",
            width: "220px",
            flexWrap: "wrap",
            
        },
    },
    contactInfo: {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: 400,
        [theme.breakpoints.down('500')]: {
            fontSize: "14px",
        },
        margin: '10px 0px',
    },
    contactLink: {
        color: "inherit",
        display: "flex",
    },
    contactIcon: {
        marginRight: "12px",
    },
    contactBuffer: {
        width: "32px",
        [theme.breakpoints.down('500')]: {
            display: "none",
        },
    },
    cright: {
        display: "flex",
        justifyContent: "center",   
        textAlign: "center",
        marginTop: '52px',
        marginBottom: '8px',
    },
    legalText: {
        display: "flex",
        justifyContent: "center",
        padding: "0px 42px",
        textAlign: "center",
        marginBottom: '50px',
    },
}));

export default useStyles;