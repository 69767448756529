import React, { useEffect, useState } from "react";
import useStyles from "./ModalForDaysStyles";
import ClearIcon from "@material-ui/icons/Clear";
import DayOption from "./DayOption";
import { Button } from "@material-ui/core";

const ModalForDays = ({ handleModal, show, onChange, value, setSave }) => {
  const classes = useStyles();
  const [savedValue, setSavedValue] = useState(value);

  const options = [
    { option: "Lunes", isSelected: savedValue.includes("Lunes") },
    { option: "Martes", isSelected: savedValue.includes("Martes") },
    { option: "Miércoles", isSelected: savedValue.includes("Miércoles") },
    { option: "Jueves", isSelected: savedValue.includes("Jueves") },
    { option: "Viernes", isSelected: savedValue.includes("Viernes") },
    { option: "Sábado", isSelected: savedValue.includes("Sábado") },
    { option: "Domingo", isSelected: savedValue.includes("Domingo") },
    {
      option: "Todos los días",
      isSelected: savedValue.includes("Todos los días"),
    },
  ];

  const handleClick = () => {
    if (JSON.stringify(savedValue) !== JSON.stringify(value)) {
      onChange(savedValue);
      setSave(false);
    }
    handleModal();
  };

  const handleDaySelection = (selectedDay, isSelected) => {
    if (selectedDay === "Todos los días") {
      if (isSelected) {
        // If "Todos los días" is selected, unselect all other options
        setSavedValue(["Todos los días"]);
      } else {
        // If "Todos los días" is unselected, unselect all options
        setSavedValue([]);
      }
    } else {
      let updatedValue;

      if (isSelected) {
        // Add the selected day to the value array
        updatedValue = [...savedValue, selectedDay];
      } else {
        // Remove the selected day from the value array
        updatedValue = savedValue.filter((day) => day !== selectedDay);
      }

      // Remove "Todos los días" from the value array if other days are selected
      if (updatedValue.includes("Todos los días")) {
        updatedValue = updatedValue.filter((day) => day !== "Todos los días");
      }

      setSavedValue(updatedValue);
    }
    setSave(true);
  };

  useEffect(() => {
    if (!show) {
      // Reset the savedValue when the modal is closed
      setSavedValue(value);
    }
  }, [show, value]);

  return (
    <React.Fragment>
      <div
        className={
          !show ? classes.modalContainerHidden : classes.modalContainer
        }
      >
        <ClearIcon
          style={{ color: "#FB7E08" }}
          className={classes.closeButton}
          onClick={() => handleModal()}
        />
        <div className={classes.title}>Días de atención</div>

        <div className={classes.daysContainer}>
          {options.map((day, index) => (
            <DayOption
              key={index}
              day={day}
              handleDaySelection={handleDaySelection}
            />
          ))}
        </div>

        <section className={classes.submitSection}>
          <Button className={classes.btnSubmit} onClick={handleClick}>
            Guardar
          </Button>
        </section>
      </div>
    </React.Fragment>
  );
};

export default ModalForDays;
