import React from "react";
import { useState } from "react";
import useStyles from "./priceFilterStyles";
const RadioButton = ({ value, selectedValue, onSelect }) => {
    const classes = useStyles();

    const handleClick = () => {
        onSelect(value);
    };

    return (
        <button
        className={selectedValue === value ? classes.selectedButton : classes.button}
        onClick={handleClick}
        >
        {value}
        </button>
    );
};

const PriceFilter = ({keyValue,setSelectedExternal}) => {
    const classes = useStyles();

    const [selectedValue, setSelectedValue] = useState(null);

    const handleSelect = (value) => {
        setSelectedValue(value);
        setSelectedExternal(keyValue,value)
    };
    return (
        <div className={classes.filterContainer}>
            <span className={classes.title}>Precio</span>
            <div className={classes.buttonContainer}>
                <RadioButton
                    value="Más bajo"
                    selectedValue={selectedValue}
                    onSelect={handleSelect}
                />
                <RadioButton
                    value="Más alto"
                    selectedValue={selectedValue}
                    onSelect={handleSelect}
                />
                
            </div>
        </div>
    );
};



export default PriceFilter