import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  WarningContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: "16px",
    color: "#253376",
  },
  warningText: {
    fontSize: "14px",
    color: "#253376",
    width: "65%",
    textAlign: "center",
  },
  confirmDeleteButton: {
    width: "65%",
    height: "48px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "center",
    borderRadius: "8px",
    border: "1px solid #FB7E08",
    color: "#FB7E08",
    backgroundColor: "transparent",
    textTransform: "none",
  },
  backButton: {
    width: "65%",
    height: "48px",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "center",
    borderRadius: "8px",
    border: "1px solid #FB7E08",
    backgroundColor: "#FB7E08",
    color: "white",
    textTransform: "none",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    height: "25%",
    minHeight: "109px",
  },
  warningIcon: {
    aspectRatio: "1/1",
    width: "45%",
  },
  closeButton: {
    width: 24,
    position: "absolute",
    right: 12,
    top: 12,
  },
  disabledPrimaryButton: {
    cursor: "not-allowed",
    //opacity: 0.5,
    color: "white",
    backgroundColor: "lightgrey",
    border: "none",
  },
  disabledSecondaryButton: {
    cursor: "not-allowed",
    //opacity: 0.5,
    color: "#00000042",
    backgroundColor: "white",
    border: "1px solid lightgrey",
  },
}));

export default useStyles;
