import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStylesNav from "./NavbackStyles";

const NavBack = ({ title, extraButton,showBackArrow=true }) => {
  const history = useHistory();
  const classes = useStylesNav();

  const handleTitle = () => {
    const pathname = history.location.pathname;
    const titlepath = pathname.split("/").filter((e) => e !== "");
    if (titlepath[0] === "negocio") {
      return "PERFIL VENDEDOR";
    }
    return title || "DETALLE PRODUCTO";
  };

  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolBar}>
        {showBackArrow && <IconButton
          className={classes.iconBack}
          edge="start"
          onClick={
            history.location.key
              ? () => history.goBack()
              : () => history.push("/")
          }
          aria-label="close"
        >
          <ArrowBackIcon style={{ color: "#FB7E08" }} />
        </IconButton>}
        <Typography className={classes.title} align="center">
          {handleTitle()}
        </Typography>

        <div className={classes.extraButtonContainer}>{extraButton}</div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBack;
