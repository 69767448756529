import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Avatar, IconButton } from "@material-ui/core";
import useStyles from "./DetailProductStyles";
import { Button } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import NavBack from "../../components/navbar/Navback";
import { handleDaysForDetail } from "../../utils/AttentionDaysHandler";
import { handleEmptyNoticeHours } from "../../utils/NoticeHoursHandler";
import { handleEmptyAtentionTimes } from "../../utils/AtentionTimesHandler";
import ShareButton from "../../components/shareButton/ShareButton";
import { getProductData } from "../../dataBaseAdapter";
import { Helmet } from "react-helmet";
import vector from "../../Icons/Vector.svg";
import ReviewStarsInProduct from "../../components/reviews/reviewStars/reviewStarsInProduct";
import ReviewCard from "../../components/reviews/reviewCard/ReviewCard";
import reviewPort from "../../ports/reviews.port";

const calculateAverageRating = (reviews) => {
  if (!reviews || reviews.length === 0) {
    return 0; // Default value if no reviews
  }

  const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
  const averageRating = totalRating / reviews.length;
  return averageRating;
};
const orderDateArrayByDate = (reviews) => {

  reviews.sort((a, b) => {
    if (!a.date) return 1;
    if (!b.date) return -1;

    const dateA = createDateWithOptionalTime(a.date);
    const dateB = createDateWithOptionalTime(b.date);

    return dateB - dateA;
  });
  return reviews;
};

const createDateWithOptionalTime = (dateObject) => {
  const { year, month, day, hour = 0, minute = 0, second = 0  } = dateObject;
  return new Date(year, month - 1, day, hour, minute,second);
};

const DetailProduct = ({ location }) => {
  const classes = useStyles();
  const [isImgExpanded, setIsImgExpanded] = useState(false);
  const [product, setProduct] = useState(location.state);
  const [loading, setLoading] = useState(location.state ? false : true);
  const [reviews, setReviews] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const getProduct = async (id) => {
      let product = await getProductData(id);
      setProduct(product);
      setReviews(orderDateArrayByDate(product.reviews));
    };

    if (!location.state) {
      const id = location.pathname.split("/")[3];
      getProduct(id);
    }
  }, [loading]);

  let whatsapp_link;

  if (loading) {
    setLoading(false);
    return <div>Loading...</div>;
  } else if (product) {
    whatsapp_link = `https://wa.me/+591${product.user.contactNumber}/?text=Hola,%20le%20hablo%20de%20DesdeCasa%20por%20el%20producto%20${product.name}`;
  }
  const handleAddReview = () => {
    history.push({ pathname: `/productReview`, state: product });
  };
  const handleDeleteReview = async (review) => {
    let updatedProduct = await reviewPort.deleteReview(review, product);
    setProduct(updatedProduct);
  };
  return (
    <React.Fragment>
      {product ? (
        <Dialog fullScreen open>
          <Helmet>
            <meta property="og:title" content={product.name} />
            <meta property="og:description" content={product.description} />
            <meta property="og:image" content={product.imageUrl} />
          </Helmet>
          <div className={classes.sectionDesktop}>
            <NavBack />

            <div
              className={classes.header}
              onClick={() => setIsImgExpanded(true)}
            >
              <img
                className={classes.img}
                alt="complex"
                src={product ? product.imageUrl : ""}
              />
            </div>

            <div className={classes.infoProduct}>
              <div className={classes.nameProduct}>
                <div className={classes.firstName}>{product.name}</div>
                <div
                  className={classes.secondName}
                  onClick={() =>
                    history.push({
                      pathname: `/negocio/${product.user.contactName}/${product.user.uid}`,
                      state: {
                        id: product.user.uid,
                      },
                    })
                  }
                >
                  {product.user.contactName}{" "}
                  <span className={classes.businessProfile}>Ver Negocio</span>
                </div>
              </div>
              {product.price == 0 ? (
                <Tooltip title="Consultar precio">
                  <div className={classes.price}>
                    <span className={classes.currency}>Bs.</span>
                    <span className={classes.money}>{"?"}</span>
                  </div>
                </Tooltip>
              ) : (
                <div className={classes.price}>
                  <span className={classes.currency}>Bs.</span>
                  <span className={classes.money}>{product.price}</span>
                </div>
              )}
            </div>

            <div className={classes.productInfoContainer}>
              <div
                className={classes.zone}
                style={{
                  borderBottom: "1px solid rgba(171, 171, 171, 0.5)",
                }}
              >
                <p className={classes.label}>🗓 Atención</p>
                <p className={classes.infoContent}>
                  {handleDaysForDetail(product)}
                </p>
              </div>

              <div
                className={classes.otherInfo}
                style={{ borderRight: "1px solid rgba(171, 171, 171, 0.5)" }}
              >
                <p className={classes.label}>📍 Ubicación</p>
                <p className={classes.infoContent}>{product.user.zone}</p>
              </div>
              <div className={classes.otherInfo}>
                <p className={classes.label}>⏰ Horarios</p>
                <p className={classes.infoContent}>
                  {handleEmptyAtentionTimes(product)}
                </p>
              </div>
              <div
                className={classes.otherInfo}
                style={{ borderRight: "1px solid rgba(171, 171, 171, 0.5)" }}
              >
                <p className={classes.label}>⏳ Reservar</p>
                <p className={classes.infoContent}>
                  {handleEmptyNoticeHours(product)}
                </p>
              </div>
              <div className={classes.otherInfo}>
                <p className={classes.label}>🛵 Delivery</p>
                <p className={classes.infoContent}>{product.delivery}</p>
              </div>
            </div>

            <div className={classes.tituloDescripcion}>
              <div className={classes.labelDescripcion}>Descripción</div>
              <span className={classes.descripcion}>{product.description}</span>
            </div>
            <div className={classes.orangeLine}></div>

            <div className={classes.tituloDescripcion}>
              <div className={classes.labelDescripcion}>
                Reseñas de clientes
              </div>
              {product.reviews && product.reviews.length > 0 ? (
                <span className={classes.ratingStars}>
                  <ReviewStarsInProduct
                    value={product?.rating || 0}
                    reviewsAverage={product?.rating || 0}
                    endAvgLabel={true}
                  />
                  <span
                    className={classes.totalReviews}
                  >{`${product.reviews.length} reseñas`}</span>
                </span>
              ) : (
                <span className={classes.descripcion}>No hay reseñas aún.</span>
              )}
            </div>

            <div className={classes.buttonContainer}>
              {/* ... other buttons ... */}
              <Button
                onClick={handleAddReview}
                className={classes.addReviewButton}
                variant="outlined"
              >
                Escribe una reseña
                <img className={classes.arrow} src={vector} alt="Arrow Icon" />
              </Button>
            </div>

            {/* Display reviews */}
            {product.reviews && product.reviews.length > 0 ? (
              <div className={classes.reviewsContainer}>
                {product.reviews.map((review) => (
                  <ReviewCard review={review} key={review.title+review.description}
                  deleteReview={handleDeleteReview} reviewerName={product.reviewers?product.reviewers[review.reviewerUID]:null}></ReviewCard>
            ))}
              </div>
            ) : (
              <span></span>
            )}
          </div>

          <FootDetailProduct
            whatsappLink={whatsapp_link}
            shareDetails={{
              title: product.user.contactName,
              text: product.name,
            }}
          />
        </Dialog>
      ) : (
        <div>cargando</div>
      )}
      {isImgExpanded && (
        <div
          className={classes.backgroundImage}
          onClick={() => setIsImgExpanded(false)}
        >
          <div className={classes.closeIconContainer}>
            <div className={classes.closeIconCircle}>
              <IconButton
                onClick={() => setIsImgExpanded(false)}
                aria-label="close"
              >
                <CloseIcon style={{ color: "#FB7E08" }} />
              </IconButton>
            </div>
          </div>

          <img
            className={classes.imgExpanded}
            alt="complex"
            src={product ? product.imageUrl : ""}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default DetailProduct;

const useStylesFooter = makeStyles(() => ({
  footContainer: {
    backgroundColor: "#fff",
    width: "100vw",
    height: "128px",
    position: "fixed",
    bottom: "0",
    padding: "12px 16px",
    boxSizing: "borderBox",
    boxShadow: "1px 1px 10px rgba(186, 162, 133, 0.3)",
  },
  reservar: {
    height: "48px",
    color: "#fff",
    backgroundColor: "#FB7E08",
    marginBottom: "8px",
    border: "1px solid #FB7E08",
    borderRadius: "8px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FB7E08",
    },
    "&:focus": {
      backgroundColor: "#FB7E08",
    },
  },
  compartir: {
    height: "48px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#FB7E08",
    backgroundColor: "#fff",
    border: "1.76471px solid #FB7E08",
    borderRadius: "8px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  shareButtonContainer: {
    padding: "12px 20px",
  },
}));

const FootDetailProduct = ({ whatsappLink, shareDetails }) => {
  const classes = useStylesFooter();
  return (
    <div className={classes.footContainer}>
      <Button
        fullWidth
        disableElevation
        variant="contained"
        color="default"
        href={whatsappLink}
        target="_blank"
        className={classes.reservar}
        startIcon={
          <WhatsAppIcon
            style={{
              fontSize: "24",
            }}
          />
        }
      >
        Reservar
      </Button>
      <ShareButton shareDetails={shareDetails} />
    </div>
  );
};
