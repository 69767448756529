import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  TextField,
  Button,
  InputAdornment,
  Grid,
  CircularProgress,
  Select,
  InputLabel,
  Checkbox,
  MenuItem,
  ListItemText,
  Input,
} from "@material-ui/core";
import { forwardRef } from "react";
import { db } from "../../firebase";
import { storage } from "../../firebase";
import { AuthContext } from "../../Auth";
import useStyles from "./NewProductFormStyles";
import { useFormik } from "formik";
import Resizer from "react-image-file-resizer";
import FormControl from "@material-ui/core/FormControl"; // Test JP UI
import { keywordsArrayCreator } from "../../utils/KeyWordsMethods";
import { categories as cats } from "../../utils/Constants";
import {  useSelector } from "react-redux";

const NewProductForm = forwardRef((props, ref) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const loggedUser = useSelector((state) => state.user.user);

  const categories = Object.keys(cats);

  categories.shift();

  const days = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
    "Todos los dias",
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      description: "",
      priorNoticeHours: "0",
      openingTime: "",
      closingTime: "",
      delivery: "",
      days: [],
      categories: [],
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Requerido";
      }
      if (!values.price) {
        errors.price = "Requerido";
      }
      if (values.price > 999) {
        errors.price = "El precio debe ser menor a Bs. 1000";
      }
      if (!values.price.match(/^[0-9]+([,][0-9]+)?$/)) {
        errors.price = "El precio debe ser un número.";
      }
      if (values.priorNoticeHours && !values.priorNoticeHours.match(/^\d+$/)) {
        errors.priorNoticeHours = "Debe ser un número.";
      }

      return errors;
    },
    onSubmit: (values) => {
      try {
        const img_id =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
        const uploadTask = storage.ref(`images/${img_id}`).put(image);
        let keywordArray = keywordsArrayCreator(values.name);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            setLoading(true);
            // progress function ...
          },
          (error) => {
            // error function ...
          },
          () => {
            storage
              .ref("images")
              .child(img_id)
              .getDownloadURL()
              .then((url) => {
                db.collection("products")
                  .add({
                    name: values.name,
                    price: values.price,
                    priorNoticeHours: values.priorNoticeHours,
                    openingTime: values.openingTime,
                    closingTime: values.closingTime,
                    days: values.days,
                    categories: ["Todos los Productos"].concat(
                      values.categories
                    ),
                    description: values.description,
                    imageUrl: url,
                    user: {
                      uid: userInfo.uid,
                      email: userInfo.email,
                      contactNumber: userInfo.contactNumber,
                      contactName: userInfo.contactName,
                      zone: userInfo.zone || "",
                    },
                    delivery: values.delivery,
                    keywords: keywordArray,
                    photoRating: 8,
                  })
                  .then((docRef) => {
                    values.name = "";
                    values.price = "";
                    values.description = "";
                    values.priorNoticeHours = "";
                    values.delivery = "";
                    values.openingTime = "";
                    values.closingTime = "";
                    values.days = [];
                    values.categories = [];
                    setImageUrl("");
                    setImage(null);
                    setLoading(false);
                    handleClose();
                  })
                  .catch((error) => {
                    console.error("Error adding document: ", error);
                  });
              });
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    setImageUrl("");
    setImage(null);
  };

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen,
    };
  });

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const getUser = async () => {
      if (currentUser) {
        setUserInfo(loggedUser);
      }
    };

    getUser();
  }, [currentUser]);

  const handleImageChange = async (e) => {
    if (e.target.files[0]) {
      const resizeFile = (file) => {
        return new Promise((resolve, reject) => {
          Resizer.imageFileResizer(
            file,
            600,
            600,
            "JPEG",
            90,
            0,
            (uri) => {
              resolve(uri);
            },
            "file"
          );
        });
      };

      try {
        const file = e.target.files[0];
        const image = await resizeFile(file);
        setImage(image);
        setImageUrl(URL.createObjectURL(image));
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" className={classes.title}>
              Publicar Producto
            </h2>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                inputProps={{ maxLength: 50 }}
                placeholder="Nombre del producto *"
                fullWidth
                required
                className={classes.textfield}
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name ? (
                <div className={classes.error}>{formik.errors.name}</div>
              ) : null}

              <TextField
                placeholder="Precio *"
                fullWidth
                required
                className={classes.textfield}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Bs. </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 3 }}
                name="price"
                onChange={formik.handleChange}
                value={formik.values.price}
              />
              {formik.errors.price && (
                <p className={classes.error}>{formik.errors.price}</p>
              )}
              <TextField
                inputProps={{ maxLength: 130 }}
                placeholder="Descripción"
                multiline
                minRows={4}
                maxRows={4}
                fullWidth
                className={classes.textfield}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              {formik.errors.description ? (
                <div className={classes.error}>{formik.errors.description}</div>
              ) : null}

              <FormControl style={{ width: "100%" }}>
                <InputLabel id="open-days">Días de Atención</InputLabel>
                <Select
                  multiple
                  value={formik.values.days}
                  input={<Input />}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={formik.handleChange}
                  name="days"
                  labelId="open-days"
                  label="Dias de Atencion"
                >
                  {days.map((day) => (
                    <MenuItem key={day} value={day}>
                      <Checkbox
                        checked={formik.values.days.indexOf(day) > -1}
                      />
                      <ListItemText primary={day} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="form-categories">Categorías</InputLabel>
                <Select
                  multiple
                  value={formik.values.categories}
                  input={<Input />}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={formik.handleChange}
                  name="categories"
                  labelId="form-categories"
                  label="Categorías"
                >
                  {categories.sort().map((category, index) => (
                    <MenuItem key={index} value={category}>
                      <Checkbox
                        checked={
                          formik.values.categories.indexOf(category) > -1
                        }
                      />
                      <ListItemText primary={category} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <InputLabel style={{ marginRight: "8px" }}>
                  Aviso Previo:{" "}
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"> Horas </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 2 }}
                  size="small"
                  name="priorNoticeHours"
                  onChange={formik.handleChange}
                  value={formik.values.priorNoticeHours}
                  style={{ marginTop: "12px", width: "200px" }}
                />
                {formik.errors.priorNoticeHours && (
                  <p className={classes.error}>
                    {formik.errors.priorNoticeHours}
                  </p>
                )}
              </Grid>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <InputLabel className={classes.horarioLabel}>
                  {" "}
                  Horario de Atención:{" "}
                </InputLabel>
                <InputLabel style={{ marginRight: "4px" }}> De </InputLabel>
                <TextField
                  name="openingTime"
                  id="time"
                  type="time"
                  defaultValue="09:00"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: 150 }}
                  onChange={formik.handleChange}
                  style={{ marginRight: "4px" }}
                />
                <InputLabel style={{ marginRight: "4px" }}> a </InputLabel>
                <TextField
                  name="closingTime"
                  id="time"
                  //label="Alarm clock"
                  type="time"
                  defaultValue="17:00"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: 150 }}
                  onChange={formik.handleChange}
                />
              </Grid>

              <div className={classes.horarios}>
                <InputLabel
                  id="demo-simple-select-standard-label"
                  style={{ marginRight: "8px" }}
                >
                  Delivery:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  name="delivery"
                  value={formik.values.delivery}
                  onChange={formik.handleChange}
                  style={{ width: "200px" }}
                >
                  <MenuItem value={"Solo para recoger"}>Solo Recoger</MenuItem>
                  <MenuItem value={"Costo Aparte"}>Costo Aparte</MenuItem>
                  <MenuItem value={"Costo Incluido"}>Costo Incluido</MenuItem>
                </Select>
              </div>

              <Grid
                container
                direction="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "16px",
                }}
              >
                <input
                  type="file"
                  id="contained-img-button-file"
                  accept="image/*"
                  className={classes.input}
                  onChange={handleImageChange}
                />
                <label htmlFor="contained-img-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.uploadButton}
                  >
                    Subir Imagen {!imageUrl ? "*" : null}
                  </Button>
                </label>
                {image ? (
                  <img
                    src={imageUrl}
                    alt="preview"
                    width="50px"
                    height="50px"
                    className={classes.thumbnail}
                  />
                ) : null}
              </Grid>

              {formik.errors.name ||
              formik.errors.price ||
              formik.errors.description ||
              !imageUrl ||
              formik.errors.days ||
              formik.errors.priorNoticeHours ||
              formik.errors.openingTime ||
              formik.errors.closingTime ||
              formik.errors.delivery ||
              !formik.values.name ||
              !formik.values.price ? (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.buttonPublicar}
                  fullWidth
                  disabled
                >
                  Publicar Producto
                </Button>
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.buttonPublicar}
                  disabled={loading}
                  fullWidth
                >
                  Publicar Producto
                  {loading ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : null}
                </Button>
              )}

              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={(e) => handleClose()}
              >
                Cerrar
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
});

export default NewProductForm;
