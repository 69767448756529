import React from "react";
import Navbar from "../../components/navbar/Navbar";
import ProfileInfoCard from "../../components/profile/ProfileInfoCard";
import ProductsTable from "../../components/profile/ProductsTable";

const Profile = () => {
  return (
    <React.Fragment>
      <Navbar view="profile" />
      <ProfileInfoCard />
      <ProductsTable id="products" />
    </React.Fragment>
  );
};

export default Profile;
