import { makeStyles,createTheme } from "@material-ui/core/styles";
let customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 320,
        sm: 375,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });
const useStyles = makeStyles((theme) => ({
    tableTitle: {
        padding: '10px',
        color: '#253376',
        fontFamily: 'ExtraBold',
        fontSize: 22,
        boxShadow: 'none',
    },
    titleContainer:{
        display:"flex",
        width:"100%"
    },
 
    deleteButton: {
        backgroundColor: '#ff4444',
        color: 'white',
        margin:'2px',
    },
    productBox: {
        display:"flex",
        flexDirection:"row",
        boxSizing:"border-box",
        height:"120px",
        width:"93.6%",
        boxShadow: '0 0 4px 3px rgba(204, 204, 204, 0.5)',
        borderRadius:"8px",
        marginBottom:"12px"
    },
    myProductsContainer: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        width:"100%"
    },
    productsList:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        width:"100%",
    },
    itemInfo:{
        display:"flex",
        flexDirection:"column",
        minHeight:"120px",
        flexGrow: 1,
        width:"70%",
        justifyContent:"space-between",
        paddingBottom:"9px",

    },
    productBottomInfo:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center"
    },
    productName: {
        color: '#253376', 
        fontFamily: 'semiBold, Nunito Sans', // Combine both font styles
        fontSize: "14",
        fontWeight:"600",
        marginLeft:"8px",
        marginTop:"12px"

    },
    editButton: {
        marginRight:"8px",
        marginLeft:"25px",
        padding:"8px 12px 7px 8px",
        width:"64.14%",
        minWidth:"131px",
        border: 'solid #FB7E08 1px',
        color: '#FB7E08',
        boxSizing: 'border-box',
        borderRadius: '8px',
        fontFamily:"Open Sans",
        fontSize:"12px",
        fontWeight:"600",
        textAlign:"center",
        textTransform:"capitalize",
        [customTheme.breakpoints.down('xs')]: {
            minWidth:"0",
        },
        [customTheme.breakpoints.up('md')]: {
            width:"50%",
        },
    },
    priceContainer: {
        display:"flex",
        flexDirection:"row",
        height:"34px",
        width:"30.86%",
        justifyContent:"space-evenly",
        alignItems:"center",
        backgroundColor: '#FEE5CE',
        paddingLeft: '5px',
        paddingRight: '5px',
        borderRadius: '5px',
        marginRight:"8px"
    },
    image: {
        display:"flex",
        height:"100%",
        borderRadius:"8px 0 0 8px",
        aspectRatio:"1/1",
      },

    bsText: {
        height:"100%",
        display:"flex",
        alignItems:"end",
        fontFamily:"Nunito Sans",
        fontSize: '14px',
        fontWeight: 'bold',
        marginRight: '5px',
        color: '#253376', 
        paddingBottom:"0.39rem",
        [customTheme.breakpoints.down('xs')]: {
            fontSize: '12px', 
          },
      },
    price: {
        fontFamily:"Nunito Sans",
        fontSize:"20px",
        fontWeight: 'bold',
        color: '#253376', 
        [customTheme.breakpoints.down('xs')]: {
            fontSize: '14px', 
          },
      },
      editIconSVG: {
        fill: '#FB7E08',
        width: '16px', 
        height: '16px',
      },
}))

export default useStyles